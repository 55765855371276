@charset "UTF-8";
@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* plugins */
@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }
  .selectric-wrapper:focus {
    background: #eeeff4;
    color: #ff5c00;
    border-bottom: 2px solid #ff5c00; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 1px solid #ff5c00;
  border-radius: 0px;
  background: #fff;
  position: relative;
  overflow: hidden;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 60px 0 30px;
    font-size: 15px;
    line-height: 60px;
    color: #323337;
    height: 60px;
    user-select: none; }
    .selectric .label b {
      float: right;
      font-weight: 400; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: #ff5c00;
    text-align: center;
    font: 0/0 a;
    *font: 20px/60px Lucida Sans Unicode, Arial Unicode MS, Arial;
    transition: all ease 200ms; }
    .selectric .button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 15px;
      content: "\e912";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ff5c00; }

.selectric-focus .selectric {
  border-color: #ff5c00; }

.selectric-hover .selectric {
  border-color: #ff5c00; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ff5c00;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul,
  .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px 30px;
    color: #323337;
    background-color: #fff;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: #323337;
      background-color: rgba(0, 0, 0, 0.05); }
    .selectric-items li.highlighted {
      color: #323337; }
    .selectric-items li:hover {
      background-color: rgba(0, 0, 0, 0.05); }
    .selectric-items li b {
      float: right;
      font-weight: 400; }
  .selectric-items.multiple li {
    position: relative;
    padding: 10px 30px 10px 60px; }
    .selectric-items.multiple li:before {
      content: "";
      width: 20px;
      height: 20px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 30px;
      top: 10px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .selectric-items.multiple li:after {
      content: "\e909";
      display: inline-block;
      line-height: 20px;
      font-size: 10px;
      width: 20px;
      color: #ff5c00;
      text-align: center;
      left: 30px;
      top: 10px;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .selectric-items.multiple li:hover:before {
      background-color: #fff; }
    .selectric-items.multiple li:hover:after {
      opacity: 0.4;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
      filter: alpha(opacity=40); }
    .selectric-items.multiple li.selected:before {
      background-color: #fff; }
    .selectric-items.multiple li.selected:after {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

/* selectric open */
.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #ff5c00;
    border-bottom: 0; }
    .selectric-open .selectric .button {
      color: #1a1a1c;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block;
    border-color: #ff5c00; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.slider-init .main-slider {
  width: 100%;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  user-select: text; }
  @media (min-width: 320px) {
    .slider-init .main-slider {
      padding-top: 5rem; } }
  @media (min-width: 320px) {
    .slider-init .main-slider {
      padding-bottom: 5rem; } }
  .slider-init .main-slider-inner {
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 320px) {
      .slider-init .main-slider-inner {
        min-height: 72rem; } }
    .slider-init .main-slider-inner-content {
      max-width: 1000px;
      margin: auto; }
      .slider-init .main-slider-inner-content-text {
        color: #000; }
        .slider-init .main-slider-inner-content-text h1,
        .slider-init .main-slider-inner-content-text h2 {
          color: #000; }
      .slider-init .main-slider-inner-content-btn {
        padding: 20px 0 0 0; }
    .slider-init .main-slider-inner .pux-container {
      width: 100%; }
  .slider-init .main-slider.top-center {
    background-position: top center !important; }
  .slider-init .main-slider.top-left {
    background-position: top left !important; }
  .slider-init .main-slider.top-right {
    background-position: top right !important; }
  .slider-init .main-slider.center-center {
    background-position: center center !important; }
  .slider-init .main-slider.bottom-center {
    background-position: bottom center !important; }
  .slider-init .main-slider.bottom-left {
    background-position: bottom left !important; }
  .slider-init .main-slider.bottom-right {
    background-position: bottom right !important; }
  .slider-init .main-slider:focus {
    outline: none; }

.slider-init .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: none;
  font-size: 0;
  background: #ff5c00;
  outline: 0;
  z-index: 22;
  cursor: pointer; }
  .slider-init .slick-arrow:before {
    font-size: 20px; }
  .slider-init .slick-arrow.slick-prev {
    left: 20px; }
    .slider-init .slick-arrow.slick-prev:before {
      content: "遗"; }
  .slider-init .slick-arrow.slick-next {
    right: 20px; }
    .slider-init .slick-arrow.slick-next:before {
      content: "道"; }

.slider-init .slick-dots {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 30px; }
  .slider-init .slick-dots li {
    margin: 0;
    padding: 0 20px 0 0; }
    .slider-init .slick-dots li:before {
      display: none; }
    .slider-init .slick-dots li:last-child {
      padding: 0; }
    .slider-init .slick-dots li button {
      font-size: 0;
      outline: 0;
      border: 0;
      padding: 20px 0;
      background: transparent;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms; }
      .slider-init .slick-dots li button:before {
        content: '';
        background: #fff;
        display: block;
        width: 40px;
        height: 4px;
        border: none; }
    .slider-init .slick-dots li.slick-active button:before {
      background: #ff5c00; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 1600px; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 45px;
      padding-right: 45px; } }
  @media (min-width: 1200px) {
    .pux-container {
      padding-left: 80px;
      padding-right: 80px; } }

.small-container {
  max-width: 865px !important; }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -45px;
      margin-right: -45px; } }

.pux-container .col-1,
.pux-container .col-2,
.pux-container .col-3,
.pux-container .col-4,
.pux-container .col-5,
.pux-container .col-6,
.pux-container .col-7,
.pux-container .col-8,
.pux-container .col-9,
.pux-container .col-10,
.pux-container .col-11,
.pux-container .col-12,
.pux-container .col,
.pux-container .col-xs-1,
.pux-container .col-xs-2,
.pux-container .col-xs-3,
.pux-container .col-xs-4,
.pux-container .col-xs-5,
.pux-container .col-xs-6,
.pux-container .col-xs-7,
.pux-container .col-xs-8,
.pux-container .col-xs-9,
.pux-container .col-xs-10,
.pux-container .col-xs-11,
.pux-container .col-xs-12,
.pux-container .col-auto,
.pux-container .col-sm-1,
.pux-container .col-sm-2,
.pux-container .col-sm-3,
.pux-container .col-sm-4,
.pux-container .col-sm-5,
.pux-container .col-sm-6,
.pux-container .col-sm-7,
.pux-container .col-sm-8,
.pux-container .col-sm-9,
.pux-container .col-sm-10,
.pux-container .col-sm-11,
.pux-container .col-sm-12,
.pux-container .col-sm,
.pux-container .col-sm-auto,
.pux-container .col-md-1,
.pux-container .col-md-2,
.pux-container .col-md-3,
.pux-container .col-md-4,
.pux-container .col-md-5,
.pux-container .col-md-6,
.pux-container .col-md-7,
.pux-container .col-md-8,
.pux-container .col-md-9,
.pux-container .col-md-10,
.pux-container .col-md-11,
.pux-container .col-md-12,
.pux-container .col-md,
.pux-container .col-md-auto,
.pux-container .col-lg-1,
.pux-container .col-lg-2,
.pux-container .col-lg-3,
.pux-container .col-lg-4,
.pux-container .col-lg-5,
.pux-container .col-lg-6,
.pux-container .col-lg-7,
.pux-container .col-lg-8,
.pux-container .col-lg-9,
.pux-container .col-lg-10,
.pux-container .col-lg-11,
.pux-container .col-lg-12,
.pux-container .col-lg,
.pux-container .col-lg-auto,
.pux-container .col-xl-1,
.pux-container .col-xl-2,
.pux-container .col-xl-3,
.pux-container .col-xl-4,
.pux-container .col-xl-5,
.pux-container .col-xl-6,
.pux-container .col-xl-7,
.pux-container .col-xl-8,
.pux-container .col-xl-9,
.pux-container .col-xl-10,
.pux-container .col-xl-11,
.pux-container .col-xl-12,
.pux-container .col-xl,
.pux-container .col-xl-auto {
  padding-left: 25px;
  padding-right: 25px; }
  @media (min-width: 768px) {
    .pux-container .col-1,
    .pux-container .col-2,
    .pux-container .col-3,
    .pux-container .col-4,
    .pux-container .col-5,
    .pux-container .col-6,
    .pux-container .col-7,
    .pux-container .col-8,
    .pux-container .col-9,
    .pux-container .col-10,
    .pux-container .col-11,
    .pux-container .col-12,
    .pux-container .col,
    .pux-container .col-xs-1,
    .pux-container .col-xs-2,
    .pux-container .col-xs-3,
    .pux-container .col-xs-4,
    .pux-container .col-xs-5,
    .pux-container .col-xs-6,
    .pux-container .col-xs-7,
    .pux-container .col-xs-8,
    .pux-container .col-xs-9,
    .pux-container .col-xs-10,
    .pux-container .col-xs-11,
    .pux-container .col-xs-12,
    .pux-container .col-auto,
    .pux-container .col-sm-1,
    .pux-container .col-sm-2,
    .pux-container .col-sm-3,
    .pux-container .col-sm-4,
    .pux-container .col-sm-5,
    .pux-container .col-sm-6,
    .pux-container .col-sm-7,
    .pux-container .col-sm-8,
    .pux-container .col-sm-9,
    .pux-container .col-sm-10,
    .pux-container .col-sm-11,
    .pux-container .col-sm-12,
    .pux-container .col-sm,
    .pux-container .col-sm-auto,
    .pux-container .col-md-1,
    .pux-container .col-md-2,
    .pux-container .col-md-3,
    .pux-container .col-md-4,
    .pux-container .col-md-5,
    .pux-container .col-md-6,
    .pux-container .col-md-7,
    .pux-container .col-md-8,
    .pux-container .col-md-9,
    .pux-container .col-md-10,
    .pux-container .col-md-11,
    .pux-container .col-md-12,
    .pux-container .col-md,
    .pux-container .col-md-auto,
    .pux-container .col-lg-1,
    .pux-container .col-lg-2,
    .pux-container .col-lg-3,
    .pux-container .col-lg-4,
    .pux-container .col-lg-5,
    .pux-container .col-lg-6,
    .pux-container .col-lg-7,
    .pux-container .col-lg-8,
    .pux-container .col-lg-9,
    .pux-container .col-lg-10,
    .pux-container .col-lg-11,
    .pux-container .col-lg-12,
    .pux-container .col-lg,
    .pux-container .col-lg-auto,
    .pux-container .col-xl-1,
    .pux-container .col-xl-2,
    .pux-container .col-xl-3,
    .pux-container .col-xl-4,
    .pux-container .col-xl-5,
    .pux-container .col-xl-6,
    .pux-container .col-xl-7,
    .pux-container .col-xl-8,
    .pux-container .col-xl-9,
    .pux-container .col-xl-10,
    .pux-container .col-xl-11,
    .pux-container .col-xl-12,
    .pux-container .col-xl,
    .pux-container .col-xl-auto {
      padding-left: 45px;
      padding-right: 45px; } }

.pux-fluid-container {
  padding-left: 25px;
  padding-right: 25px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 45px;
      padding-right: 45px; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 80px;
      padding-right: 80px; } }

.text-container {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto; }
  .text-container:before, .text-container:after {
    content: '';
    display: table; }
  .text-container:after {
    clear: both; }
  .text-container iframe {
    display: block;
    margin: 0 auto;
    max-width: 100% !important; }
    @media (min-width: 320px) and (max-width: 1199px) {
      .text-container iframe {
        max-height: 480px; } }

header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0);
  background-color: #fff;
  background: linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0) 90%);
  z-index: 1001;
  -moz-transition: all ease-in-out 350ms;
  -o-transition: all ease-in-out 350ms;
  -webkit-transition: all ease-in-out 350ms;
  transition: all ease-in-out 350ms; }
  header .main-navbar .navbar-brand {
    margin-right: 0;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 0;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    header .main-navbar .navbar-brand img {
      width: 78px;
      height: 40px !important;
      position: relative;
      z-index: 1;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms;
      object-fit: contain; }
    header .main-navbar .navbar-brand img.logo-dark {
      display: block; }
    header .main-navbar .navbar-brand img.logo-light {
      display: none; }
    @media (min-width: 1200px) {
      header .main-navbar .navbar-brand {
        margin-bottom: -7px;
        padding-top: 15px; }
        header .main-navbar .navbar-brand img {
          width: 94px;
          height: 63px !important; } }
  header .main-navbar .navbar-toggler {
    display: inline-block;
    vertical-align: middle;
    border: 0;
    padding: 0;
    margin: 0px 0px;
    height: 18px;
    width: 32px;
    position: relative; }
    header .main-navbar .navbar-toggler .icon-bar {
      display: block;
      background-color: #323337;
      width: 32px;
      height: 2px;
      border-radius: 0px;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms;
      position: absolute;
      left: 0;
      top: 0; }
      header .main-navbar .navbar-toggler .icon-bar:nth-of-type(2) {
        top: 8px; }
      header .main-navbar .navbar-toggler .icon-bar:nth-of-type(3) {
        top: 16px; }
    header .main-navbar .navbar-toggler:not(.collapsed) .icon-bar {
      border-radius: 1px; }
      header .main-navbar .navbar-toggler:not(.collapsed) .icon-bar:nth-of-type(1) {
        -webkit-transform: rotateZ(45deg);
        -moz-transform: rotateZ(45deg);
        -ms-transform: rotateZ(45deg);
        -o-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        top: 8px; }
      header .main-navbar .navbar-toggler:not(.collapsed) .icon-bar:nth-of-type(2) {
        width: 0px;
        opacity: 0; }
      header .main-navbar .navbar-toggler:not(.collapsed) .icon-bar:nth-of-type(3) {
        -webkit-transform: rotateZ(-45deg);
        -moz-transform: rotateZ(-45deg);
        -ms-transform: rotateZ(-45deg);
        -o-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
        top: 8px; }
    header .main-navbar .navbar-toggler:focus {
      outline: 0;
      box-shadow: none;
      border: 0; }
    @media (min-width: 1200px) {
      header .main-navbar .navbar-toggler {
        display: none; } }
  header .main-navbar .navbar-collapse {
    width: 100%; }
    @media (min-width: 1200px) {
      header .main-navbar .navbar-collapse {
        display: block;
        width: auto; } }
    header .main-navbar .navbar-collapse.main-menu > ul {
      padding: 5px 0px 15px 0px;
      margin: 0;
      font-size: 0;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms; }
      @media (min-width: 1200px) {
        header .main-navbar .navbar-collapse.main-menu > ul {
          padding: 40px 0 27px 0; } }
      header .main-navbar .navbar-collapse.main-menu > ul > li {
        display: block;
        text-align: right;
        margin-bottom: 8px;
        position: relative; }
        @media (min-width: 1200px) {
          header .main-navbar .navbar-collapse.main-menu > ul > li {
            display: inline-block;
            margin-bottom: 0px; } }
        @media (min-width: 1200px) {
          header .main-navbar .navbar-collapse.main-menu > ul > li:not(:last-child) {
            padding-right: 35px; } }
        @media (min-width: 1400px) {
          header .main-navbar .navbar-collapse.main-menu > ul > li:not(:last-child) {
            padding-right: 70px; } }
        header .main-navbar .navbar-collapse.main-menu > ul > li a {
          font-size: 16px;
          text-transform: uppercase;
          display: inline-block;
          position: relative;
          padding: 0 4px;
          color: #646569; }
          @media (min-width: 1400px) {
            header .main-navbar .navbar-collapse.main-menu > ul > li a {
              font-size: 17px;
              padding-bottom: 4px; } }
          header .main-navbar .navbar-collapse.main-menu > ul > li a:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 100%;
            border-bottom: 2px solid transparent;
            -moz-transition: all ease-in-out 350ms;
            -o-transition: all ease-in-out 350ms;
            -webkit-transition: all ease-in-out 350ms;
            transition: all ease-in-out 350ms; }
          header .main-navbar .navbar-collapse.main-menu > ul > li a:hover {
            text-decoration: none; }
            header .main-navbar .navbar-collapse.main-menu > ul > li a:hover:after {
              right: 0;
              border-color: #ff5c00; }
        header .main-navbar .navbar-collapse.main-menu > ul > li > ul {
          display: none;
          background-color: #fff;
          margin: 0 -20px;
          padding: 20px 20px 10px 20px; }
          @media (min-width: 1200px) {
            header .main-navbar .navbar-collapse.main-menu > ul > li > ul {
              position: absolute;
              padding: 20px 20px 10px 20px;
              top: 100%;
              left: 0;
              box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
              min-width: 200px;
              text-align: left; } }
          header .main-navbar .navbar-collapse.main-menu > ul > li > ul > li {
            margin: 0 0 7px 0; }
            @media (min-width: 1200px) {
              header .main-navbar .navbar-collapse.main-menu > ul > li > ul > li {
                margin: 0 0 10px 0; } }
            header .main-navbar .navbar-collapse.main-menu > ul > li > ul > li > a {
              font-size: 14px; }
              @media (min-width: 1200px) {
                header .main-navbar .navbar-collapse.main-menu > ul > li > ul > li > a {
                  font-size: 16px; } }
            header .main-navbar .navbar-collapse.main-menu > ul > li > ul > li.Highlighted > a:after {
              right: 0;
              border-color: #ff5c00; }
          header .main-navbar .navbar-collapse.main-menu > ul > li > ul.open {
            display: block; }
        header .main-navbar .navbar-collapse.main-menu > ul > li.hide-submenu > ul.open {
          display: none; }
        header .main-navbar .navbar-collapse.main-menu > ul > li.Highlighted > a:after {
          right: 0;
          border-color: #ff5c00; }
    header .main-navbar .navbar-collapse.main-menu .open-btn {
      display: inline-block;
      margin: 0 0 0 5px; }
      header .main-navbar .navbar-collapse.main-menu .open-btn:before {
        content: "\e903";
        font-size: 12px; }
  header .main-navbar .header-controls {
    padding: 13px 0 16px 0;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    @media (min-width: 1200px) {
      header .main-navbar .header-controls {
        padding: 33px 0 10px 0; } }
  header .main-navbar .header-btn {
    color: #646569;
    text-align: center;
    width: 30px;
    line-height: 30px;
    display: inline-block; }
    header .main-navbar .header-btn:before {
      font-size: 20px;
      display: inline-block;
      vertical-align: middle; }
    header .main-navbar .header-btn.login {
      margin: 0 5px 0 0; }
      @media (min-width: 1200px) {
        header .main-navbar .header-btn.login {
          margin: 0 0 0 20px; } }
      @media (min-width: 1400px) {
        header .main-navbar .header-btn.login {
          margin: 0 0 0 30px; } }
      header .main-navbar .header-btn.login:before {
        content: "\e905"; }
    header .main-navbar .header-btn:hover {
      text-decoration: none;
      color: #ff5c00; }
  header.header-dark {
    background: #000;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 90%); }
    header.header-dark .navbar-brand img.logo-dark {
      display: none; }
    header.header-dark .navbar-brand img.logo-light {
      display: block; }
    header.header-dark .main-navbar .navbar-collapse.main-menu > ul > li a {
      color: #fff; }
    header.header-dark .main-navbar .navbar-collapse.main-menu > ul > li > ul {
      background-color: #000; }
      @media (min-width: 1200px) {
        header.header-dark .main-navbar .navbar-collapse.main-menu > ul > li > ul {
          background-color: #fff; } }
    @media (min-width: 1200px) {
      header.header-dark .main-navbar .navbar-collapse.main-menu > ul > li > ul a {
        color: #646569; } }
    header.header-dark .main-navbar .navbar-toggler .icon-bar {
      background-color: #fff; }
    header.header-dark .open-btn {
      color: #fff; }
    header.header-dark .header-btn {
      color: #fff; }
  header.scrolled {
    background-color: white;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1); }
    header.scrolled.homepage-header {
      background: #fff; }
    header.scrolled.header-dark {
      background: #000; }
    header.scrolled .header-controls {
      padding: 16px 0 10px 0; }
    header.scrolled .main-navbar .navbar-brand {
      padding-top: 10px;
      margin-bottom: 10px; }
      header.scrolled .main-navbar .navbar-brand img {
        height: 40px !important; }
    header.scrolled .main-navbar .navbar-collapse.main-menu > ul {
      padding: 5px 0 7px 0; }
      @media (min-width: 1200px) {
        header.scrolled .main-navbar .navbar-collapse.main-menu > ul {
          padding: 20px 0 7px 0; } }
  header.open {
    background: #fff; }
    header.open.header-dark {
      background: #000; }

footer {
  background-color: #000;
  color: #fff;
  padding: 20px 0 7rem 0; }
  footer h4,
  footer .h4,
  footer p {
    color: #fff; }
  footer .footer-container {
    padding: 25px 0 7rem 0;
    border-top: 1px solid #525151; }
  footer .footer-header {
    margin: 0 0 4rem 0;
    display: flex;
    justify-content: space-between; }
    @media (min-width: 768px) {
      footer .footer-header {
        align-items: center; } }
  footer .footer-logo {
    text-align: left;
    padding: 0 40px 0 0; }
    footer .footer-logo svg {
      width: 80px; }
  footer .footer-list {
    margin: 0 -2rem; }
    footer .footer-list ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap; }
      footer .footer-list ul li {
        padding: 0 2rem;
        margin: 0 0 15px 0;
        width: 100%; }
        @media (min-width: 768px) {
          footer .footer-list ul li {
            width: auto;
            margin: 0; } }
        footer .footer-list ul li a {
          color: #fff;
          display: inline-block;
          position: relative; }
          footer .footer-list ul li a:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 100%;
            border-bottom: 2px solid transparent;
            -moz-transition: all ease-in-out 350ms;
            -o-transition: all ease-in-out 350ms;
            -webkit-transition: all ease-in-out 350ms;
            transition: all ease-in-out 350ms; }
          footer .footer-list ul li a:hover {
            text-decoration: none; }
            footer .footer-list ul li a:hover:after {
              right: 0;
              border-color: #fff; }
  footer .footer-columns {
    display: flex;
    flex-flow: row wrap;
    width: 100%; }
    @media (min-width: 768px) {
      footer .footer-columns {
        text-align: left;
        padding: 0 0 8rem 0; } }
    footer .footer-columns-container {
      margin: 0 -2rem; }
  footer .footer-column {
    padding: 0 2rem;
    margin: 0 0 30px 0;
    flex: 1 1 100%;
    width: 100%;
    text-align: center; }
    @media (min-width: 768px) {
      footer .footer-column {
        flex: 1 1 33.3333%;
        max-width: 33.3333%;
        margin: 0;
        text-align: left; } }
    footer .footer-column h4,
    footer .footer-column .h4 {
      font-size: 20px;
      line-height: 25px; }
    footer .footer-column p {
      font-size: 14px;
      line-height: 18px; }
    footer .footer-column address {
      margin-bottom: 18px; }
    footer .footer-column ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      footer .footer-column ul li {
        padding: 0;
        margin: 0 0 18px 0; }
        footer .footer-column ul li a {
          color: #fff;
          font-weight: 700;
          display: inline-block;
          position: relative; }
          footer .footer-column ul li a:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 100%;
            border-bottom: 2px solid transparent;
            -moz-transition: all ease-in-out 350ms;
            -o-transition: all ease-in-out 350ms;
            -webkit-transition: all ease-in-out 350ms;
            transition: all ease-in-out 350ms; }
          footer .footer-column ul li a:hover {
            text-decoration: none; }
            footer .footer-column ul li a:hover:after {
              right: 0;
              border-color: #fff; }
    footer .footer-column .map-canvas,
    footer .footer-column iframe {
      height: 180px;
      width: 300px;
      max-width: 100%;
      margin: 0 auto; }
      @media (min-width: 992px) {
        footer .footer-column .map-canvas,
        footer .footer-column iframe {
          margin: 0; } }
  footer .footer-bottom-list {
    padding: 3rem 0 0 0;
    border-top: 1px solid #525151; }
    footer .footer-bottom-list ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin: 0 -10px;
      padding: 0;
      list-style: none; }
      @media (min-width: 768px) {
        footer .footer-bottom-list ul {
          justify-content: flex-start;
          margin: 0; } }
      footer .footer-bottom-list ul li {
        padding: 0 10px;
        margin: 0 0 15px 0; }
        @media (min-width: 768px) {
          footer .footer-bottom-list ul li {
            margin: 0; } }
        footer .footer-bottom-list ul li a {
          color: #fff;
          display: inline-block;
          position: relative;
          font-size: 14px; }
          footer .footer-bottom-list ul li a:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 100%;
            border-bottom: 2px solid transparent;
            -moz-transition: all ease-in-out 350ms;
            -o-transition: all ease-in-out 350ms;
            -webkit-transition: all ease-in-out 350ms;
            transition: all ease-in-out 350ms; }
          footer .footer-bottom-list ul li a:hover {
            text-decoration: none; }
            footer .footer-bottom-list ul li a:hover:after {
              right: 0;
              border-color: #fff; }
  footer .footer-social {
    text-align: center; }
    footer .footer-social ul {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
      footer .footer-social ul li {
        padding: 0 15px 15px 0; }
        footer .footer-social ul li:before {
          display: none; }
        footer .footer-social ul li a {
          font-size: 0;
          display: block;
          text-decoration: none;
          width: 30px;
          height: 30px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%;
          background: #fff;
          border: none;
          color: #646569;
          text-align: center;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          footer .footer-social ul li a:focus, footer .footer-social ul li a:hover {
            background: #ff5c00;
            color: #fff;
            border: none;
            text-decoration: none; }
    footer .footer-social .facebook:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .facebook:focus, footer .footer-social .facebook:hover {
      background: #3b5998; }
    footer .footer-social .twitter:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .twitter:focus, footer .footer-social .twitter:hover {
      background: #00aced; }
    footer .footer-social .linkedin:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .linkedin:focus, footer .footer-social .linkedin:hover {
      background: #0d77b7; }
    footer .footer-social .youtube:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .youtube:focus, footer .footer-social .youtube:hover {
      background: #cc181e; }
    footer .footer-social .instagram:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .instagram:focus, footer .footer-social .instagram:hover {
      background: #8a3ab9; }
    footer .footer-social .pinterest:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .pinterest:focus, footer .footer-social .pinterest:hover {
      background: #cd2029; }
    footer .footer-social .google:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .google:focus, footer .footer-social .google:hover {
      background: #d34836; }
    footer .footer-social .vimeo:before {
      content: "";
      line-height: 30px;
      font-size: 21px; }
    footer .footer-social .vimeo:focus, footer .footer-social .vimeo:hover {
      background: #45bbff; }
    @media (min-width: 768px) {
      footer .footer-social {
        text-align: left; } }
    footer .footer-social ul {
      width: 100%;
      display: block; }
      footer .footer-social ul li {
        display: inline-block;
        vertical-align: middle; }
        footer .footer-social ul li:last-of-type {
          padding-right: 0px; }
        footer .footer-social ul li .social-ico {
          width: 32px;
          height: 32px;
          color: #000; }
          footer .footer-social ul li .social-ico:before {
            line-height: 32px; }
          footer .footer-social ul li .social-ico:after {
            display: none; }
          footer .footer-social ul li .social-ico.twitter:before {
            font-size: 14px; }
          footer .footer-social ul li .social-ico.linkedin:before {
            font-size: 17px; }
          footer .footer-social ul li .social-ico.youtube:before {
            font-size: 12px; }
  footer .footer-copy {
    font-size: 13px;
    line-height: 20px;
    text-align: center; }
  footer.footer-light {
    background-color: #fff;
    color: #000; }
    footer.footer-light .footer-fill {
      fill: #000 !important; }
    footer.footer-light .footer-column h4,
    footer.footer-light .footer-column .h4,
    footer.footer-light .footer-column p {
      color: #000; }
    footer.footer-light .footer-column ul li a {
      color: #000; }
      footer.footer-light .footer-column ul li a:after {
        border-color: #000; }
      footer.footer-light .footer-column ul li a.social-ico {
        color: #fff;
        background-color: #000; }
    footer.footer-light .footer-list ul li a,
    footer.footer-light .footer-bottom-list ul li a {
      color: #000; }
      footer.footer-light .footer-list ul li a:after,
      footer.footer-light .footer-bottom-list ul li a:after {
        border-color: #000; }

main {
  transition: all ease-in-out 350ms; }

.page-content {
  padding-top: 60px; }
  @media (min-width: 1200px) {
    .page-content {
      padding-top: 100px; } }

.EditMode header,
.DesignMode header {
  position: static; }
  .EditMode header:after,
  .DesignMode header:after {
    display: none; }

.EditMode main,
.DesignMode main {
  padding-top: 0px; }

.EditMode #content,
.DesignMode #content {
  margin-top: 0 !important; }
  .EditMode #content:before, .EditMode #content:after,
  .DesignMode #content:before,
  .DesignMode #content:after {
    display: none !important; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@font-face {
  font-family: 'Fedra';
  src: url("/ZFPAcademy/media/system/fonts/FedraSans/FedraSansPro-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Fedra';
  src: url("/ZFPAcademy/media/system/fonts/FedraSans/FedraSansPro-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Fedra';
  src: url("/ZFPAcademy/media/system/fonts/FedraSans/FedraSansPro-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Fedra';
  src: url("/ZFPAcademy/media/system/fonts/FedraSans/FedraSansPro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal; }

.cms-bootstrap [class^="icon-"]:before,
.cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html,
body {
  font-family: "Fedra", sans-serif;
  height: 100%;
  color: #000; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }
  @media (min-width: 1925px) {
    html {
      font-size: 10.5px; } }
  @media (min-width: 3200px) {
    html {
      font-size: 15px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
  html {
    font-size: 6.3px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
  html {
    font-size: 7px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1925px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1925px), only screen and (min-resolution: 120dpi) and (min-width: 1925px), only screen and (min-resolution: 1.25dppx) and (min-width: 1925px) {
  html {
    font-size: 10.5px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 3200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 3200px), only screen and (min-resolution: 120dpi) and (min-width: 3200px), only screen and (min-resolution: 1.25dppx) and (min-width: 3200px) {
  html {
    font-size: 15px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.1) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 110dpi) and (min-width: 1200px), only screen and (min-resolution: 1.1dppx) and (min-width: 1200px) {
  html {
    font-size: 7.2px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.1) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 110dpi) and (min-width: 1400px), only screen and (min-resolution: 1.1dppx) and (min-width: 1400px) {
  html {
    font-size: 8px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.1) and (min-width: 1925px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1925px), only screen and (min-resolution: 110dpi) and (min-width: 1925px), only screen and (min-resolution: 1.1dppx) and (min-width: 1925px) {
  html {
    font-size: 10.5px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.1) and (min-width: 3200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 3200px), only screen and (min-resolution: 110dpi) and (min-width: 3200px), only screen and (min-resolution: 1.1dppx) and (min-width: 3200px) {
  html {
    font-size: 15px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.05) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 105dpi) and (min-width: 1200px), only screen and (min-resolution: 1.05dppx) and (min-width: 1200px) {
  html {
    font-size: 7.65px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.05) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 105dpi) and (min-width: 1400px), only screen and (min-resolution: 1.05dppx) and (min-width: 1400px) {
  html {
    font-size: 8.5px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.05) and (min-width: 1925px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1925px), only screen and (min-resolution: 105dpi) and (min-width: 1925px), only screen and (min-resolution: 1.05dppx) and (min-width: 1925px) {
  html {
    font-size: 10.5px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.05) and (min-width: 3200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 3200px), only screen and (min-resolution: 105dpi) and (min-width: 3200px), only screen and (min-resolution: 1.05dppx) and (min-width: 3200px) {
  html {
    font-size: 15px; } }

@media (min-width: 320px) {
  body {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  body {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  body {
    font-size: 1.8rem; } }

@media (min-width: 320px) {
  body {
    line-height: 4.6rem; } }

@media (min-width: 480px) {
  body {
    line-height: 4.18182rem; } }

@media (min-width: 545px) {
  body {
    line-height: 3.83333rem; } }

@media (min-width: 768px) {
  body {
    line-height: 3.06667rem; } }

@media (min-width: 992px) {
  body {
    line-height: 2.875rem; } }

@media (min-width: 1200px) {
  body {
    line-height: 2.55556rem; } }

@media (min-width: 1400px) {
  body {
    line-height: 2.5rem; } }

img {
  max-width: 100%;
  height: auto !important; }

.pt-5 {
  padding-top: 5px; }

.pb-5 {
  padding-bottom: 5px; }

.mt-5 {
  margin-top: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.pt-10 {
  padding-top: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.mt-10 {
  margin-top: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.pt-15 {
  padding-top: 15px; }

.pb-15 {
  padding-bottom: 15px; }

.mt-15 {
  margin-top: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.pt-20 {
  padding-top: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px; }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem; } }

@media (min-width: 320px) {
  .pt-120 {
    padding-top: 12rem; } }

@media (min-width: 320px) {
  .pb-120 {
    padding-bottom: 12rem; } }

@media (min-width: 320px) {
  .mt-120 {
    margin-top: 12rem; } }

@media (min-width: 320px) {
  .mb-120 {
    margin-bottom: 12rem; } }

@media (min-width: 320px) {
  .pt-150 {
    padding-top: 15rem; } }

@media (min-width: 320px) {
  .pb-150 {
    padding-bottom: 15rem; } }

@media (min-width: 320px) {
  .mt-150 {
    margin-top: 15rem; } }

@media (min-width: 320px) {
  .mb-150 {
    margin-bottom: 15rem; } }

.no-padding-bottom {
  padding-bottom: 0px !important; }

h1,
.h1 {
  font-weight: 700;
  text-transform: uppercase; }
  @media (min-width: 320px) {
    h1,
    .h1 {
      font-size: 6rem; } }
  @media (min-width: 320px) {
    h1,
    .h1 {
      line-height: 10rem; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      line-height: 9.09091rem; } }
  @media (min-width: 545px) {
    h1,
    .h1 {
      line-height: 8.33333rem; } }
  @media (min-width: 768px) {
    h1,
    .h1 {
      line-height: 8rem; } }
  @media (min-width: 320px) {
    h1,
    .h1 {
      margin-bottom: 4rem; } }

h2,
.h2 {
  font-weight: 700;
  text-transform: uppercase; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 5.2rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      line-height: 7rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      margin-bottom: 4rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      margin-bottom: 3.63636rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      margin-bottom: 3.33333rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      margin-bottom: 3rem; } }

h3,
.h3 {
  font-weight: 700;
  text-transform: uppercase; }
  @media (min-width: 320px) {
    h3,
    .h3 {
      font-size: 4.8rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 4.36364rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 4rem; } }
  @media (min-width: 320px) {
    h3,
    .h3 {
      line-height: 6.4rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      line-height: 6rem; } }
  @media (min-width: 320px) {
    h3,
    .h3 {
      margin-bottom: 3rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      margin-bottom: 2.72727rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      margin-bottom: 2.5rem; } }

h4,
.h4 {
  font-weight: 700;
  color: #000; }
  @media (min-width: 320px) {
    h4,
    .h4 {
      font-size: 5rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 4.54545rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      font-size: 4.16667rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 3.33333rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      font-size: 3.2rem; } }
  @media (min-width: 320px) {
    h4,
    .h4 {
      line-height: 6.4rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      line-height: 5.81818rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      line-height: 5.33333rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      line-height: 4.26667rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      line-height: 4.2rem; } }
  @media (min-width: 320px) {
    h4,
    .h4 {
      margin-bottom: 2rem; } }

h5,
.h5 {
  font-weight: 400;
  color: #ff5c00; }
  @media (min-width: 320px) {
    h5,
    .h5 {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    h5,
    .h5 {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    h5,
    .h5 {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    h5,
    .h5 {
      font-size: 2.4rem; } }
  @media (min-width: 320px) {
    h5,
    .h5 {
      line-height: 4.8rem; } }
  @media (min-width: 480px) {
    h5,
    .h5 {
      line-height: 4.36364rem; } }
  @media (min-width: 545px) {
    h5,
    .h5 {
      line-height: 4rem; } }
  @media (min-width: 768px) {
    h5,
    .h5 {
      line-height: 3.2rem; } }
  @media (min-width: 992px) {
    h5,
    .h5 {
      line-height: 3rem; } }
  @media (min-width: 320px) {
    h5,
    .h5 {
      margin-bottom: 2rem; } }

h6,
.h6 {
  font-weight: 700;
  color: #000; }
  @media (min-width: 320px) {
    h6,
    .h6 {
      font-size: 6.6rem; } }
  @media (min-width: 480px) {
    h6,
    .h6 {
      font-size: 6rem; } }
  @media (min-width: 545px) {
    h6,
    .h6 {
      font-size: 5.5rem; } }
  @media (min-width: 768px) {
    h6,
    .h6 {
      font-size: 4.4rem; } }
  @media (min-width: 992px) {
    h6,
    .h6 {
      font-size: 4.125rem; } }
  @media (min-width: 1200px) {
    h6,
    .h6 {
      font-size: 3.66667rem; } }
  @media (min-width: 1400px) {
    h6,
    .h6 {
      font-size: 3.6rem; } }
  @media (min-width: 320px) {
    h6,
    .h6 {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    h6,
    .h6 {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    h6,
    .h6 {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    h6,
    .h6 {
      line-height: 2.66667rem; } }
  @media (min-width: 992px) {
    h6,
    .h6 {
      line-height: 2.5rem; } }
  @media (min-width: 1200px) {
    h6,
    .h6 {
      line-height: 2.22222rem; } }
  @media (min-width: 1400px) {
    h6,
    .h6 {
      line-height: 2rem; } }
  @media (min-width: 320px) {
    h6,
    .h6 {
      margin-bottom: 3rem; } }

p {
  color: #000; }
  @media (min-width: 320px) {
    p {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    p {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    p {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    p {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    p {
      font-size: 2rem; } }
  @media (min-width: 320px) {
    p {
      line-height: 4.8rem; } }
  @media (min-width: 480px) {
    p {
      line-height: 4.36364rem; } }
  @media (min-width: 545px) {
    p {
      line-height: 4rem; } }
  @media (min-width: 768px) {
    p {
      line-height: 3.2rem; } }
  @media (min-width: 992px) {
    p {
      line-height: 3rem; } }
  .wrapper-black p {
    color: #fff; }

@media (min-width: 320px) {
  big,
  .perex {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    font-size: 3rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    font-size: 2.4rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    font-size: 2.2rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    16px: 5rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    16px: 4.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    16px: 4.16667rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    16px: 3.33333rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    16px: 3.125rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    16px: 2.77778rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    16px: 2.5rem; } }

a {
  color: #ff5c00;
  cursor: pointer;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  a:focus, a:hover {
    outline: none;
    color: #323337; }

q:before {
  font-family: "Fedra", sans-serif !important;
  content: '»';
  color: #ff5c00;
  display: inline-block; }

q:after {
  font-family: "Fedra", sans-serif !important;
  content: '«';
  color: #ff5c00;
  display: inline-block; }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: 2px solid #ff5c00;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 11px 25px 11px 25px;
  text-transform: uppercase;
  position: relative;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  @media (min-width: 768px) {
    .btn {
      font-size: 20px;
      line-height: 24px;
      padding: 13px 34px 13px 34px; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    outline: none;
    box-shadow: none; }
    .btn:focus:after, .btn:hover:after {
      font-size: 21px; }
      @media (min-width: 768px) {
        .btn:focus:after, .btn:hover:after {
          right: 13px; } }
  span .btn {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0px;
    margin-right: 0px;
    vertical-align: top; }
    span .btn:focus, span .btn:hover {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      margin-bottom: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-left: 0px; }
  span.btn {
    padding: 0; }
  .btn-link {
    color: #ff5c00;
    font-weight: 700; }
    .btn-link:after {
      position: relative;
      content: "\e908";
      font-size: 10px;
      line-height: 25px;
      margin-left: 5px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .btn-link:hover {
      color: #323337;
      text-decoration: none; }
      .btn-link:hover:after {
        margin-left: 8px;
        color: #ff5c00; }

.btn-primary {
  background: transparent;
  color: #ff5c00;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    background: #ff5c00;
    color: #fff;
    border-color: #ff5c00; }

.btn-default {
  background-color: transparent;
  color: #fff;
  font-weight: 500;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #ff5c00;
    color: #fff;
    border-color: #ff5c00; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 400px; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 10px 0;
      padding: 0 0 0 3rem; }
      main ol li:before {
        font-family: "Fedra", sans-serif !important;
        content: counter(my-ol);
        counter-increment: my-ol;
        color: #646569;
        position: absolute;
        left: 0;
        top: 2px;
        font-weight: bold; }
    main ol[type="a"] li:before {
      content: counter(my-ol, lower-alpha); }
    main ol[type="a"][data-counter-upper] li:before {
      content: counter(my-ol, upper-alpha); }
    main ol[type="i"] li:before {
      content: counter(my-ol, lower-roman); }
    main ol[type="i"][data-counter-upper] li:before {
      content: counter(my-ol, upper-roman); }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      color: #000;
      line-height: 26px;
      margin: 0 0 10px 0;
      padding: 0 0 0 40px;
      position: relative; }
      @media (min-width: 320px) {
        main ul li {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        main ul li {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        main ul li {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        main ul li {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        main ul li {
          font-size: 2rem; } }
      @media (min-width: 320px) {
        main ul li {
          line-height: 4.8rem; } }
      @media (min-width: 480px) {
        main ul li {
          line-height: 4.36364rem; } }
      @media (min-width: 545px) {
        main ul li {
          line-height: 4rem; } }
      @media (min-width: 768px) {
        main ul li {
          line-height: 3.2rem; } }
      @media (min-width: 992px) {
        main ul li {
          line-height: 3rem; } }
      main ul li:before {
        content: "";
        width: 10px;
        height: 2px;
        background: #ff5c00;
        position: absolute;
        top: 14px;
        left: 0; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 2px solid #e4e4e4; }
      main table tr td {
        padding: 13px 15px 12px 15px; }
      main table tr:nth-child(even) {
        background: #ebebeb; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 2px solid #323337; }
        main .respo-table table thead tr th {
          padding: 13px 15px 12px 15px;
          font-weight: 400; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #e4e4e4;
      border-bottom: none;
      margin: 0 0 20px 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #ebebeb; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 2px solid #e4e4e4; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 2px solid #e4e4e4; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "Fedra", sans-serif !important;
          margin: 0 15px 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

@media (min-width: 545px) {
  .two-column-list ul {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2; } }

.btn-list ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .btn-list ul li {
    -webkit-flex: 100%;
    -moz-flex: 100%;
    -ms-flex: 100%;
    -webkit-flex: 100%;
    flex: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .btn-list ul li {
        -webkit-flex: 33.3333%;
        -moz-flex: 33.3333%;
        -ms-flex: 33.3333%;
        -webkit-flex: 33.3333%;
        flex: 33.3333%;
        max-width: 33.3333%; } }
    .btn-list ul li:before {
      display: none; }
    .btn-list ul li a {
      display: block;
      background-color: #ff5c00;
      color: #fff;
      line-height: 22px;
      padding: 14px 30px;
      text-align: center;
      text-transform: uppercase; }
      .btn-list ul li a:hover {
        text-decoration: none;
        background-color: #e65300; }
      @media (min-width: 768px) {
        .btn-list ul li a {
          font-size: 17px;
          padding: 24px 30px; } }

/* wrappers */
.orange-wrapper {
  background-color: #ff5c00;
  color: #fff; }
  .orange-wrapper .perex,
  .orange-wrapper big {
    color: #fff; }
  .orange-wrapper a:not(.btn) {
    color: #fff;
    text-decoration: underline; }

.text-block {
  color: #000; }
  @media (min-width: 992px) {
    .text-block {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (min-width: 1200px) {
    .text-block {
      padding-left: 70px;
      padding-right: 70px; } }
  @media (min-width: 1400px) {
    .text-block {
      padding-left: 120px;
      padding-right: 120px; } }
  @media (min-width: 320px) {
    .text-block h2,
    .text-block .h2 {
      margin-bottom: 4rem; } }
  @media (min-width: 480px) {
    .text-block h2,
    .text-block .h2 {
      margin-bottom: 3.63636rem; } }
  @media (min-width: 545px) {
    .text-block h2,
    .text-block .h2 {
      margin-bottom: 3.33333rem; } }
  @media (min-width: 768px) {
    .text-block h2,
    .text-block .h2 {
      margin-bottom: 3rem; } }
  @media (min-width: 320px) {
    .text-block p {
      margin-bottom: 4rem; } }
  .text-block.narrow-text {
    max-width: 1120px;
    margin: 0 auto; }
  .text-block.with-devider p:nth-of-type(1) {
    position: relative; }
    @media (min-width: 320px) {
      .text-block.with-devider p:nth-of-type(1) {
        padding-top: 4rem; } }
    .text-block.with-devider p:nth-of-type(1):before {
      content: '';
      width: 86px;
      height: 4px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -43px;
      background-color: #ff5c00; }

h2.text-underline,
h3.text-underline {
  position: relative;
  margin-bottom: 30px; }
  h2.text-underline:before,
  h3.text-underline:before {
    content: '';
    width: 86px;
    height: 4px;
    position: absolute;
    bottom: -15px;
    background-color: #ff5c00; }

.white-wrapper {
  background-color: #fff; }

.grey-wrapper {
  background-color: #f2f2f2; }

.wrapper-semi-black {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff; }

.wrapper-black {
  background-color: #000;
  color: #fff; }
  .wrapper-black .text-block,
  .wrapper-black big,
  .wrapper-black .perex {
    color: #fff; }

.bottom-line {
  border-bottom: 1px solid #f2f2f2; }

.custom-layout {
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 768px) {
    .custom-layout .row {
      margin-left: -15px;
      margin-right: -15px; }
      .custom-layout .row [class*="col-"] {
        padding-left: 15px;
        padding-right: 15px; } }
  @media (min-width: 1600px) {
    .custom-layout .row {
      margin-left: -20px;
      margin-right: -20px; }
      .custom-layout .row [class*="col-"] {
        padding-left: 20px;
        padding-right: 20px; } }

/* animated icons */
.animated-icon {
  opacity: 0; }
  .animated-icon.full-visible {
    opacity: 1; }

.hide {
  display: none; }

.EditMode .btn:after,
.DesignMode .btn:after {
  display: none; }

.EditMode .business-contact-frame-inner,
.DesignMode .business-contact-frame-inner {
  position: static; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

label {
  display: block;
  margin: 0px 0 10px 0;
  font-size: 16px;
  line-height: 25px;
  font-weight: 700; }

.form-control,
textarea,
input[type="text"],
input[type="password"] {
  width: 100%;
  height: 60px;
  font-size: 16px;
  padding: 0 30px;
  border: 1px solid #ff5c00;
  border-radius: 0;
  background: #fff;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #646569; }
  .form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #646569; }
  .form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #646569; }
  .form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #646569; }
  .form-control:focus,
  textarea:focus,
  input[type="text"]:focus,
  input[type="password"]:focus {
    outline: 0;
    border: 1px solid #323337; }

textarea,
textarea.form-control {
  height: 180px;
  padding: 20px 30px;
  font-size: 16px; }
  textarea:focus,
  textarea.form-control:focus {
    border: 1px solid #323337; }

.required label:before {
  content: "*";
  font-size: 10px;
  display: inline-block;
  vertical-align: 6px;
  margin: 0 2px 0 0; }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #ff5c00;
  cursor: pointer;
  font-size: 15px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 280px;
  margin: 0;
  margin-top: 35px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important;
  line-height: 20px; }

.file .file-name {
  display: block;
  padding: 5px 10px;
  background: #f2f2f2;
  color: #000;
  margin-top: 10px; }

.Error select,
.Error .selectric,
.Error input[type="text"],
.Error textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

.EditingFormErrorLabel {
  font-size: 14px;
  color: #d40511;
  display: block;
  margin: 5px 0 15px 0; }

.ErrorLabel {
  color: #d40511;
  display: block;
  margin: 5px 10px; }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 10px 30px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 6px;
      width: 10px;
      height: 10px;
      background: #ff5c00;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:hover:after {
      opacity: 0.4;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
      filter: alpha(opacity=40); }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 10px 30px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 20px;
      height: 20px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "\e909";
      display: inline-block;
      line-height: 20px;
      font-size: 10px;
      width: 20px;
      color: #ff5c00;
      text-align: center;
      left: 0px;
      top: 1px;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:hover:after {
      opacity: 0.4;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
      filter: alpha(opacity=40); }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 60px;
  padding: 0 30px;
  border: 1px solid #ff5c00;
  background: #fff;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms;
  background-image: url(../img/dp-arrow.png);
  background-position: 97% center;
  background-repeat: no-repeat; }
  select:focus {
    outline: 0;
    border: 1px solid #323337; }

.modal {
  background-color: rgba(255, 255, 255, 0.8); }
  .modal-dialog {
    background-color: #fff;
    width: calc(100% - 20px);
    max-width: 920px;
    padding: 20px; }
    @media (min-width: 320px) {
      .modal-dialog {
        margin-top: 15rem; } }
    @media (min-width: 320px) {
      .modal-dialog {
        margin-bottom: 10rem; } }
    .modal-dialog.search {
      background-color: transparent;
      padding: 30px 0 0 0;
      text-align: center; }
    @media (min-width: 768px) {
      .modal-dialog {
        padding: 40px; } }
    @media (min-width: 992px) {
      .modal-dialog {
        padding: 80px; } }
  .modal-form .row {
    margin-left: -10px;
    margin-right: -10px; }
    .modal-form .row [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px; }
  @media (min-width: 320px) {
    .modal-form .editing-form-control-nested-control {
      margin-bottom: 2rem; } }
  .modal-form .editing-form-control-nested-control.Error {
    margin-bottom: 0; }
    .modal-form .editing-form-control-nested-control.Error + label {
      margin-bottom: 0; }
  .modal-close {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #ff5c00;
    color: #fff;
    display: block;
    height: 40px;
    width: 40px;
    text-align: center; }
    .modal-close:before {
      content: "\e900";
      line-height: 40px !important;
      font-size: 14px; }
    .modal-close:hover {
      background-color: #e65300;
      color: #fff;
      text-decoration: none; }
    @media (min-width: 768px) {
      .modal-close {
        height: 80px;
        width: 80px; }
        .modal-close:before {
          line-height: 80px !important; } }

.newsletter-subscription {
  max-width: 440px;
  margin: 0 auto;
  font-size: 0;
  background-color: #fff;
  border: 2px solid #ff5c00; }
  .newsletter-subscription-input {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-right: -60px;
    padding-right: 60px; }
    .newsletter-subscription-input input[type="text"] {
      background-color: #fff;
      height: 60px;
      line-height: 60px;
      border-color: transparent;
      padding: 0 20px; }
      .newsletter-subscription-input input[type="text"]:focus {
        border-color: transparent; }
    @media (min-width: 545px) {
      .newsletter-subscription-input {
        margin-right: -80px;
        padding-right: 80px; }
        .newsletter-subscription-input input[type="text"] {
          height: 80px;
          line-height: 80px;
          padding: 0 30px; } }
  .newsletter-subscription-btn {
    position: relative;
    vertical-align: top;
    width: 44px;
    height: 44px;
    font-size: 0;
    margin: 18px 0 0 18px;
    padding: 0;
    box-shadow: none;
    background-color: #fff;
    cursor: pointer; }
    .newsletter-subscription-btn:before {
      content: "\e91b";
      font-size: 16px;
      border: 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      top: 10px;
      right: 10px; }
    .newsletter-subscription-btn:after {
      display: none; }

.checkbox-control .editing-form-control-nested-control {
  display: inline-block;
  vertical-align: top;
  width: 35px; }
  .checkbox-control .editing-form-control-nested-control label {
    padding-bottom: 0px !important; }

.checkbox-control .EditingFormLabel {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-left: -35px;
  padding-left: 35px; }

.modal-form {
  max-width: 645px;
  margin: 0 auto; }
  .modal-form .btn {
    cursor: pointer; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.infobox-wrapper {
  text-align: center; }
  .infobox-wrapper h1 {
    max-width: 1200px;
    margin: 0 auto;
    text-transform: uppercase; }
    @media (min-width: 320px) {
      .infobox-wrapper h1 {
        margin-bottom: 4rem; } }
    @media (min-width: 480px) {
      .infobox-wrapper h1 {
        margin-bottom: 3.63636rem; } }
    @media (min-width: 545px) {
      .infobox-wrapper h1 {
        margin-bottom: 3.33333rem; } }
    @media (min-width: 768px) {
      .infobox-wrapper h1 {
        margin-bottom: 3rem; } }
  .infobox-wrapper h2 {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 20px; }
  .infobox-wrapper .perex {
    max-width: 1200px;
    margin: 0 auto; }
    @media (min-width: 320px) {
      .infobox-wrapper .perex {
        margin-bottom: 4rem; } }

.infobox-item {
  margin-bottom: 4rem;
  text-align: center; }
  @media (min-width: 992px) {
    .infobox-item {
      margin-bottom: 8rem; } }
  .infobox-item.jobs {
    margin-bottom: 40px; }
  .infobox-item-icon {
    margin-bottom: 10px; }
    .infobox-item-icon svg {
      height: 100px; }
      @media (min-width: 545px) {
        .infobox-item-icon svg {
          height: 120px; } }
  .infobox-item-name {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #000;
    transition: all ease-in-out 200ms; }
    .wrapper-black .infobox-item-name {
      color: #fff; }
    .infobox-item-name a {
      position: relative;
      padding: 0 4px; }
      .infobox-item-name a:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100%;
        border-bottom: 2px solid transparent;
        -moz-transition: all ease-in-out 350ms;
        -o-transition: all ease-in-out 350ms;
        -webkit-transition: all ease-in-out 350ms;
        transition: all ease-in-out 350ms; }
      .infobox-item-name a:hover {
        text-decoration: none; }
        .infobox-item-name a:hover:after {
          right: 0;
          border-color: #ff5c00; }
  .infobox-item-text {
    color: #000;
    transition: all ease-in-out 200ms; }
    @media (min-width: 320px) {
      .infobox-item-text {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .infobox-item-text {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .infobox-item-text {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .infobox-item-text {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .infobox-item-text {
        font-size: 2rem; } }
    @media (min-width: 320px) {
      .infobox-item-text {
        line-height: 4.8rem; } }
    @media (min-width: 480px) {
      .infobox-item-text {
        line-height: 4.36364rem; } }
    @media (min-width: 545px) {
      .infobox-item-text {
        line-height: 4rem; } }
    @media (min-width: 768px) {
      .infobox-item-text {
        line-height: 3.2rem; } }
    @media (min-width: 992px) {
      .infobox-item-text {
        line-height: 3rem; } }
    .wrapper-black .infobox-item-text {
      color: #fff; }
  .infobox-item-link {
    padding-top: 10px;
    font-weight: 700; }
    .infobox-item-link a:after {
      position: relative;
      content: "\e908";
      font-size: 10px;
      line-height: 25px;
      margin-left: 0;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .infobox-item-link a:hover {
      color: #323337;
      text-decoration: none; }
      .infobox-item-link a:hover:after {
        margin-left: 3px;
        color: #ff5c00; }

.infobox-items .row {
  margin-left: -15px;
  margin-right: -15px; }
  .infobox-items .row [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px; }
  @media (min-width: 992px) {
    .infobox-items .row {
      margin-left: -45px;
      margin-right: -45px; }
      .infobox-items .row [class*="col-"] {
        padding-left: 45px;
        padding-right: 45px; } }

@media (min-width: 320px) {
  .infobox-items.features-infobox-homepage .infobox-item-name {
    font-size: 5rem; } }

@media (min-width: 480px) {
  .infobox-items.features-infobox-homepage .infobox-item-name {
    font-size: 4.54545rem; } }

@media (min-width: 545px) {
  .infobox-items.features-infobox-homepage .infobox-item-name {
    font-size: 4.5rem; } }

@media (min-width: 320px) {
  .infobox-items.features-infobox-homepage .infobox-item-name {
    line-height: 5.6rem; } }

@media (min-width: 480px) {
  .infobox-items.features-infobox-homepage .infobox-item-name {
    line-height: 5.09091rem; } }

@media (min-width: 545px) {
  .infobox-items.features-infobox-homepage .infobox-item-name {
    line-height: 5rem; } }

@media (min-width: 320px) {
  .infobox-items.infobox-items-strategy .infobox-item-name {
    font-size: 5rem; } }

@media (min-width: 480px) {
  .infobox-items.infobox-items-strategy .infobox-item-name {
    font-size: 4.8rem; } }

@media (min-width: 320px) {
  .infobox-items.infobox-items-strategy .infobox-item-name {
    line-height: 5.6rem; } }

@media (min-width: 480px) {
  .infobox-items.infobox-items-strategy .infobox-item-name {
    line-height: 5.2rem; } }

.wrapper-black .infobox-items.infobox-items-strategy .infobox-item-text {
  color: #fff; }

.infobox-items.infobox-items-business .infobox-item {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto; }

.infobox-items.infobox-items-business .infobox-item-icon {
  margin-bottom: 20px; }

.infobox-items.infobox-items-business .infobox-item-name {
  margin-bottom: 20px; }
  @media (min-width: 320px) {
    .infobox-items.infobox-items-business .infobox-item-name {
      font-size: 5rem; } }
  @media (min-width: 480px) {
    .infobox-items.infobox-items-business .infobox-item-name {
      font-size: 4.54545rem; } }
  @media (min-width: 545px) {
    .infobox-items.infobox-items-business .infobox-item-name {
      font-size: 4.16667rem; } }
  @media (min-width: 768px) {
    .infobox-items.infobox-items-business .infobox-item-name {
      font-size: 3.33333rem; } }
  @media (min-width: 992px) {
    .infobox-items.infobox-items-business .infobox-item-name {
      font-size: 3.2rem; } }
  @media (min-width: 320px) {
    .infobox-items.infobox-items-business .infobox-item-name {
      line-height: 5.6rem; } }
  @media (min-width: 480px) {
    .infobox-items.infobox-items-business .infobox-item-name {
      line-height: 5.09091rem; } }
  @media (min-width: 545px) {
    .infobox-items.infobox-items-business .infobox-item-name {
      line-height: 4.66667rem; } }
  @media (min-width: 768px) {
    .infobox-items.infobox-items-business .infobox-item-name {
      line-height: 4rem; } }

@media (min-width: 992px) {
  .infobox-items.infobox-items-brokers .infobox-item-icon svg {
    height: 150px; } }

@media (min-width: 320px) {
  .infobox-items.infobox-items-brokers .infobox-item-name {
    font-size: 5rem; } }

@media (min-width: 480px) {
  .infobox-items.infobox-items-brokers .infobox-item-name {
    font-size: 4.54545rem; } }

@media (min-width: 545px) {
  .infobox-items.infobox-items-brokers .infobox-item-name {
    font-size: 4.16667rem; } }

@media (min-width: 768px) {
  .infobox-items.infobox-items-brokers .infobox-item-name {
    font-size: 4rem; } }

@media (min-width: 320px) {
  .infobox-items.infobox-items-brokers .infobox-item-name {
    line-height: 5.6rem; } }

@media (min-width: 480px) {
  .infobox-items.infobox-items-brokers .infobox-item-name {
    line-height: 5.09091rem; } }

@media (min-width: 545px) {
  .infobox-items.infobox-items-brokers .infobox-item-name {
    line-height: 4.8rem; } }

a.infobox-item {
  display: block; }
  a.infobox-item:hover, a.infobox-item:focus {
    color: #ff5c00;
    text-decoration: none; }
    a.infobox-item:hover .infobox-item-name,
    a.infobox-item:hover .infobox-item-text, a.infobox-item:focus .infobox-item-name,
    a.infobox-item:focus .infobox-item-text {
      color: #ff5c00; }

.infobox-wrapper-text-map-layout {
  position: relative; }
  .infobox-wrapper-text-map-layout img {
    position: absolute;
    z-index: -1;
    margin-top: -2rem;
    top: 0;
    max-width: none;
    height: 700px !important;
    right: -11rem;
    display: none; }
    @media (min-width: 992px) {
      .infobox-wrapper-text-map-layout img {
        right: 0;
        margin-top: -4rem;
        max-width: 100%;
        height: 910px !important; } }
    @media (min-width: 1200px) {
      .infobox-wrapper-text-map-layout img {
        display: block; } }
    .infobox-wrapper-text-map-layout img:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      background: white;
      background: linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0) 50%); }
  .infobox-wrapper-text-map-layout .infobox-item:last-of-type {
    margin-bottom: 3rem; }
  .infobox-wrapper-text-map-layout .infobox-item-name {
    font-weight: 400;
    color: #000; }
    @media (min-width: 320px) {
      .infobox-wrapper-text-map-layout .infobox-item-name {
        font-size: 5.2rem; } }
    @media (min-width: 480px) {
      .infobox-wrapper-text-map-layout .infobox-item-name {
        font-size: 4.72727rem; } }
    @media (min-width: 545px) {
      .infobox-wrapper-text-map-layout .infobox-item-name {
        font-size: 4.33333rem; } }
    @media (min-width: 768px) {
      .infobox-wrapper-text-map-layout .infobox-item-name {
        font-size: 3.6rem; } }
  .infobox-wrapper-text-map-layout .infobox-item-name b {
    color: #ff5c00;
    font-weight: 700;
    margin-right: 1.5rem; }
    @media (min-width: 320px) {
      .infobox-wrapper-text-map-layout .infobox-item-name b {
        font-size: 9.6rem; } }
  .infobox-wrapper-text-map-layout .infobox-item-name {
    text-align: left; }
  .infobox-wrapper-text-map-layout .infobox-item-text {
    margin-top: 2.4rem;
    text-align: left; }
    .infobox-wrapper-text-map-layout .infobox-item-text br {
      display: none; }
    @media (min-width: 992px) {
      .infobox-wrapper-text-map-layout .infobox-item-text br {
        display: inline-block; } }

.infobox-items.features-small-icons .infobox-item-icon svg {
  height: 70px; }

@media (min-width: 320px) {
  .infobox-items.features-small-icons .infobox-item-name {
    font-size: 4.4rem; } }

@media (min-width: 480px) {
  .infobox-items.features-small-icons .infobox-item-name {
    font-size: 4rem; } }

@media (min-width: 545px) {
  .infobox-items.features-small-icons .infobox-item-name {
    font-size: 3.66667rem; } }

@media (min-width: 768px) {
  .infobox-items.features-small-icons .infobox-item-name {
    font-size: 2.93333rem; } }

@media (min-width: 992px) {
  .infobox-items.features-small-icons .infobox-item-name {
    font-size: 2.75rem; } }

@media (min-width: 1200px) {
  .infobox-items.features-small-icons .infobox-item-name {
    font-size: 2.44444rem; } }

@media (min-width: 1400px) {
  .infobox-items.features-small-icons .infobox-item-name {
    font-size: 2.4rem; } }

.infobox-items.features-small-icons .row [class*="col-"] {
  padding-left: 40px !important;
  padding-right: 40px !important; }

@media (min-width: 992px) {
  .infobox-items.features-small-icons .infobox-item-icon {
    margin-bottom: 1.75rem; } }

.infobox-items.features-small-icons .infobox-item {
  margin-bottom: 3rem; }
  @media (min-width: 992px) {
    .infobox-items.features-small-icons .infobox-item {
      margin-bottom: 5rem; } }

.infobox-items-strateg-btn {
  margin-top: 10px; }
  @media (min-width: 992px) {
    .infobox-items-strateg-btn {
      margin-top: 0; } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@media (min-width: 768px) {
  .milestone-items {
    margin: 0 60px; } }

.milestone-items .slick-list {
  z-index: 1; }
  .milestone-items .slick-list:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #f2f2f2;
    position: absolute;
    top: 50%;
    left: 0px; }

.milestone-items .slick-arrow {
  display: block;
  background-color: #fff;
  color: #ff5c00;
  width: 40px;
  height: 40px;
  text-align: center;
  border: 2px solid #ff5c00;
  overflow: hidden;
  position: absolute;
  bottom: -5px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 0px;
  cursor: pointer;
  z-index: 2;
  padding: 0;
  transition: all 200ms linear; }
  @media (min-width: 768px) {
    .milestone-items .slick-arrow {
      top: 50%;
      bottom: auto; } }
  .milestone-items .slick-arrow:before {
    font-size: 36px;
    line-height: 40px;
    display: inline-block;
    vertical-align: middle; }
  .milestone-items .slick-arrow:hover {
    background-color: #ff5c00;
    color: #fff;
    text-decoration: none; }
  .milestone-items .slick-arrow:focus {
    outline: none; }
  @media (min-width: 768px) {
    .milestone-items .slick-arrow {
      width: 50px;
      height: 50px; }
      .milestone-items .slick-arrow:before {
        font-size: 46px;
        line-height: 48px; } }
  .milestone-items .slick-arrow.slick-disabled {
    background-color: #fff;
    border-color: rgba(255, 92, 0, 0.2);
    color: rgba(255, 92, 0, 0.2);
    cursor: not-allowed; }
    .milestone-items .slick-arrow.slick-disabled:hover {
      background-color: #fff; }

.milestone-items .slick-prev {
  left: 15px; }
  @media (min-width: 768px) {
    .milestone-items .slick-prev {
      left: -60px; } }
  .milestone-items .slick-prev:before {
    content: "\e935";
    transform: rotateZ(180deg); }

.milestone-items .slick-next {
  right: 15px; }
  @media (min-width: 768px) {
    .milestone-items .slick-next {
      right: -60px; } }
  .milestone-items .slick-next:before {
    content: "\e935"; }

.milestone-item {
  height: 400px;
  position: relative; }
  @media (min-width: 768px) {
    .milestone-item {
      height: 860px;
      margin: 0 120px; } }
  .milestone-item:focus {
    outline: none; }
  .milestone-item-inner {
    background-color: #f2f2f2;
    height: 400px;
    opacity: 0;
    -moz-transition: opacity 400ms linear, left 400ms linear 400ms, right 400ms linear 400ms;
    -o-transition: opacity 400ms linear, left 400ms linear 400ms, right 400ms linear 400ms;
    -webkit-transition: opacity 400ms linear, left 400ms linear 400ms, right 400ms linear 400ms;
    transition: opacity 400ms linear, left 400ms linear 400ms, right 400ms linear 400ms;
    display: flex;
    flex-flow: row wrap; }
    @media (min-width: 768px) {
      .milestone-item-inner {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px; } }
    .milestone-item-inner:before {
      content: '';
      display: block;
      height: 7px;
      width: 7px;
      background-color: #ff5c00;
      border-radius: 50%;
      position: absolute;
      top: 427px;
      left: 57px; }
    .milestone-item-inner:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 20px 0 20px;
      border-color: #f2f2f2 transparent transparent transparent;
      position: absolute;
      top: 400px;
      left: 40px; }
  .milestone-item-teaser {
    height: 165px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    flex: 100%;
    order: 1; }
    @media (min-width: 768px) {
      .milestone-item-teaser {
        height: 200px; } }
  .milestone-item-info {
    padding: 15px;
    flex: 100%;
    order: 2; }

@media (min-width: 768px) and (min-width: 320px) {
  .milestone-item-info {
    padding-top: 3rem; } }

@media (min-width: 768px) and (min-width: 320px) {
  .milestone-item-info {
    padding-right: 4rem; } }

@media (min-width: 768px) and (min-width: 320px) {
  .milestone-item-info {
    padding-bottom: 3rem; } }

@media (min-width: 768px) and (min-width: 320px) {
  .milestone-item-info {
    padding-left: 4rem; } }
  .milestone-item-name {
    font-weight: 700;
    color: #646569; }
    @media (min-width: 320px) {
      .milestone-item-name {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .milestone-item-name {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .milestone-item-name {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .milestone-item-name {
        font-size: 2.2rem; } }
    @media (min-width: 320px) {
      .milestone-item-name {
        line-height: 4rem; } }
    @media (min-width: 480px) {
      .milestone-item-name {
        line-height: 3.63636rem; } }
    @media (min-width: 545px) {
      .milestone-item-name {
        line-height: 3.33333rem; } }
    @media (min-width: 768px) {
      .milestone-item-name {
        line-height: 3rem; } }
    @media (min-width: 320px) {
      .milestone-item-name {
        margin-bottom: 2rem; } }
  .milestone-item-text {
    min-height: 90px;
    overflow: hidden;
    text-overflow: ellipsis; }
  @media (min-width: 768px) {
    .milestone-item:nth-of-type(even) .milestone-item-inner {
      top: 460px; } }
  .milestone-item:nth-of-type(even) .milestone-item-inner:before {
    top: -33px; }
  .milestone-item:nth-of-type(even) .milestone-item-inner:after {
    top: -20px;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #f2f2f2 transparent; }
  .milestone-item:nth-of-type(even) .milestone-item-teaser {
    order: 3; }
  .milestone-item.slick-active .milestone-item-inner {
    -moz-transition: opacity 400ms linear 400ms, left 400ms linear, right 400ms linear;
    -o-transition: opacity 400ms linear 400ms, left 400ms linear, right 400ms linear;
    -webkit-transition: opacity 400ms linear 400ms, left 400ms linear, right 400ms linear;
    transition: opacity 400ms linear 400ms, left 400ms linear, right 400ms linear;
    opacity: 1; }
  @media (min-width: 768px) {
    .milestone-item.slick-current .milestone-item-inner {
      left: -60px;
      right: -200px; }
    .milestone-item.slick-current + div .milestone-item-inner {
      left: -130px;
      right: -130px; }
    .milestone-item.slick-current + div + div .milestone-item-inner {
      left: -200px;
      right: -60px; } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.blog-filter-main ul {
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 992px) {
    .blog-filter-main ul {
      padding-bottom: 30px; } }
  .blog-filter-main ul:before, .blog-filter-main ul:after {
    content: '';
    display: table; }
  .blog-filter-main ul:after {
    clear: both; }
  .blog-filter-main ul li {
    display: block;
    float: left;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px; }
    .blog-filter-main ul li:before {
      display: none; }
    .blog-filter-main ul li a {
      display: block;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 50px;
      padding: 0 20px;
      border: 2px solid #ff5c00;
      font-weight: 500; }
      .blog-filter-main ul li a:hover {
        background-color: #ff5c00;
        text-decoration: none; }
    .blog-filter-main ul li.Highlighted a {
      background-color: #ff5c00;
      color: #fff; }
    @media (min-width: 768px) {
      .blog-filter-main ul li {
        width: 50%;
        margin-bottom: 30px; } }
    @media (min-width: 992px) {
      .blog-filter-main ul li {
        width: 25%; }
        .blog-filter-main ul li a {
          font-size: 17px;
          line-height: 70px; } }

.blog-filter-secondary {
  text-align: center; }
  @media (min-width: 320px) {
    .blog-filter-secondary {
      margin-bottom: 6rem; } }
  .blog-filter-secondary a {
    display: inline-block;
    background-color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 40px;
    padding: 0 15px;
    margin: 0 0 15px 0;
    width: 100%; }
    @media (min-width: 545px) {
      .blog-filter-secondary a {
        width: auto;
        font-size: 15px;
        padding: 0 30px;
        margin: 0 5px 10px 5px; } }
    .blog-filter-secondary a.active {
      background-color: #ff5c00;
      color: #fff; }

.blog-items {
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 1400px) {
    .blog-items {
      margin-left: -35px;
      margin-right: -35px; } }
  .blog-items .items-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: wrap;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap; }
  .blog-items .show-more-container {
    text-align: center; }
    @media (min-width: 320px) {
      .blog-items .show-more-container {
        padding-top: 4rem; } }
    @media (min-width: 480px) {
      .blog-items .show-more-container {
        padding-top: 3.63636rem; } }
    @media (min-width: 545px) {
      .blog-items .show-more-container {
        padding-top: 3.33333rem; } }
    @media (min-width: 768px) {
      .blog-items .show-more-container {
        padding-top: 3rem; } }
    @media (min-width: 320px) {
      .blog-items .show-more-container {
        padding-bottom: 4rem; } }
    @media (min-width: 480px) {
      .blog-items .show-more-container {
        padding-bottom: 3.63636rem; } }
    @media (min-width: 545px) {
      .blog-items .show-more-container {
        padding-bottom: 3.33333rem; } }
    @media (min-width: 768px) {
      .blog-items .show-more-container {
        padding-bottom: 3rem; } }
    .blog-items .show-more-container .arrow-down-link {
      display: inline-block;
      background-color: transparent;
      color: #fff;
      text-align: center;
      border-radius: 0;
      overflow: hidden;
      border: 2px solid #ff5c00;
      font-size: 20px;
      line-height: 24px;
      padding: 11px 25px 11px 25px;
      text-transform: uppercase;
      position: relative;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms;
      font-weight: 500; }
      @media (min-width: 768px) {
        .blog-items .show-more-container .arrow-down-link {
          font-size: 20px;
          line-height: 24px;
          padding: 13px 34px 13px 34px; } }
      .blog-items .show-more-container .arrow-down-link:hover {
        text-decoration: none;
        background-color: #ff5c00;
        color: #fff; }

.blog-item {
  -webkit-flex: 100%;
  -moz-flex: 100%;
  -ms-flex: 100%;
  -webkit-flex: 100%;
  flex: 100%;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 30px; }
  .blog-item-teaser {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    height: 211px; }
    .blog-item-teaser-inner {
      display: block;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      -moz-transition: all ease-in-out 400ms;
      -o-transition: all ease-in-out 400ms;
      -webkit-transition: all ease-in-out 400ms;
      transition: all ease-in-out 400ms; }
  .blog-item-info {
    background-color: #171717;
    position: relative;
    padding: 25px 20px 20px 20px;
    z-index: 2;
    -moz-transition: all ease-in-out 400ms;
    -o-transition: all ease-in-out 400ms;
    -webkit-transition: all ease-in-out 400ms;
    transition: all ease-in-out 400ms;
    text-align: left; }
    .blog-item-info-name {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      display: block;
      min-height: 60px;
      margin-bottom: 3rem; }
    .blog-item-info a.blog-item-info-name {
      color: #fff; }
      .blog-item-info a.blog-item-info-name:hover {
        color: #ff5c00;
        text-decoration: none; }
    .blog-item-info-category {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      line-height: 30px;
      padding: 0 20px;
      background-color: #525151;
      color: #fff;
      text-transform: uppercase;
      max-width: 180px;
      font-size: 14px; }
      @media (min-width: 545px) {
        .blog-item-info-category {
          max-width: 120px; } }
      @media (min-width: 768px) {
        .blog-item-info-category {
          max-width: 140px; } }
      @media (min-width: 992px) {
        .blog-item-info-category {
          max-width: 160px; } }
      @media (min-width: 1400px) {
        .blog-item-info-category {
          max-width: none;
          font-size: 16px; } }
      .blog-item-info-category:hover {
        text-decoration: none;
        color: #fff;
        background-color: #ff5c00; }
    .blog-item-info-date {
      float: right;
      line-height: 30px;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #ff5c00; }
  @media (min-width: 545px) {
    .blog-item {
      -webkit-flex: 50%;
      -moz-flex: 50%;
      -ms-flex: 50%;
      -webkit-flex: 50%;
      flex: 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .blog-item {
      -webkit-flex: 33.33333%;
      -moz-flex: 33.33333%;
      -ms-flex: 33.33333%;
      -webkit-flex: 33.33333%;
      flex: 33.33333%;
      max-width: 33.333333%; }
      .blog-item-teaser {
        height: 211px; }
      .blog-item-info {
        background-color: #171717; } }
  @media (min-width: 1400px) {
    .blog-item {
      padding-left: 35px;
      padding-right: 35px; } }

.blog-page .grey-wrapper {
  background-color: #000; }

.blog-page-flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap; }
  @media (min-width: 320px) {
    .blog-page-flex {
      padding-top: 14rem; } }
  @media (min-width: 320px) {
    .blog-page-flex {
      padding-bottom: 8rem; } }

.blog-page-header {
  border-top: 1px solid #fff;
  padding-bottom: 5px;
  padding-top: 10px;
  color: #fff;
  -webkit-flex: 100%;
  -moz-flex: 100%;
  -ms-flex: 100%;
  -webkit-flex: 100%;
  flex: 100%;
  -webkit-box-ordinal-group: 2;
  -moz-box-ordinal-group: 2;
  -ms-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  -moz-order: 2;
  -ms-order: 2;
  order: 2; }
  @media (min-width: 320px) {
    .blog-page-header {
      margin-top: 4rem; } }
  @media (min-width: 480px) {
    .blog-page-header {
      margin-top: 3.63636rem; } }
  @media (min-width: 545px) {
    .blog-page-header {
      margin-top: 3.33333rem; } }
  @media (min-width: 768px) {
    .blog-page-header {
      margin-top: 3rem; } }
  @media (min-width: 320px) {
    .blog-page-header {
      margin-bottom: 4rem; } }
  @media (min-width: 1200px) {
    .blog-page-header {
      -webkit-box-ordinal-group: 0;
      -moz-box-ordinal-group: 0;
      -ms-box-ordinal-group: 0;
      -ms-flex-order: 0;
      -webkit-order: 0;
      -moz-order: 0;
      -ms-order: 0;
      order: 0;
      padding-top: 0;
      border-top: 0;
      border-bottom: 1px solid #fff; } }
  .blog-page-header-tags {
    font-size: 0;
    margin-bottom: 10px; }
    .blog-page-header-tags a {
      display: inline-block;
      vertical-align: middle;
      line-height: 28px;
      padding: 0 15px;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 10px;
      border: 2px solid #ff5c00; }
      .blog-page-header-tags a:not(.blog-page-header-category) {
        background-color: #fff;
        border: 2px solid #fff;
        color: #ff5c00; }
        .blog-page-header-tags a:not(.blog-page-header-category):hover {
          background-color: #e5e5e5;
          color: #323337;
          text-decoration: none; }
      .blog-page-header-tags a:not(:last-of-type) {
        margin-right: 10px; }
      @media (min-width: 545px) {
        .blog-page-header-tags a {
          font-size: 14px;
          padding: 0 20px; } }
    @media (min-width: 768px) {
      .blog-page-header-tags {
        margin-bottom: 0px; } }
  .blog-page-header-category {
    background-color: transparent;
    color: #fff; }
    .blog-page-header-category:hover {
      background-color: #ff5c00;
      color: #fff;
      text-decoration: none; }
  .blog-page-header-date {
    margin-bottom: 10px; }
    @media (min-width: 545px) {
      .blog-page-header-date {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 0px; }
        .blog-page-header-date:after {
          content: "|";
          margin-left: 10px;
          color: #fff; } }
  .blog-page-header-author {
    margin-bottom: 10px;
    display: none; }
    @media (min-width: 545px) {
      .blog-page-header-author {
        display: none;
        margin-right: 10px;
        margin-bottom: 0px; }
        .blog-page-header-author:after {
          content: "|";
          margin-left: 10px;
          color: #fff; } }
  .blog-page-header-social {
    display: inline-block; }
    .blog-page-header-social ul {
      display: inline-block;
      vertical-align: -8px;
      margin-left: 10px; }
      .blog-page-header-social ul li {
        margin: 0;
        padding: 0; }
        .blog-page-header-social ul li:before {
          display: none; }
        .blog-page-header-social ul li .social-ico {
          color: #fff; }
          .blog-page-header-social ul li .social-ico:hover {
            text-decoration: none; }
          .blog-page-header-social ul li .social-ico.facebook:before {
            content: "\e93b";
            font-size: 32px; }

.blog-page-title {
  -webkit-flex: 100%;
  -moz-flex: 100%;
  -ms-flex: 100%;
  -webkit-flex: 100%;
  flex: 100%;
  -webkit-box-ordinal-group: 0;
  -moz-box-ordinal-group: 0;
  -ms-box-ordinal-group: 0;
  -ms-flex-order: 0;
  -webkit-order: 0;
  -moz-order: 0;
  -ms-order: 0;
  order: 0;
  color: #fff; }
  @media (min-width: 1200px) {
    .blog-page-title {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -ms-box-ordinal-group: 1;
      -ms-flex-order: 1;
      -webkit-order: 1;
      -moz-order: 1;
      -ms-order: 1;
      order: 1; } }
  .blog-page-title h1 {
    text-align: center; }
    @media (min-width: 320px) {
      .blog-page-title h1 {
        margin-bottom: 6rem; } }
    @media (min-width: 1200px) {
      .blog-page-title h1 {
        padding: 0 100px; } }

.blog-page-content {
  -webkit-flex: 100%;
  -moz-flex: 100%;
  -ms-flex: 100%;
  -webkit-flex: 100%;
  flex: 100%;
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-box-ordinal-group: 1;
  -ms-flex-order: 1;
  -webkit-order: 1;
  -moz-order: 1;
  -ms-order: 1;
  order: 1;
  color: #fff; }
  .blog-page-content p,
  .blog-page-content big,
  .blog-page-content .perex {
    color: #fff; }
  @media (min-width: 1200px) {
    .blog-page-content {
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      -ms-box-ordinal-group: 2;
      -ms-flex-order: 2;
      -webkit-order: 2;
      -moz-order: 2;
      -ms-order: 2;
      order: 2; } }

.blog-page-latest {
  color: #fff; }
  @media (min-width: 1200px) {
    .blog-page-latest .items-container .blog-item:nth-of-type(4) {
      display: none; } }

.author-item {
  background-color: #171717;
  font-size: 0;
  display: flex;
  padding: 20px 15px; }
  @media (min-width: 768px) {
    .author-item {
      padding: 30px 35px; } }
  .author-item-left {
    margin: 0 3rem 0 0; }
  .author-item-right {
    width: 100%; }
  .author-item-avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
  .author-item-name {
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase; }
  .author-item-description {
    font-size: 16px;
    line-height: 25px;
    color: #fff; }
  .author-item-social {
    text-align: right; }
    .author-item-social ul {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
      .author-item-social ul li {
        padding: 0; }
        .author-item-social ul li:before {
          display: none; }
        .author-item-social ul li a {
          font-size: 0;
          display: block;
          text-decoration: none;
          width: 30px;
          height: 30px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%;
          background: none;
          border: 0;
          color: #fff;
          text-align: center;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          .author-item-social ul li a:focus, .author-item-social ul li a:hover {
            background: none;
            color: #fff;
            border: 0;
            text-decoration: none; }
    .author-item-social .facebook {
      background: #3b5998; }
      .author-item-social .facebook:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .facebook:focus, .author-item-social .facebook:hover {
        background: #2d4373; }
    .author-item-social .twitter {
      background: #00aced; }
      .author-item-social .twitter:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .twitter:focus, .author-item-social .twitter:hover {
        background: #0087ba; }
    .author-item-social .linkedin {
      background: #0d77b7; }
      .author-item-social .linkedin:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .linkedin:focus, .author-item-social .linkedin:hover {
        background: #0a5887; }
    .author-item-social .youtube {
      background: #cc181e; }
      .author-item-social .youtube:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .youtube:focus, .author-item-social .youtube:hover {
        background: #9e1317; }
    .author-item-social .instagram {
      background: #8a3ab9; }
      .author-item-social .instagram:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .instagram:focus, .author-item-social .instagram:hover {
        background: #6d2e92; }
    .author-item-social .pinterest {
      background: #cd2029; }
      .author-item-social .pinterest:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .pinterest:focus, .author-item-social .pinterest:hover {
        background: #a11920; }
    .author-item-social .google {
      background: #d34836; }
      .author-item-social .google:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .google:focus, .author-item-social .google:hover {
        background: #b03626; }
    .author-item-social .vimeo {
      background: #45bbff; }
      .author-item-social .vimeo:before {
        content: "";
        line-height: 30px;
        font-size: 21px; }
      .author-item-social .vimeo:focus, .author-item-social .vimeo:hover {
        background: #12a8ff; }
    .author-item-social ul {
      display: block; }
      .author-item-social ul li {
        display: inline-block;
        margin-bottom: 20px; }

.blog-stories {
  margin: 0; }
  @media (min-width: 992px) {
    .blog-stories {
      padding-left: 70px;
      padding-right: 70px; } }
  @media (min-width: 992px) {
    .blog-stories .blog-item {
      -webkit-flex: 50%;
      -moz-flex: 50%;
      -ms-flex: 50%;
      -webkit-flex: 50%;
      flex: 50%;
      max-width: 50%;
      margin-bottom: 50px; } }
  @media (min-width: 1400px) {
    .blog-stories .blog-item {
      padding: 0 70px;
      margin-bottom: 80px; } }
  .blog-stories .blog-item-info-date {
    font-weight: 700;
    text-transform: uppercase; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.cta-image-left {
  background-size: cover;
  background-position: center; }
  .cta-image-left.founders {
    position: relative; }
    .cta-image-left.founders:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.8);
      background: rgba(0, 0, 0, 0.9); }
      body.EditMode .cta-image-left.founders:before,
      body.DesignMode .cta-image-left.founders:before {
        display: none; }

@media (min-width: 768px) and (min-width: 320px) {
  .cta-image-left.founders .cta-content-image {
    margin-top: 12rem; } }
    @media (min-width: 992px) {
      .cta-image-left.founders .cta-content-image .cta-content-image-inner {
        width: 450px;
        height: 450px; } }
    .cta-image-left.founders .cta-content-image .cta-content-image-caption {
      text-align: center;
      color: #fff;
      padding: 20px 0; }
      .cta-image-left.founders .cta-content-image .cta-content-image-caption > strong {
        display: block;
        text-align: center;
        color: #ff5c00;
        font-weight: 700; }
        @media (min-width: 320px) {
          .cta-image-left.founders .cta-content-image .cta-content-image-caption > strong {
            font-size: 4rem; } }
        @media (min-width: 480px) {
          .cta-image-left.founders .cta-content-image .cta-content-image-caption > strong {
            font-size: 3.63636rem; } }
        @media (min-width: 545px) {
          .cta-image-left.founders .cta-content-image .cta-content-image-caption > strong {
            font-size: 3.33333rem; } }
        @media (min-width: 768px) {
          .cta-image-left.founders .cta-content-image .cta-content-image-caption > strong {
            font-size: 2.66667rem; } }
        @media (min-width: 992px) {
          .cta-image-left.founders .cta-content-image .cta-content-image-caption > strong {
            font-size: 2.5rem; } }
        @media (min-width: 1200px) {
          .cta-image-left.founders .cta-content-image .cta-content-image-caption > strong {
            font-size: 2.22222rem; } }
        @media (min-width: 1400px) {
          .cta-image-left.founders .cta-content-image .cta-content-image-caption > strong {
            font-size: 2.2rem; } }
    .cta-image-left.founders .cta-content {
      justify-content: space-between;
      align-items: flex-start; }
      @media (min-width: 768px) {
        .cta-image-left.founders .cta-content {
          flex-flow: row nowrap; } }
    .cta-image-left.founders .cta-content-text {
      color: #fff;
      flex: 1 1 100%;
      max-width: none;
      margin: 0; }
      .cta-image-left.founders .cta-content-text-inner {
        padding: 30px 0; }
        @media (min-width: 768px) {
          .cta-image-left.founders .cta-content-text-inner {
            padding: 60px 0 50px 40px; } }
        @media (min-width: 992px) {
          .cta-image-left.founders .cta-content-text-inner {
            padding: 60px 0 50px 115px; } }

.cta-content {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  width: 100%; }
  @media (min-width: 1400px) {
    .cta-content {
      align-items: center;
      flex-flow: row nowrap; } }
  .cta-content.with-devider h2 {
    position: relative;
    font-weight: 500; }
    @media (min-width: 320px) {
      .cta-content.with-devider h2 {
        padding-bottom: 4rem; } }
    @media (min-width: 480px) {
      .cta-content.with-devider h2 {
        padding-bottom: 3.63636rem; } }
    @media (min-width: 545px) {
      .cta-content.with-devider h2 {
        padding-bottom: 3.33333rem; } }
    @media (min-width: 768px) {
      .cta-content.with-devider h2 {
        padding-bottom: 3rem; } }
    .cta-content.with-devider h2:after {
      content: '';
      width: 86px;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #ff5c00; }
  .cta-content-image {
    position: relative;
    width: 100%;
    flex: 0 1 450px;
    max-width: 450px; }
    @media (min-width: 320px) and (max-width: 1399px) {
      .cta-content-image {
        padding: calc(30px + 1rem) 0 30px; } }
    .cta-content-image-inner {
      width: 100%;
      max-width: 450px;
      aspect-ratio: 1/1;
      background-size: cover;
      background-position: center;
      padding-top: 100%; }
      @media (min-width: 768px) {
        .cta-content-image-inner {
          padding-top: 0;
          width: 240px;
          height: 240px;
          margin-left: auto; } }
      @media (min-width: 992px) {
        .cta-content-image-inner {
          width: 480px;
          height: 480px; } }
  .cta-content-text {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex: 1 0 100%;
    position: relative;
    color: #000; }
    @media (min-width: 320px) {
      .cta-content-text {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .cta-content-text {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .cta-content-text {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .cta-content-text {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .cta-content-text {
        font-size: 2rem; } }
    @media (min-width: 320px) {
      .cta-content-text {
        line-height: 4.8rem; } }
    @media (min-width: 480px) {
      .cta-content-text {
        line-height: 4.36364rem; } }
    @media (min-width: 545px) {
      .cta-content-text {
        line-height: 4rem; } }
    @media (min-width: 768px) {
      .cta-content-text {
        line-height: 3.2rem; } }
    @media (min-width: 992px) {
      .cta-content-text {
        line-height: 3rem; } }
    .cta-content-text p {
      color: #000; }
    .cta-content-text ul li {
      color: #000; }
    .wrapper-black .cta-content-text {
      color: #fff; }
      .wrapper-black .cta-content-text p {
        color: #fff; }
      .wrapper-black .cta-content-text ul li {
        color: #fff; }
    @media (min-width: 768px) {
      .cta-content-text {
        flex: 1 0 80%;
        max-width: 80%; } }
    @media (min-width: 1400px) {
      .cta-content-text {
        flex: 1 0 60%;
        max-width: 60%;
        margin: 0 -80px; } }
    .cta-content-text-inner {
      height: 100%;
      padding: 30px 0; }
      @media (min-width: 768px) {
        .cta-content-text-inner {
          padding: 50px 0;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: flex-start; } }
      @media (min-width: 1400px) {
        .cta-content-text-inner {
          padding: 80px; } }
    .cta-content-text h2,
    .cta-content-text .h2,
    .cta-content-text h3,
    .cta-content-text .h3 {
      color: #000; }
      .wrapper-black .cta-content-text h2, .wrapper-black
      .cta-content-text .h2, .wrapper-black
      .cta-content-text h3, .wrapper-black
      .cta-content-text .h3 {
        color: #fff; }

.cta-container.reverse {
  margin-left: 0;
  margin-right: auto; }
  .cta-container.reverse .cta-content {
    justify-content: flex-end; }
  @media (min-width: 768px) {
    .cta-container.reverse .cta-content-text-inner {
      text-align: right;
      align-items: flex-end; } }

.cta-container.homepage .cta-content {
  display: block; }
  .cta-container.homepage .cta-content.with-devider h2:after {
    left: 50%;
    transform: translateX(-50%); }

@media (min-width: 992px) {
  .cta-container.homepage .cta-content-text {
    width: 50%;
    margin: 0 -60px; } }

.cta-container.homepage .cta-content-text-inner {
  max-width: none; }
  @media (min-width: 992px) {
    .cta-container.homepage .cta-content-text-inner {
      padding: 60px; } }

.cta-container.homepage h2 {
  margin-bottom: 2.5rem;
  text-align: center !important;
  display: block;
  width: 100%; }

.cta-container.homepage p {
  width: 100%;
  margin: 0; }

.cta-wrapper.cta-wrapper-backgound-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  .cta-wrapper.cta-wrapper-backgound-image:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, #fff 99%); }
    @media (min-width: 992px) {
      .cta-wrapper.cta-wrapper-backgound-image:before {
        background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, #fff 99%); } }
    .wrapper-black .cta-wrapper.cta-wrapper-backgound-image:before {
      background: radial-gradient(circle, rgba(0, 0, 0, 0.7) 0%, #000 99%); }
      @media (min-width: 992px) {
        .wrapper-black .cta-wrapper.cta-wrapper-backgound-image:before {
          background: radial-gradient(circle, rgba(0, 0, 0, 0.4) 0%, #000 99%); } }
  .cta-wrapper.cta-wrapper-backgound-image .cta-container {
    position: relative;
    z-index: 2; }
  .cta-wrapper.cta-wrapper-backgound-image.cta-wrapper-map {
    background-size: 60% auto;
    background-position: top right;
    padding-top: 100px; }
    @media (min-width: 1400px) {
      .cta-wrapper.cta-wrapper-backgound-image.cta-wrapper-map {
        background-size: 1200px auto;
        padding-bottom: 200px; } }
    .cta-wrapper.cta-wrapper-backgound-image.cta-wrapper-map:before {
      display: none; }
    .cta-wrapper.cta-wrapper-backgound-image.cta-wrapper-map h2:after {
      display: none; }
    @media (min-width: 1400px) {
      .cta-wrapper.cta-wrapper-backgound-image.cta-wrapper-map .cta-container.homepage .cta-content-text {
        min-height: 837px; } }

.cta-wrapper.cta-wrapper-with-overflow {
  padding-bottom: 120px; }
  @media (min-width: 992px) {
    .cta-wrapper.cta-wrapper-with-overflow {
      padding-bottom: 220px; } }
  @media (min-width: 1400px) {
    .cta-wrapper.cta-wrapper-with-overflow {
      padding-bottom: 320px; } }

.cta-wrapper.cta-wrapper-persons .cta-content-text h3 {
  position: relative; }
  .cta-wrapper.cta-wrapper-persons .cta-content-text h3:before {
    content: '';
    width: 86px;
    height: 4px;
    position: absolute;
    margin: 15px 0 0 0;
    top: 100%;
    left: 0;
    background-color: #ff5c00; }

@media (min-width: 1400px) {
  .cta-wrapper.cta-wrapper-persons {
    padding-bottom: 240px; } }

.cta-wrapper.cta-wrapper-timeline:before {
  display: none; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.parallax-slider-widget-wrapper {
  position: relative;
  padding: 30px 0; }
  @media (min-width: 768px) {
    .parallax-slider-widget-wrapper {
      padding: 70px 0; } }
  @media (min-width: 768px) {
    .board-container .parallax-slider-widget-wrapper {
      z-index: 1;
      margin-bottom: -110px; } }
  @media (min-width: 1400px) {
    .board-container .parallax-slider-widget-wrapper {
      margin-bottom: -160px; } }
  .parallax-slider-widget-wrapper h2 {
    color: #fff;
    position: relative; }
    @media (min-width: 320px) {
      .parallax-slider-widget-wrapper h2 {
        padding-bottom: 4rem; } }
    @media (min-width: 480px) {
      .parallax-slider-widget-wrapper h2 {
        padding-bottom: 3.63636rem; } }
    @media (min-width: 545px) {
      .parallax-slider-widget-wrapper h2 {
        padding-bottom: 3.33333rem; } }
    @media (min-width: 768px) {
      .parallax-slider-widget-wrapper h2 {
        padding-bottom: 3rem; } }
    .parallax-slider-widget-wrapper h2:after {
      content: '';
      width: 86px;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #ff5c00; }
  .parallax-slider-widget-wrapper p {
    color: #fff; }

.parallax-slider-widget-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
  @media (min-width: 768px) {
    .parallax-slider-widget-background {
      width: calc(66.666667% + 120px); } }

@media (min-width: 768px) {
  .parallax-slider-widget-row {
    display: flex;
    flex-flow: row nowrap; } }

@media (min-width: 768px) {
  .parallax-slider-widget-left {
    flex: 0 0 calc(100% - 240px);
    max-width: calc(100% - 240px);
    padding-right: 40px; } }

@media (min-width: 992px) {
  .parallax-slider-widget-left {
    flex: 0 0 calc(100% - 480px);
    max-width: calc(100% - 480px);
    padding-right: 100px; } }

@media (min-width: 768px) {
  .parallax-slider-widget-right {
    padding-top: 0;
    flex: 0 0 240px;
    max-width: 240px; } }

@media (min-width: 992px) {
  .parallax-slider-widget-right {
    padding-top: 0;
    flex: 0 0 480px;
    max-width: 480px; } }

.parallax-slider-wrapper {
  color: #fff;
  position: relative; }

.parallax-slider-background-item {
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: calc(100vw - 30px); }
  @media (min-width: 768px) {
    .parallax-slider-background-item {
      padding-top: 0;
      height: 480px; } }
  .parallax-slider-background-item:focus {
    outline: none; }

.parallax-slider-item-name {
  font-size: 22px;
  font-weight: 700; }

.parallax-slider-item-position {
  font-size: 22px;
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .parallax-slider-item-position {
      margin-bottom: 20px; } }

.parallax-slider-item:focus {
  outline: none; }

.parallax-slider-controls {
  font-size: 0px;
  margin-top: 20px;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .parallax-slider-controls {
      margin-top: 60px; } }
  @media (min-width: 992px) {
    .parallax-slider-controls {
      margin-top: 0px; } }

.parallax-slider-arrow {
  display: inline-block;
  background-color: transparent;
  color: #ff5c00;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 36px;
  border-radius: 00%;
  border: 2px solid #ff5c00;
  font-size: 24px;
  overflow: hidden; }
  .parallax-slider-arrow:before {
    display: inline-block; }
  .parallax-slider-arrow:hover {
    background-color: #ff5c00;
    color: #fff;
    text-decoration: none; }
  @media (min-width: 768px) {
    .parallax-slider-arrow {
      width: 50px;
      height: 50px;
      line-height: 46px; } }
  .parallax-slider-arrow.prev:before {
    content: "\e90a";
    transform: rotateZ(180deg); }
  .parallax-slider-arrow.next {
    margin-left: -2px; }
    .parallax-slider-arrow.next:before {
      content: "\e90a"; }

.project-parallax-container .parallax-slider {
  background-color: rgba(0, 0, 0, 0.9); }
  .project-parallax-container .parallax-slider-text {
    padding-top: 20px;
    padding-right: 60px;
    min-height: 270px;
    padding-bottom: 260px;
    color: #000; }
    .project-parallax-container .parallax-slider-text h2 {
      color: #000; }
    .project-parallax-container .parallax-slider-text p {
      color: #000; }
    .project-parallax-container .parallax-slider-text ul li {
      color: #000; }
    @media (min-width: 768px) {
      .project-parallax-container .parallax-slider-text {
        padding-top: 70px;
        padding-bottom: 0px;
        padding-right: 60px;
        min-height: 270px;
        margin-bottom: -200px; } }
  .project-parallax-container .parallax-slider-wrapper {
    position: absolute;
    top: 100%;
    width: 100%;
    margin-top: -260px; }
    @media (min-width: 768px) {
      .project-parallax-container .parallax-slider-wrapper {
        position: relative;
        margin-top: 0;
        top: auto; } }
  .project-parallax-container .parallax-slider-spacer {
    padding-top: 60px; }
    @media (min-width: 768px) {
      .project-parallax-container .parallax-slider-spacer {
        position: relative;
        padding-top: 0;
        left: 90%;
        top: 0;
        margin-bottom: -50px; } }
    @media (min-width: 1200px) {
      .project-parallax-container .parallax-slider-spacer {
        left: 100%; } }
  .project-parallax-container .parallax-slider-widget-wrapper {
    padding: 0; }
    .project-parallax-container .parallax-slider-widget-wrapper:before {
      content: '';
      background-color: rgba(255, 255, 255, 0.9);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .project-parallax-container .parallax-slider-widget-background {
    width: 100%; }
    .project-parallax-container .parallax-slider-widget-background:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #fff; }
      @media (min-width: 768px) {
        .project-parallax-container .parallax-slider-widget-background:after {
          background: transparent; } }
    @media (min-width: 768px) {
      .project-parallax-container .parallax-slider-widget-background {
        width: 60%;
        height: 100%; }
        .project-parallax-container .parallax-slider-widget-background:after {
          display: none; } }
    @media (min-width: 1200px) {
      .project-parallax-container .parallax-slider-widget-background {
        width: 55%; } }
    @media (min-width: 1500px) {
      .project-parallax-container .parallax-slider-widget-background {
        width: 47%; } }
  .project-parallax-container .parallax-slider-widget-left {
    position: relative;
    z-index: 2; }
    @media (min-width: 768px) {
      .project-parallax-container .parallax-slider-widget-left {
        padding-right: 0;
        flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 1200px) {
      .project-parallax-container .parallax-slider-widget-left {
        flex: 0 0 490px;
        max-width: 490px; } }
  .project-parallax-container .parallax-slider-widget-right {
    margin: 0 -25px; }
    @media (min-width: 768px) {
      .project-parallax-container .parallax-slider-widget-right {
        position: relative;
        z-index: 1;
        flex: 0 0 calc(50% + 45px);
        max-width: calc(50% + 45px);
        padding-left: 60px;
        margin: 0; } }
    @media (min-width: 1200px) {
      .project-parallax-container .parallax-slider-widget-right {
        flex: 0 0 calc(100vw - 490px);
        max-width: calc(100vw - 490px);
        padding-left: 120px; } }
    @media (min-width: 1600px) {
      .project-parallax-container .parallax-slider-widget-right {
        flex: 0 0 calc(50vw + 230px);
        max-width: calc(50vw + 230px); } }
  .project-parallax-container .parallax-slider-item {
    color: #323337;
    padding: 20px 30px; }
    @media (min-width: 992px) {
      .project-parallax-container .parallax-slider-item {
        padding: 50px 60px; } }
    .project-parallax-container .parallax-slider-item-logo {
      margin-bottom: 20px;
      max-height: 60px; }
      @media (min-width: 992px) {
        .project-parallax-container .parallax-slider-item-logo {
          margin-bottom: 40px; } }
      .project-parallax-container .parallax-slider-item-logo img {
        max-height: 60px !important; }
        @media (min-width: 992px) {
          .project-parallax-container .parallax-slider-item-logo img {
            max-height: none; } }
    .project-parallax-container .parallax-slider-item-name {
      color: #fff;
      margin-bottom: 20px; }
    .project-parallax-container .parallax-slider-item-text {
      color: #fff;
      margin-bottom: 20px; }
      @media (min-width: 992px) {
        .project-parallax-container .parallax-slider-item-text {
          margin-bottom: 40px; } }
  .project-parallax-container .parallax-slider-background {
    background-color: #fff; }
    @media (min-width: 768px) {
      .project-parallax-container .parallax-slider-background {
        height: 100%; }
        .project-parallax-container .parallax-slider-background .slick-list,
        .project-parallax-container .parallax-slider-background .slick-track {
          height: 100%; } }
    @media (min-width: 768px) {
      .project-parallax-container .parallax-slider-background-wrapper {
        height: 100%; } }
    .project-parallax-container .parallax-slider-background-item {
      height: 100%;
      background-size: cover;
      background-position: center; }
  .project-parallax-container .parallax-slider-controls {
    position: absolute;
    top: 0px;
    margin-top: 0; }
    @media (min-width: 768px) {
      .project-parallax-container .parallax-slider-controls {
        position: relative;
        top: auto;
        margin-top: -50px;
        padding-bottom: 70px; } }

.EditMode .board-container .parallax-slider-widget-wrapper,
.DesignMode .board-container .parallax-slider-widget-wrapper {
  margin-bottom: 0; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.company-slider-widget-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh; }
  @media (min-width: 992px) {
    .company-slider-widget-wrapper {
      height: 92vh; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 150dpi), only screen and (min-resolution: 1.5dppx) {
    .company-slider-widget-wrapper {
      min-height: 720px; } }
  @media (min-width: 1925px) {
    .company-slider-widget-wrapper {
      height: 980px; } }
  .company-slider-widget-wrapper .row {
    margin-left: 0px;
    margin-right: 0px; }
    .company-slider-widget-wrapper .row [class*="col-"] {
      padding-left: 0px;
      padding-right: 0px; }
  .company-slider-widget-wrapper:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    height: auto;
    background-color: rgba(255, 255, 255, 0.8);
    background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.9) 89%, #fff 99%);
    z-index: 1; }
    @media (min-width: 992px) {
      .company-slider-widget-wrapper:before {
        background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.9) 89%, #fff 99%); } }
    body.EditMode .company-slider-widget-wrapper:before,
    body.DesignMode .company-slider-widget-wrapper:before {
      display: none; }
  .company-slider-widget-wrapper:after {
    content: "";
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    height: 208px !important;
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1; }
    body.EditMode .company-slider-widget-wrapper:after,
    body.DesignMode .company-slider-widget-wrapper:after {
      display: none; }
    @media (min-width: 992px) {
      .company-slider-widget-wrapper:after {
        height: 299px; } }
    @media (min-width: 1200px) {
      .company-slider-widget-wrapper:after {
        height: auto; } }
    @media (min-width: 1600px) {
      .company-slider-widget-wrapper:after {
        height: 500px !important; } }
    body.EditMode .company-slider-widget-wrapper:after,
    body.DesignMode .company-slider-widget-wrapper:after {
      display: none; }

.company-slider-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .company-slider-background .slick-list,
  .company-slider-background .slick-track {
    height: 100%; }
  .company-slider-background .company-slider-background-item {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }

.company-slider-wrapper {
  padding: 80px 0 20px 0;
  position: relative;
  z-index: 2;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center; }
  @media (min-width: 992px) {
    .company-slider-wrapper {
      height: 92vh;
      padding: 110px 0 20px 0; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.25dppx) {
    .company-slider-wrapper {
      min-height: 720px; } }
  @media (min-width: 1925px) {
    .company-slider-wrapper {
      height: 980px; } }
  .company-slider-wrapper p {
    color: #000; }
  .company-slider-wrapper ul li {
    color: #000; }
  .company-slider-wrapper h1 {
    margin-bottom: 20px; }
    @media (min-width: 320px) {
      .company-slider-wrapper h1 {
        line-height: 9rem; } }
    @media (min-width: 480px) {
      .company-slider-wrapper h1 {
        line-height: 8.18182rem; } }
    @media (min-width: 545px) {
      .company-slider-wrapper h1 {
        line-height: 7.5rem; } }
    @media (min-width: 768px) {
      .company-slider-wrapper h1 {
        line-height: 7rem; } }
  @media (min-width: 320px) {
    .company-slider-wrapper .perex {
      margin-bottom: 4rem; } }

.company-slider-dots {
  padding: 30px 0px 20px 0px;
  overflow: hidden;
  display: none; }
  @media (min-width: 992px) {
    .company-slider-dots {
      padding: 35px 0px 40px 0px;
      display: block; } }
  .company-slider-dots .slick-dots {
    display: flex;
    margin: 0 -5px;
    flex-flow: row nowrap; }
    @media (min-width: 545px) {
      .company-slider-dots .slick-dots {
        margin: 0 -10px; } }
    .company-slider-dots .slick-dots li {
      padding: 0 5px; }
      @media (min-width: 545px) {
        .company-slider-dots .slick-dots li {
          padding: 0 10px; } }
      .company-slider-dots .slick-dots li:before {
        display: none; }
      .company-slider-dots .slick-dots li button {
        background: transparent;
        border: 2px solid #797979;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        font-size: 0;
        padding: 0;
        outline: none;
        cursor: pointer; }
      .company-slider-dots .slick-dots li.slick-active button {
        background: #ff5c00;
        border: 2px solid #ff5c00; }

.company-slider-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f2f2f2;
  text-align: center;
  cursor: pointer; }
  @media (min-width: 545px) {
    .company-slider-dot {
      width: 30px;
      height: 30px; } }
  @media (min-width: 992px) {
    .company-slider-dot {
      width: 80px;
      height: 80px;
      line-height: 80px; } }
  @media (min-width: 1400px) {
    .company-slider-dot {
      width: 100px;
      height: 100px;
      line-height: 100px; } }
  .company-slider-dot svg {
    vertical-align: middle;
    display: none; }
    @media (min-width: 992px) {
      .company-slider-dot svg {
        display: inline-block;
        width: 46px; } }
    @media (min-width: 1400px) {
      .company-slider-dot svg {
        width: 60px; } }
    .company-slider-dot svg .cls-1,
    .company-slider-dot svg .cls-2,
    .company-slider-dot svg .cls-3 {
      fill: #939396 !important; }
  .company-slider-dot:hover {
    background-color: rgba(255, 92, 0, 0.5); }
    .company-slider-dot:hover svg .cls-1,
    .company-slider-dot:hover svg .cls-2,
    .company-slider-dot:hover svg .cls-3 {
      fill: #fff !important; }
  .slick-active .company-slider-dot {
    background-color: #ff5c00; }
    .slick-active .company-slider-dot svg .cls-1,
    .slick-active .company-slider-dot svg .cls-2,
    .slick-active .company-slider-dot svg .cls-3 {
      fill: #fff !important; }

.company-slider-controls {
  font-size: 0px;
  margin-top: 30px; }
  @media (min-width: 992px) {
    .company-slider-controls {
      margin-top: 40px; } }

.company-slider-arrow {
  display: inline-block;
  background-color: transparent;
  color: #ff5c00;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 36px;
  border-radius: 00%;
  border: 2px solid #ff5c00;
  font-size: 24px;
  overflow: hidden; }
  .company-slider-arrow:before {
    display: inline-block; }
  .company-slider-arrow:hover {
    background-color: #ff5c00;
    color: #fff;
    text-decoration: none; }
  @media (min-width: 768px) {
    .company-slider-arrow {
      width: 50px;
      height: 50px;
      line-height: 46px; } }
  .company-slider-arrow.prev:before {
    content: "\e90a";
    transform: rotateZ(180deg); }
  .company-slider-arrow.next {
    margin-left: -2px; }
    .company-slider-arrow.next:before {
      content: "\e90a"; }

.company-item {
  user-select: text; }
  .company-item-logo {
    display: none; }
  .company-item-text {
    margin-bottom: 15px;
    color: #000; }
    @media (min-width: 320px) {
      .company-item-text {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .company-item-text {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .company-item-text {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .company-item-text {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .company-item-text {
        font-size: 2rem; } }
    @media (min-width: 320px) {
      .company-item-text {
        line-height: 4.8rem; } }
    @media (min-width: 480px) {
      .company-item-text {
        line-height: 4.36364rem; } }
    @media (min-width: 545px) {
      .company-item-text {
        line-height: 4rem; } }
    @media (min-width: 768px) {
      .company-item-text {
        line-height: 3.2rem; } }
    @media (min-width: 992px) {
      .company-item-text {
        line-height: 3rem; } }
    .company-item-text p {
      color: #000; }
    .company-item-text ul li {
      color: #000; }
    @media (min-width: 992px) {
      .company-item-text {
        margin-bottom: 30px; } }

.company-item-title {
  font-weight: 700;
  color: #ff5c00;
  text-transform: uppercase; }
  @media (min-width: 320px) {
    .company-item-title {
      font-size: 4.4rem; } }
  @media (min-width: 480px) {
    .company-item-title {
      font-size: 4rem; } }
  @media (min-width: 545px) {
    .company-item-title {
      font-size: 3.66667rem; } }
  @media (min-width: 768px) {
    .company-item-title {
      font-size: 2.93333rem; } }
  @media (min-width: 992px) {
    .company-item-title {
      font-size: 2.75rem; } }
  @media (min-width: 1200px) {
    .company-item-title {
      font-size: 2.44444rem; } }
  @media (min-width: 1400px) {
    .company-item-title {
      font-size: 2.4rem; } }

.dark-slider-text .main-slider-inner-content-title {
  color: #fff; }

.dark-slider-text p {
  color: #fff; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@media (min-width: 320px) {
  .testimonial-wrapper {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .testimonial-wrapper {
    padding-bottom: 6rem; } }

@media (min-width: 480px) {
  .testimonial-wrapper {
    padding-bottom: 5.5rem; } }

.testimonial-wrapper h2 {
  text-align: center; }
  @media (min-width: 320px) {
    .testimonial-wrapper h2 {
      margin-bottom: 6rem; } }

.testimonial-item {
  position: relative;
  padding: 15px 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start; }
  @media (min-width: 768px) {
    .testimonial-item {
      flex-flow: row nowrap; } }
  @media (min-width: 992px) {
    .testimonial-item {
      padding: 35px 50px; } }
  .testimonial-item-photo {
    flex: 1 0 200px;
    max-width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f2f2f2;
    margin: 0 0 20px 0; }
    @media (min-width: 768px) {
      .testimonial-item-photo {
        margin: 0; } }
  .testimonial-item-content {
    flex: 0 1 100%;
    font-size: 16px;
    line-height: 25px; }
    @media (min-width: 768px) {
      .testimonial-item-content {
        padding: 0 0 0 20px; } }
    @media (min-width: 992px) {
      .testimonial-item-content {
        padding: 0 0 0 50px; } }
    .testimonial-item-content strong {
      display: block;
      margin: 0 0 20px 0;
      font-size: 24px; }
  .testimonial-item-rate {
    padding-top: 20px;
    line-height: 20px; }
    .testimonial-item-rate span {
      display: inline-block;
      margin-right: 4px;
      font-size: 20px;
      line-height: 20px; }
      .testimonial-item-rate span:before {
        content: "\e90b";
        color: #939396; }
      .testimonial-item-rate span:nth-of-type(1):before {
        -moz-transition: color 50ms linear 0ms;
        -o-transition: color 50ms linear 0ms;
        -webkit-transition: color 50ms linear 0ms;
        transition: color 50ms linear 0ms; }
      .testimonial-item-rate span:nth-of-type(2):before {
        -moz-transition: color 50ms linear 300ms;
        -o-transition: color 50ms linear 300ms;
        -webkit-transition: color 50ms linear 300ms;
        transition: color 50ms linear 300ms; }
      .testimonial-item-rate span:nth-of-type(3):before {
        -moz-transition: color 50ms linear 600ms;
        -o-transition: color 50ms linear 600ms;
        -webkit-transition: color 50ms linear 600ms;
        transition: color 50ms linear 600ms; }
      .testimonial-item-rate span:nth-of-type(4):before {
        -moz-transition: color 50ms linear 900ms;
        -o-transition: color 50ms linear 900ms;
        -webkit-transition: color 50ms linear 900ms;
        transition: color 50ms linear 900ms; }
      .testimonial-item-rate span:nth-of-type(5):before {
        -moz-transition: color 50ms linear 1200ms;
        -o-transition: color 50ms linear 1200ms;
        -webkit-transition: color 50ms linear 1200ms;
        transition: color 50ms linear 1200ms; }
    .testimonial-item-rate.activate.count-1 span:nth-of-type(1):before {
      color: #ff5c00; }
    .testimonial-item-rate.activate.count-2 span:nth-of-type(1):before, .testimonial-item-rate.activate.count-2 span:nth-of-type(2):before {
      color: #ff5c00; }
    .testimonial-item-rate.activate.count-3 span:nth-of-type(1):before, .testimonial-item-rate.activate.count-3 span:nth-of-type(2):before, .testimonial-item-rate.activate.count-3 span:nth-of-type(3):before {
      color: #ff5c00; }
    .testimonial-item-rate.activate.count-4 span:nth-of-type(1):before, .testimonial-item-rate.activate.count-4 span:nth-of-type(2):before, .testimonial-item-rate.activate.count-4 span:nth-of-type(3):before, .testimonial-item-rate.activate.count-4 span:nth-of-type(4):before {
      color: #ff5c00; }
    .testimonial-item-rate.activate.count-5 span:before {
      color: #ff5c00; }
  .testimonial-item.no-rate .testimonial-item-info {
    padding-bottom: 43px; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

#brokers-map {
  width: 100%;
  padding: 80% 0 0 0;
  margin: 0 -15px 20px -15px; }
  @media (min-width: 768px) {
    #brokers-map {
      padding: 60% 0 0 0; } }
  @media (min-width: 1500px) {
    #brokers-map {
      padding: 55% 0 0 0; } }
  #brokers-map.loading {
    position: relative; }
    #brokers-map.loading:before {
      content: '';
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: white; }
    #brokers-map.loading:after {
      content: '';
      z-index: 3;
      border: 10px solid #e4e4e4;
      /* Light grey */
      border-top: 10px solid #ff5c00;
      /* Blue */
      border-radius: 50%;
      width: 60px;
      height: 60px;
      animation: loader 2s linear infinite;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: -30px; }
  #brokers-map .zfp-leaflet-tooltip {
    font-family: "Fedra", sans-serif;
    font-size: 0;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    opacity: 1 !important; }
    @media (min-width: 768px) {
      #brokers-map .zfp-leaflet-tooltip {
        font-size: 12px; } }
    @media (min-width: 1400px) {
      #brokers-map .zfp-leaflet-tooltip {
        font-size: 16px; } }
    #brokers-map .zfp-leaflet-tooltip.active {
      color: #fff; }
    #brokers-map .zfp-leaflet-tooltip.ST {
      margin: 20px 0 0 0; }
      @media (min-width: 992px) {
        #brokers-map .zfp-leaflet-tooltip.ST {
          margin: 40px 0 0 0; } }
    #brokers-map .zfp-leaflet-tooltip.OL {
      margin: 20px 0 0 0; }
      @media (min-width: 992px) {
        #brokers-map .zfp-leaflet-tooltip.OL {
          margin: 20px 0 0 6px; } }
      @media (min-width: 1500px) {
        #brokers-map .zfp-leaflet-tooltip.OL {
          margin: 40px 0 0 0; } }
    #brokers-map .zfp-leaflet-tooltip.JM {
      margin: 10px 0 0 5px; }
      @media (min-width: 1500px) {
        #brokers-map .zfp-leaflet-tooltip.JM {
          margin: 0; } }
    #brokers-map .zfp-leaflet-tooltip.KR {
      margin: 0 0 0 -17px; }
    #brokers-map .zfp-leaflet-tooltip.MO {
      margin: 0px 0 0 6px; }
      @media (min-width: 1500px) {
        #brokers-map .zfp-leaflet-tooltip.MO {
          margin: 0; } }
  #brokers-map .leaflet-control-attribution {
    display: none; }

.broker-county-container .selectric-wrapper {
  width: 100%;
  max-width: 360px;
  margin: 0 auto; }

.broker-county-container .selectric {
  border-color: #ff5c00; }

.broker-items {
  margin: 0 -15px 30px -15px; }
  @media (min-width: 992px) {
    .broker-items {
      margin: 0 -15px 60px -15px; } }
  .broker-items .items-container {
    display: flex;
    flex-flow: row wrap;
    min-height: 250px; }
    .broker-items .items-container.loading {
      position: relative; }
      .broker-items .items-container.loading:before {
        content: '';
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.9); }
      .broker-items .items-container.loading:after {
        content: '';
        z-index: 3;
        border: 10px solid #e4e4e4;
        /* Light grey */
        border-top: 10px solid #ff5c00;
        /* Blue */
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: loader 2s linear infinite;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -30px; }
    .broker-items .items-container [id*="pnlNoData"] {
      flex: 1 0 100%;
      width: 100%; }
    .broker-items .items-container .pux-infinite-repeater-no-data-title {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 16px;
      padding: 0 15px; }
      @media (min-width: 992px) {
        .broker-items .items-container .pux-infinite-repeater-no-data-title {
          font-size: 24px; } }
      .broker-items .items-container .pux-infinite-repeater-no-data-title:before {
        content: "\e92c";
        font-size: 40px;
        margin: 0 10px 0 0;
        color: #ff5c00; }
  .broker-items .show-more-container {
    text-align: center; }
    @media (min-width: 320px) {
      .broker-items .show-more-container {
        padding-top: 4rem; } }
    @media (min-width: 480px) {
      .broker-items .show-more-container {
        padding-top: 3.63636rem; } }
    @media (min-width: 545px) {
      .broker-items .show-more-container {
        padding-top: 3.33333rem; } }
    @media (min-width: 768px) {
      .broker-items .show-more-container {
        padding-top: 3rem; } }
    @media (min-width: 320px) {
      .broker-items .show-more-container {
        padding-bottom: 4rem; } }
    @media (min-width: 480px) {
      .broker-items .show-more-container {
        padding-bottom: 3.63636rem; } }
    @media (min-width: 545px) {
      .broker-items .show-more-container {
        padding-bottom: 3.33333rem; } }
    @media (min-width: 768px) {
      .broker-items .show-more-container {
        padding-bottom: 3rem; } }
    .broker-items .show-more-container .arrow-down-link {
      display: inline-block;
      background-color: transparent;
      color: #ff5c00;
      text-align: center;
      border-radius: 0;
      overflow: hidden;
      border: 2px solid #ff5c00;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      padding: 11px 25px 11px 25px;
      text-transform: uppercase;
      position: relative;
      -moz-transition: all ease-in-out 100ms;
      -o-transition: all ease-in-out 100ms;
      -webkit-transition: all ease-in-out 100ms;
      transition: all ease-in-out 100ms; }
      @media (min-width: 768px) {
        .broker-items .show-more-container .arrow-down-link {
          font-size: 20px;
          line-height: 24px;
          padding: 13px 34px 13px 34px; } }
      .broker-items .show-more-container .arrow-down-link:hover {
        text-decoration: none;
        background-color: #ff5c00;
        color: #fff; }
  .broker-items .broker-item {
    margin: 0 0 30px 0;
    padding: 0 15px;
    width: 100%;
    flex: 1 0 100%; }
    @media (min-width: 768px) {
      .broker-items .broker-item {
        flex: 1 0 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .broker-items .broker-item {
        flex: 1 0 33.33333%;
        max-width: 33.33333%; } }
    .broker-items .broker-item-inner {
      padding: 25px 25px 25px 25px;
      background-color: #f2f2f2;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      height: 100%; }
      @media (min-width: 768px) {
        .broker-items .broker-item-inner {
          padding: 35px 35px 25px 25px; } }
    .broker-items .broker-item-left {
      padding: 0 15px 0 0; }
      @media (min-width: 320px) {
        .broker-items .broker-item-left {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .broker-items .broker-item-left {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .broker-items .broker-item-left {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .broker-items .broker-item-left {
          font-size: 2.4rem; } }
      @media (min-width: 320px) {
        .broker-items .broker-item-left {
          line-height: 5rem; } }
      @media (min-width: 480px) {
        .broker-items .broker-item-left {
          line-height: 4.54545rem; } }
      @media (min-width: 545px) {
        .broker-items .broker-item-left {
          line-height: 4.16667rem; } }
      @media (min-width: 768px) {
        .broker-items .broker-item-left {
          line-height: 3.6rem; } }
    .broker-items .broker-item-name {
      font-weight: 700;
      color: #323337; }
      .broker-items .broker-item-name:hover {
        text-decoration: none;
        color: #ff5c00; }
    .broker-items .broker-item-func {
      font-style: italic; }
    .broker-items .broker-item-city {
      margin: 0 0 10px 0; }
    .broker-items .broker-item-link {
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 28px;
      text-align: center;
      border: 2px solid #ff5c00; }
      .broker-items .broker-item-link:before {
        content: "\e908";
        font-size: 12px;
        display: inline-block;
        vertical-align: 4px; }
        .Safari .broker-items .broker-item-link:before {
          vertical-align: 2px; }
      .broker-items .broker-item-link:hover, .broker-items .broker-item-link:focus {
        text-decoration: none;
        background-color: #ff5c00;
        color: #fff; }
    .broker-items .broker-item-right {
      padding: 10px 0 0 15px;
      width: 100%;
      max-width: 100px; }
      .broker-items .broker-item-right img {
        width: 100px;
        border-radius: 50%; }
        .broker-items .broker-item-right img.broker-item-default-image {
          border-radius: 0; }

.broker-page .top-image .main-slider-inner-content {
  max-width: 700px; }

@media (max-width: 767px) {
  .broker-page .top-image .main-slider-inner {
    padding: 100px 0 40px 0; } }

@media (max-width: 767px) {
  .broker-page .top-image .main-slider-image {
    position: static; } }

@media (max-width: 767px) {
  .broker-page .top-image .main-slider-image:before {
    display: none; } }

@media (max-width: 767px) {
  .broker-page .top-image .main-slider-image img {
    max-width: 100%; } }

.broker-page .wrapper-black .top-image .main-slider-image:before {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.2) 0%, #000 99%); }

@media (max-width: 767px) {
  .broker-page .content-overflow {
    margin-top: 40px; } }

@media (min-width: 992px) {
  .broker-page .infobox-item-col {
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 992px) {
  .broker-page .infobox-item-icon {
    margin: 0 0 30px 0; } }

.broker-detail-item {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 0 20px 0; }
  @media (min-width: 992px) {
    .broker-detail-item {
      margin: 0 0 30px 0; } }
  .broker-detail-item > [class^="zfp-ico-"],
  .broker-detail-item > [class*=" zfp-ico-"] {
    color: #ff5c00;
    font-size: 36px;
    display: block;
    margin: 0 25px 0 0; }
    @media (min-width: 992px) {
      .broker-detail-item > [class^="zfp-ico-"],
      .broker-detail-item > [class*=" zfp-ico-"] {
        margin: 0 40px 0 0; } }
  .broker-detail-item .broker-detail-item-link {
    color: #fff; }
  .broker-detail-item a:hover,
  .broker-detail-item a:focus {
    color: #ff5c00; }

.broker-rating-item {
  font-size: 16px;
  line-height: 25px;
  padding: 0 20px; }
  @media (min-width: 992px) {
    .broker-rating-item {
      padding: 0 50px; } }
  .broker-rating-item-name {
    margin: 0 0 20px 0;
    font-size: 24px; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.top-image .main-slider {
  padding-bottom: 0px;
  flex-wrap: wrap; }
  @media (min-width: 992px) {
    .top-image .main-slider {
      min-height: 100vh;
      flex-wrap: nowrap;
      padding-top: 10rem; } }
  @media (min-width: 1925px) {
    .top-image .main-slider {
      min-height: 980px; } }
  .top-image .main-slider-inner {
    padding-bottom: 70px;
    width: 100%;
    flex: 0 1 100%;
    order: 1;
    position: relative;
    z-index: 1;
    padding: 100px 0 140px 0; }
    @media (min-width: 992px) {
      .top-image .main-slider-inner {
        min-height: 100vh;
        padding: 0; } }
    @media (min-width: 1925px) {
      .top-image .main-slider-inner {
        min-height: 980px; } }
    .top-image .main-slider-inner-content {
      margin: 0; }
      @media (min-width: 992px) {
        .top-image .main-slider-inner-content {
          max-width: 540px;
          margin: -200px 0 0 0; } }
      .top-image .main-slider-inner-content-title {
        font-weight: 700;
        color: #000;
        text-transform: uppercase; }
        @media (min-width: 320px) {
          .top-image .main-slider-inner-content-title {
            font-size: 6rem; } }
        @media (min-width: 320px) {
          .top-image .main-slider-inner-content-title {
            line-height: 6.6rem; } }
        @media (min-width: 320px) {
          .top-image .main-slider-inner-content-title {
            margin-bottom: 5rem; } }
        .top-image .main-slider-inner-content-title.normal-font {
          text-transform: none; }
        .wrapper-black .top-image .main-slider-inner-content-title {
          color: #fff; }
      .top-image .main-slider-inner-content-text {
        color: #000;
        color: #000; }
        @media (min-width: 320px) {
          .top-image .main-slider-inner-content-text {
            font-size: 3.2rem; } }
        @media (min-width: 480px) {
          .top-image .main-slider-inner-content-text {
            font-size: 2.90909rem; } }
        @media (min-width: 545px) {
          .top-image .main-slider-inner-content-text {
            font-size: 2.66667rem; } }
        @media (min-width: 768px) {
          .top-image .main-slider-inner-content-text {
            font-size: 2.13333rem; } }
        @media (min-width: 992px) {
          .top-image .main-slider-inner-content-text {
            font-size: 2rem; } }
        @media (min-width: 320px) {
          .top-image .main-slider-inner-content-text {
            line-height: 4.8rem; } }
        @media (min-width: 480px) {
          .top-image .main-slider-inner-content-text {
            line-height: 4.36364rem; } }
        @media (min-width: 545px) {
          .top-image .main-slider-inner-content-text {
            line-height: 4rem; } }
        @media (min-width: 768px) {
          .top-image .main-slider-inner-content-text {
            line-height: 3.2rem; } }
        @media (min-width: 992px) {
          .top-image .main-slider-inner-content-text {
            line-height: 3rem; } }
        @media (min-width: 320px) {
          .top-image .main-slider-inner-content-text {
            margin-bottom: 4rem; } }
        .top-image .main-slider-inner-content-text p {
          color: #000; }
        .top-image .main-slider-inner-content-text ul li {
          color: #000; }
        .wrapper-black .top-image .main-slider-inner-content-text {
          color: #fff; }
          .wrapper-black .top-image .main-slider-inner-content-text p {
            color: #fff; }
          .wrapper-black .top-image .main-slider-inner-content-text ul li {
            color: #fff; }
        @media (max-width: 991px) {
          .top-image .main-slider-inner-content-text br {
            display: none; } }
  .top-image .main-slider-image {
    width: 100% !important;
    flex: 0 1 100%;
    order: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden; }
    @media (min-width: 992px) {
      .top-image .main-slider-image {
        flex: none;
        width: calc(100% - 500px); } }
    @media (min-width: 1400px) {
      .top-image .main-slider-image {
        width: auto; } }
    .top-image .main-slider-image:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: hidden;
      z-index: 1;
      background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, #fff 99%); }
      @media (min-width: 992px) {
        .top-image .main-slider-image:before {
          background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, #fff 99%); } }
      .wrapper-black .top-image .main-slider-image:before {
        background: radial-gradient(circle, rgba(0, 0, 0, 0.7) 0%, #000 99%); }
        @media (min-width: 992px) {
          .wrapper-black .top-image .main-slider-image:before {
            background: radial-gradient(circle, rgba(0, 0, 0, 0.4) 0%, #000 99%); } }
    .top-image .main-slider-image-wrapper {
      height: 100%; }
    .top-image .main-slider-image img {
      min-width: 100%;
      max-width: none;
      min-height: 100% !important;
      position: relative;
      z-index: 0;
      left: 50%;
      transform: translateX(-50%); }
  .top-image .main-slider-scroll {
    display: none; }
    .top-image .main-slider-scroll:before {
      content: "\e942";
      transform: rotateZ(90deg);
      margin-left: 0;
      display: block;
      width: 42px;
      height: 42px;
      line-height: 38px;
      text-align: center;
      color: #fff;
      border: 2px solid #ff5c00;
      font-size: 14px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .top-image .main-slider-scroll:hover {
      text-decoration: none; }
      .top-image .main-slider-scroll:hover:before {
        background-color: #ff5c00; }
  .top-image .main-slider-video .main-slider-inner-content-btn {
    padding: 20px 0 40px 0; }
    @media (min-width: 992px) {
      .top-image .main-slider-video .main-slider-inner-content-btn {
        padding: 0;
        position: absolute;
        top: 50%;
        left: 70%;
        transform: translate(-50%, -120px); } }
    @media (min-width: 1400px) {
      .top-image .main-slider-video .main-slider-inner-content-btn {
        top: 50%;
        left: 50%; } }
    .top-image .main-slider-video .main-slider-inner-content-btn a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background-color: transparent;
      border: 2px solid #ff5c00;
      margin: 0 auto;
      padding: 0; }
      .top-image .main-slider-video .main-slider-inner-content-btn a:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 13px 0 13px 22px;
        border-color: transparent transparent transparent #fff;
        margin: 0 0 0 7px;
        transition: all ease-in-out 300ms; }
      .top-image .main-slider-video .main-slider-inner-content-btn a:hover {
        background-color: #ff5c00; }
        .top-image .main-slider-video .main-slider-inner-content-btn a:hover:before {
          transform: scale(1.2); }

.top-image.top-image-text-right .main-slider-inner-content {
  margin: 0 0 0 auto;
  text-align: right; }
  @media (min-width: 992px) {
    .top-image.top-image-text-right .main-slider-inner-content {
      margin: -10rem 0 0 auto; } }

.top-image.top-image-people:before {
  content: "";
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  height: 208px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1; }
  @media (min-width: 992px) {
    .top-image.top-image-people:before {
      height: 300px; } }
  body.EditMode .top-image.top-image-people:before,
  body.DesignMode .top-image.top-image-people:before {
    display: none; }

.top-image.top-image-people .main-slider-inner {
  min-height: 0; }

.top-image.top-image-people .main-slider-image:before {
  display: none; }

.top-image.top-image-people .main-slider-image:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); }
  @media (min-width: 992px) {
    .top-image.top-image-people .main-slider-image:after {
      background-color: rgba(255, 255, 255, 0.4); } }

@media (min-width: 768px) {
  .top-image.top-image-people .main-slider-image img {
    margin-top: 120px;
    min-width: 0;
    min-height: 0 !important;
    max-width: 65% !important;
    transform: none;
    left: auto; } }

.top-image.top-image-strategy .main-slider {
  min-height: 500px; }
  @media (min-width: 1400px) {
    .top-image.top-image-strategy .main-slider {
      min-height: 670px; } }
  .top-image.top-image-strategy .main-slider-inner {
    min-height: 0; }

.top-image.top-image-strategy .main-slider-inner-content {
  margin: 0; }

.top-image.contact-top-image .main-slider {
  min-height: 420px; }
  @media (min-width: 992px) {
    .top-image.contact-top-image .main-slider {
      min-height: 680px; } }
  .top-image.contact-top-image .main-slider-inner {
    min-height: 0; }

.top-image.contact-top-image .main-slider-image img {
  height: auto !important; }

.top-image.contact-top-image .main-slider-inner .pux-container {
  width: 100%;
  height: 100%; }

@media (min-width: 992px) {
  .top-image.contact-top-image .main-slider-inner-content {
    margin: 120px 0 0 0; } }

.top-image.contact-top-image .main-slider-inner-content-text {
  margin: 0; }

.top-image.top-image-downloads .main-slider {
  align-items: flex-start; }

.top-image.top-image-downloads .main-slider-inner {
  justify-content: flex-start;
  min-height: 0; }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 125dpi), only screen and (min-resolution: 1.25dppx) {
    .top-image.top-image-downloads .main-slider-inner {
      min-height: 720px; } }

.top-image.top-image-downloads .main-slider-inner-content {
  max-width: 85%; }
  @media (min-width: 768px) {
    .top-image.top-image-downloads .main-slider-inner-content {
      max-width: 50%; } }

@media (min-width: 992px) and (min-width: 320px) {
  .top-image.top-image-downloads .main-slider-inner-content {
    margin-top: 12rem; } }

.top-image.top-image-career .main-slider-image {
  display: none; }
  @media (min-width: 768px) {
    .top-image.top-image-career .main-slider-image {
      display: block; } }

.top-image.top-image-career .main-slider-scroll {
  display: inline-block; }

.content-overflow {
  margin-top: 0;
  z-index: 2;
  position: relative;
  background-color: transparent;
  padding-top: 20px;
  margin-top: -100px; }
  @media (min-width: 992px) {
    .content-overflow {
      margin-top: -200px;
      padding-top: 40px; } }
  @media (min-width: 1400px) {
    .content-overflow {
      margin-top: -270px;
      padding-top: 60px; } }
  body.EditMode .content-overflow,
  body.DesignMode .content-overflow {
    margin-top: 0px; }
  .content-overflow-container {
    position: relative;
    z-index: 2; }
  .content-overflow:before {
    content: "";
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    height: 208px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 100px; }
    @media (min-width: 992px) {
      .content-overflow:before {
        height: 200px; } }
    @media (min-width: 1400px) {
      .content-overflow:before {
        height: 270px; } }
    body.EditMode .content-overflow:before,
    body.DesignMode .content-overflow:before {
      display: none; }
  .wrapper-black .content-overflow:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%); }
  .content-overflow.content-overflow-downloads {
    margin-top: -70rem; }
    @media (min-width: 768px) {
      .content-overflow.content-overflow-downloads {
        margin-top: -55rem; } }
    @media (max-height: 900px) {
      .content-overflow.content-overflow-downloads {
        margin-top: -45rem; } }
    @media (max-height: 800px) {
      .content-overflow.content-overflow-downloads {
        margin-top: -40rem; } }
    .content-overflow.content-overflow-downloads:before {
      height: 55rem; }

.content-overflow-bottom {
  position: relative; }
  @media (min-width: 1400px) {
    .content-overflow-bottom:after {
      content: "";
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, #fff 100%);
      height: 560px;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1; }
      .wrapper-black .content-overflow-bottom:after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 80%, #000 100%); } }
  body.EditMode .content-overflow-bottom:after,
  body.DesignMode .content-overflow-bottom:after {
    display: none; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.person-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: transparent; }
  .person-container .person-item {
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px 0; }
    @media (min-width: 545px) {
      .person-container .person-item {
        width: 50%; } }
    @media (min-width: 768px) {
      .person-container .person-item {
        margin: 0 0 30px 0; } }
    @media (min-width: 1200px) {
      .person-container .person-item {
        width: 25%; } }
  .person-container .person-item-teaser {
    height: 250px;
    width: 250px;
    padding: 20px;
    margin: 0 auto;
    position: relative; }
    .person-container .person-item-teaser span {
      height: 100%;
      width: 100%;
      display: block;
      overflow: hidden;
      border-radius: 50%;
      position: relative; }
      .person-container .person-item-teaser span img {
        position: relative;
        top: 50%;
        transform: translateY(-50%); }
  .person-container .person-item-info {
    background-color: transparent;
    text-align: center;
    padding: 0; }
  .person-container .person-item-info-name {
    color: #ff5c00;
    min-height: auto;
    margin-bottom: 0;
    font-weight: 700; }
    @media (min-width: 320px) {
      .person-container .person-item-info-name {
        font-size: 4rem; } }
    @media (min-width: 480px) {
      .person-container .person-item-info-name {
        font-size: 3.63636rem; } }
    @media (min-width: 545px) {
      .person-container .person-item-info-name {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .person-container .person-item-info-name {
        font-size: 2.66667rem; } }
    @media (min-width: 992px) {
      .person-container .person-item-info-name {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .person-container .person-item-info-name {
        font-size: 2.22222rem; } }
    @media (min-width: 1400px) {
      .person-container .person-item-info-name {
        font-size: 2.2rem; } }

.person-container.person-container-spread .cta-content-text-inner {
  color: #000; }
  @media (min-width: 992px) {
    .person-container.person-container-spread .cta-content-text-inner h2 {
      margin-top: -17rem; } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.business-contact-frame {
  position: relative;
  padding: 0 20px;
  padding-bottom: 140px;
  overflow: hidden; }
  .business-contact-frame:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, #fff 99%); }
    .wrapper-black .business-contact-frame:before {
      background: radial-gradient(circle, rgba(0, 0, 0, 0.4) 0%, #000 99%); }
    body.EditMode .business-contact-frame:before,
    body.DesignMode .business-contact-frame:before {
      display: none; }
  @media (min-width: 992px) {
    .business-contact-frame {
      padding-bottom: 300px; } }
  @media (min-width: 1400px) {
    .business-contact-frame {
      padding-bottom: 420px; } }
  .business-contact-frame > img {
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; }
  .business-contact-frame-inner {
    position: relative;
    z-index: 3; }
  .business-contact-frame-title {
    text-align: center; }
  .business-contact-frame-text {
    border: 2px solid #fff;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    padding: 20px;
    text-align: center; }
    @media (min-width: 768px) {
      .business-contact-frame-text {
        padding: 40px 40px 50px 40px; } }
    .business-contact-frame-text:before {
      display: inline-block;
      content: "\e940";
      font-size: 75px;
      color: #ff5c00;
      margin: 0 0 20px 0; }
      @media (min-width: 768px) {
        .business-contact-frame-text:before {
          margin: 0 0 40px 0; } }
    .business-contact-frame-text h3 {
      font-weight: 500;
      margin: 0 0 20px 0; }
    .business-contact-frame-text p {
      margin: 0 0 20px 0; }
      @media (min-width: 768px) {
        .business-contact-frame-text p {
          margin: 0 0 40px 0; } }

.business-videos-container {
  position: relative;
  padding: 0 0 20px 0; }
  @media (min-width: 992px) {
    .business-videos-container {
      padding: 0 140px 60px 140px; } }
  @media (min-width: 1400px) {
    .business-videos-container {
      padding: 0 140px 120px 140px; } }

.business-videos:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc((100% - 548px) / 2);
  background-image: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 3; }
  @media (min-width: 1200px) {
    .business-videos:before {
      width: calc((100% - 800px) / 2); } }

.business-videos:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: calc((100% - 548px) / 2);
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, black 100%);
  z-index: 3; }
  @media (min-width: 1200px) {
    .business-videos:after {
      width: calc((100% - 800px) / 2); } }

.business-videos .business-video {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 100%; }
  @media (min-width: 768px) {
    .business-videos .business-video {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0%, -50%);
      height: 420px;
      width: 548px;
      transition: all 300ms ease-in-out; } }
  @media (min-width: 1200px) {
    .business-videos .business-video {
      height: 380px;
      width: 680px; } }
  .business-videos .business-video-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0.55) 100%);
    background-size: calc(100% + 200px) 100%;
    background-position: -200px 50%;
    background-repeat: no-repeat;
    transition: background 300ms ease-in-out, padding 300ms ease-in-out, opacity 0ms ease-in-out;
    padding: 10px 20px; }
    @media (min-width: 768px) {
      .business-videos .business-video-text {
        opacity: 0; } }
    @media (min-width: 1200px) {
      .business-videos .business-video-text {
        padding: 20px 35px 15px 40px; } }
  .business-videos .business-video-placeholder {
    height: 280px;
    z-index: 1; }
    @media (min-width: 768px) {
      .business-videos .business-video-placeholder {
        height: 455px; } }
    .business-videos .business-video-placeholder.swiper-slide-prev {
      z-index: 2; }
      @media (min-width: 1200px) {
        .business-videos .business-video-placeholder.swiper-slide-prev .business-video {
          height: 420px;
          left: 25%;
          width: 755px;
          transform: translate(-27%, -50%); } }
    .business-videos .business-video-placeholder.swiper-slide-active {
      z-index: 3; }
      @media (min-width: 768px) {
        .business-videos .business-video-placeholder.swiper-slide-active .business-video {
          left: 50%;
          height: 445px;
          width: 580px;
          transform: translate(-50%, -50%); } }
      @media (min-width: 1200px) {
        .business-videos .business-video-placeholder.swiper-slide-active .business-video {
          height: 445px;
          width: 800px; } }
      @media (min-width: 768px) {
        .business-videos .business-video-placeholder.swiper-slide-active .business-video-text {
          opacity: 1;
          transition: background 300ms ease-in-out, padding 300ms ease-in-out, opacity 300ms ease-in-out 150ms; } }
    .business-videos .business-video-placeholder.swiper-slide-next {
      z-index: 2; }
      @media (min-width: 1200px) {
        .business-videos .business-video-placeholder.swiper-slide-next .business-video {
          left: 25%;
          height: 420px;
          width: 755px;
          transform: translate(-60%, -50%); } }
      @media (min-width: 1500px) {
        .business-videos .business-video-placeholder.swiper-slide-next .business-video {
          transform: translate(-58%, -50%); } }
    @media (min-width: 1200px) {
      .business-videos .business-video-placeholder.swiper-slide-next + .business-video-placeholder .business-video {
        transform: translate(-66%, -50%); } }
  .business-videos .business-video-title {
    font-size: 24px;
    line-height: 28px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 0 5px 0; }
    @media (min-width: 768px) {
      .business-videos .business-video-title {
        font-size: 32px;
        line-height: 36px; } }
    .business-videos .business-video-title:before {
      display: block;
      content: "\e901";
      color: #ff5c00;
      font-size: 32px;
      margin: 0 10px 0 -7px; }
      @media (min-width: 768px) {
        .business-videos .business-video-title:before {
          font-size: 40px; } }
  .business-videos .business-video:hover .business-video-text {
    background-position: 0px 50%;
    background-size: 200% 100%; }
    @media (min-width: 992px) {
      .business-videos .business-video:hover .business-video-text {
        padding: 25px 35px 25px 45px; } }

.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  border-radius: 50%;
  font-size: 0;
  background-color: #323337;
  transition: all 200ms ease-in-out; }
  @media (min-width: 768px) {
    .swiper-button-prev,
    .swiper-button-next {
      width: 50px;
      height: 50px;
      margin-top: -25px; } }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: "\e91a";
    font-size: 16px;
    color: #fff; }
  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    background-color: #4a4c52; }

.swiper-button-next:after {
  content: "\e91b"; }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.test-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .test-social ul li {
    padding: 0 15px 15px 0; }
    .test-social ul li:before {
      display: none; }
    .test-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 30px;
      height: 30px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: grey;
      border: none;
      color: #fff;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .test-social ul li a:focus, .test-social ul li a:hover {
        background: blue;
        color: black;
        border: none;
        text-decoration: none; }

.test-social .facebook:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .twitter:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .linkedin:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .youtube:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .instagram:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .pinterest:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .google:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.test-social .vimeo:before {
  content: "";
  line-height: 30px;
  font-size: 20px; }

.product-social ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none; }
  .product-social ul li {
    padding: 0 15px 15px 0; }
    .product-social ul li:before {
      display: none; }
    .product-social ul li a {
      font-size: 0;
      display: block;
      text-decoration: none;
      width: 40px;
      height: 40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      background: none;
      border: 1px solid grey;
      color: grey;
      text-align: center;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .product-social ul li a:focus, .product-social ul li a:hover {
        background: none;
        color: black;
        border: 1px solid black;
        text-decoration: none; }

.product-social .facebook:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .twitter:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .linkedin:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .youtube:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .instagram:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .pinterest:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .google:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.product-social .vimeo:before {
  content: "";
  line-height: 40px;
  font-size: 20px; }

.job-page .bottom-line {
  border-bottom: 0; }

.job-items {
  padding-bottom: 60px;
  position: relative; }
  .job-items .row {
    margin-left: -15px;
    margin-right: -15px; }
    .job-items .row [class*="col-"] {
      padding-left: 15px;
      padding-right: 15px; }
  .job-items [class*="col-"] {
    margin-bottom: 30px; }
  .job-items .btn-more {
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, 50%) rotateZ(45deg);
    -moz-transform: translate(-50%, 50%) rotateZ(45deg);
    -ms-transform: translate(-50%, 50%) rotateZ(45deg);
    -o-transform: translate(-50%, 50%) rotateZ(45deg);
    transform: translate(-50%, 50%) rotateZ(45deg);
    z-index: 2;
    -moz-transition: all ease-in-out 350ms;
    -o-transition: all ease-in-out 350ms;
    -webkit-transition: all ease-in-out 350ms;
    transition: all ease-in-out 350ms; }
    .job-items .btn-more.collapsed {
      -webkit-transform: translate(-50%, 50%) rotateZ(0deg);
      -moz-transform: translate(-50%, 50%) rotateZ(0deg);
      -ms-transform: translate(-50%, 50%) rotateZ(0deg);
      -o-transform: translate(-50%, 50%) rotateZ(0deg);
      transform: translate(-50%, 50%) rotateZ(0deg); }

.job-item {
  background-color: #171717;
  padding: 25px 25px 50px 25px;
  position: relative;
  min-height: 280px;
  height: 100%; }
  @media (min-width: 992px) {
    .job-item {
      padding: 50px 50px 70px 50px; } }
  .job-item-name {
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
    display: inline-block;
    position: relative;
    padding: 0 4px;
    padding-left: 0;
    color: #fff; }
    .job-item-name:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 100%;
      border-bottom: 2px solid transparent;
      -moz-transition: all ease-in-out 350ms;
      -o-transition: all ease-in-out 350ms;
      -webkit-transition: all ease-in-out 350ms;
      transition: all ease-in-out 350ms; }
    .job-item-name:hover {
      text-decoration: none; }
      .job-item-name:hover:after {
        right: 0;
        border-color: #ff5c00; }
    .job-item-name:hover {
      color: #ff5c00; }
  .job-item-description {
    margin-bottom: 15px;
    color: #fff; }
    .job-item-description big {
      color: #fff;
      font-size: 16px;
      line-height: 25px; }
  .job-item-link {
    position: absolute;
    bottom: 23px;
    left: 25px; }
    @media (min-width: 992px) {
      .job-item-link {
        bottom: 29px;
        left: 50px; } }
    .job-item-link:hover {
      color: #e4e4e4; }
      .job-item-link:hover:after {
        color: #e4e4e4; }

.job-detail-header {
  padding: 30px 0 0 0; }
  @media (min-width: 992px) {
    .job-detail-header {
      padding: 0; } }

.job-detail-cta {
  padding: 30px 0; }
  @media (max-width: 767px) {
    .job-detail-cta h3 {
      margin: 0 0 20px 0; } }
  @media (max-width: 767px) {
    .job-detail-cta .perex {
      margin: 0 0 40px 0; } }

.job-detail-socials ul {
  display: flex;
  justify-content: center; }
  .job-detail-socials ul li {
    margin: 0;
    padding: 0 20px; }
    @media (min-width: 545px) {
      .job-detail-socials ul li {
        padding: 0 25px; } }
    @media (min-width: 992px) {
      .job-detail-socials ul li {
        padding: 0 50px; } }
    .job-detail-socials ul li:before {
      display: none; }
    .job-detail-socials ul li [class^="zfp-ico-"],
    .job-detail-socials ul li [class*=" zfp-ico-"] {
      color: #fff; }
      .job-detail-socials ul li [class^="zfp-ico-"]:before,
      .job-detail-socials ul li [class*=" zfp-ico-"]:before {
        font-size: 48px; }
      .job-detail-socials ul li [class^="zfp-ico-"]:hover,
      .job-detail-socials ul li [class*=" zfp-ico-"]:hover {
        color: #ff5c00;
        text-decoration: none; }
    .job-detail-socials ul li .zfp-ico-job-icon-fb:hover {
      color: #3b5998; }
    .job-detail-socials ul li .zfp-ico-job-icon-in:hover {
      color: #0d77b7; }
    .job-detail-socials ul li .zfp-ico-job-icon-ytb:hover {
      color: #cc181e; }
    .job-detail-socials ul li .zfp-ico-job-icon-insta:hover {
      color: #8a3ab9; }

/* contact */
.contact-column-text {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin: 0 0 20px 0; }
  @media (min-width: 320px) {
    .contact-column-text {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    .contact-column-text {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    .contact-column-text {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .contact-column-text {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    .contact-column-text {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .contact-column-text {
      font-size: 2.2rem; } }
  @media (min-width: 320px) {
    .contact-column-text {
      line-height: 5.6rem; } }
  @media (min-width: 480px) {
    .contact-column-text {
      line-height: 5.09091rem; } }
  @media (min-width: 545px) {
    .contact-column-text {
      line-height: 4.66667rem; } }
  @media (min-width: 768px) {
    .contact-column-text {
      line-height: 3.73333rem; } }
  @media (min-width: 992px) {
    .contact-column-text {
      line-height: 3.6rem; } }
  .contact-column-text:before {
    color: #ff5c00;
    display: block;
    margin: 10px 25px 0 0; }
    @media (min-width: 320px) {
      .contact-column-text:before {
        font-size: 6.4rem; } }
    @media (min-width: 480px) {
      .contact-column-text:before {
        font-size: 5.81818rem; } }
    @media (min-width: 545px) {
      .contact-column-text:before {
        font-size: 5.33333rem; } }
    @media (min-width: 768px) {
      .contact-column-text:before {
        font-size: 4.6rem; } }
  .contact-column-text.address:before {
    content: "\e93e"; }
  .contact-column-text.phone:before {
    content: "\e93c"; }
  .contact-column-text.email:before {
    content: "\e93d"; }

.map-wrapper .map-points {
  display: none; }

.map-wrapper .marker-detail-container {
  display: none; }

.map-canvas {
  height: 600px; }

.search-box {
  display: inline-block;
  width: 100%;
  max-width: 760px;
  font-size: 0; }
  .search-box.map-search {
    max-width: 430px; }
  .search-box-inner {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    background-color: #fff;
    padding-right: 90px;
    margin-right: -80px; }
    .map-search .search-box-inner {
      padding-right: 0;
      margin-right: 0; }
    .search-box-inner input[type="text"] {
      line-height: 70px;
      background: #fff;
      font-size: 16px;
      height: 70px;
      border: 0; }
      .search-box-inner input[type="text"]:focus {
        outline: none; }
  .search-box-btn {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 80px;
    height: 70px;
    line-height: 70px; }
    .map-search .search-box-btn {
      display: none; }
    .search-box-btn:before {
      content: "\e904";
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #ff5c00;
      color: #fff;
      border-radius: 50%;
      font-size: 20px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .search-box-btn:hover:before {
      width: 80px;
      height: 70px;
      border-radius: 0;
      line-height: 70px; }

.marker-detail {
  color: #fff;
  text-align: center;
  padding: 25px;
  font-size: 16px;
  line-height: 25px;
  font-family: "Fedra", sans-serif; }
  .marker-detail h2 {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 0; }
  .marker-detail .number,
  .marker-detail .city,
  .marker-detail .email {
    padding-bottom: 10px; }
  .marker-detail a:hover {
    color: #ff5c00; }
  .marker-detail-position .gm-style-iw-c {
    padding: 0 !important; }
  .marker-detail-position:after {
    width: 0 !important;
    height: 0 !important;
    border-style: solid;
    border-width: 11px 10px 0 10px;
    border-color: #323337 transparent transparent transparent;
    transform: translate(-50%, 0%) !important;
    box-shadow: none !important;
    opacity: 0.6 !important; }
  .marker-detail-flyout {
    background-color: rgba(50, 51, 55, 0.8) !important;
    border: 0 !important;
    border-radius: 0 !important; }
    .marker-detail-flyout > div {
      background-color: transparent !important;
      overflow: hidden !important; }
  .marker-detail-close {
    opacity: 1 !important;
    width: 50px !important;
    height: 50px !important;
    outline: none !important; }
    .marker-detail-close > img,
    .marker-detail-close > span {
      display: none !important; }
    .marker-detail-close:before {
      content: "\e900";
      color: #ff5c00; }

@media (min-width: 320px) {
  .contact-slider.slider-init .main-slider-inner {
    min-height: 40rem; } }

@media (min-width: 480px) {
  .contact-slider.slider-init .main-slider-inner {
    min-height: 36.36364rem; } }

@media (min-width: 545px) {
  .contact-slider.slider-init .main-slider-inner {
    min-height: 33.33333rem; } }

@media (min-width: 768px) {
  .contact-slider.slider-init .main-slider-inner {
    min-height: 30rem; } }

.downloads-container .row {
  margin-left: -18px;
  margin-right: -18px; }
  .downloads-container .row [class*="col-"] {
    padding-left: 18px;
    padding-right: 18px; }

.download-item {
  display: block;
  background-color: #fff;
  padding: 30px 120px;
  position: relative;
  -webkit-box-shadow: 4px 4px 0px 0px rgba(100, 101, 105, 0);
  -moz-box-shadow: 4px 4px 0px 0px rgba(100, 101, 105, 0);
  box-shadow: 4px 4px 0px 0px rgba(100, 101, 105, 0);
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  margin-bottom: 30px; }
  .download-item:before {
    content: "\e937";
    display: block;
    position: absolute;
    top: 32px;
    left: 36px;
    font-size: 70px; }
  .download-item:focus, .download-item:hover {
    -webkit-box-shadow: 6px 6px 10px 2px rgba(100, 101, 105, 0.5);
    -moz-box-shadow: 6px 6px 10px 2px rgba(100, 101, 105, 0.5);
    box-shadow: 6px 6px 10px 2px rgba(100, 101, 105, 0.5);
    color: #ff5c00;
    text-decoration: none; }
  .download-item-name {
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 5px; }
  .download-item-file {
    font-size: 14px;
    color: #646569; }

.page-404-container {
  text-align: center; }

@media (min-width: 320px) {
  .page-404-image {
    margin-bottom: 5rem; } }

.page-404-image svg {
  margin: 0 auto; }
  @media (min-width: 320px) {
    .page-404-image svg {
      width: 30rem; } }
  @media (min-width: 320px) {
    .page-404-image svg {
      height: 40rem; } }

body {
  overflow-x: hidden; }

.wrapper-black {
  background-color: #000; }
  .wrapper-black h2 {
    color: #fff; }
  .wrapper-black p {
    color: #fff; }

#content.slider-no-features {
  margin-top: -183px; }
  #content.slider-no-features:before {
    margin-top: -171px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%); }
  #content.slider-no-features:after {
    content: "";
    background: transparent;
    height: auto;
    width: auto;
    display: block;
    margin-top: 0; }

.project-item-wrapper {
  display: grid;
  grid-gap: 8rem;
  grid-template-columns: 1fr;
  margin-bottom: 4rem; }
  @media (min-width: 768px) {
    .project-item-wrapper {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (min-width: 1100px) {
    .project-item-wrapper {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 11rem 4rem;
      margin-bottom: 0; } }
  .project-item-wrapper .project-item {
    position: relative;
    padding: 0 0 150px 0; }
  .project-item-wrapper .project-item-tooltip-inner {
    color: #000; }
    @media (min-width: 320px) {
      .project-item-wrapper .project-item-tooltip-inner {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .project-item-wrapper .project-item-tooltip-inner {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .project-item-wrapper .project-item-tooltip-inner {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .project-item-wrapper .project-item-tooltip-inner {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .project-item-wrapper .project-item-tooltip-inner {
        font-size: 2rem; } }
    @media (min-width: 320px) {
      .project-item-wrapper .project-item-tooltip-inner {
        line-height: 4.8rem; } }
    @media (min-width: 480px) {
      .project-item-wrapper .project-item-tooltip-inner {
        line-height: 4.36364rem; } }
    @media (min-width: 545px) {
      .project-item-wrapper .project-item-tooltip-inner {
        line-height: 4rem; } }
    @media (min-width: 768px) {
      .project-item-wrapper .project-item-tooltip-inner {
        line-height: 3.2rem; } }
    @media (min-width: 992px) {
      .project-item-wrapper .project-item-tooltip-inner {
        line-height: 3rem; } }
    .project-item-wrapper .project-item-tooltip-inner li {
      color: #000;
      font-weight: 700; }
  .project-item-wrapper .project-item-tooltip-inner-title {
    font-weight: 700;
    color: #000;
    margin-bottom: 2.5rem; }
    @media (min-width: 320px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        font-size: 5rem; } }
    @media (min-width: 480px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        font-size: 4.54545rem; } }
    @media (min-width: 545px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        font-size: 4.16667rem; } }
    @media (min-width: 768px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        font-size: 3.33333rem; } }
    @media (min-width: 992px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        font-size: 3.2rem; } }
    @media (min-width: 320px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        line-height: 5rem; } }
    @media (min-width: 480px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        line-height: 4.54545rem; } }
    @media (min-width: 545px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        line-height: 4.16667rem; } }
    @media (min-width: 768px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        line-height: 3.33333rem; } }
    @media (min-width: 992px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        line-height: 3.125rem; } }
    @media (min-width: 1200px) {
      .project-item-wrapper .project-item-tooltip-inner-title {
        line-height: 3rem; } }
  .project-item-wrapper .project-item-tooltip-teaser {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    overflow: hidden; }
    .project-item-wrapper .project-item-tooltip-teaser img {
      min-height: 100%;
      min-width: 100%;
      max-width: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .4s ease-in-out; }
      @media (min-width: 992px) {
        .project-item-wrapper .project-item-tooltip-teaser img {
          filter: grayscale(100%); } }
    .project-item-wrapper .project-item-tooltip-teaser span {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      z-index: 5;
      color: #fff;
      text-transform: uppercase;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all .4s ease-in-out;
      background: rgba(0, 0, 0, 0.4); }
      @media (min-width: 320px) {
        .project-item-wrapper .project-item-tooltip-teaser span {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .project-item-wrapper .project-item-tooltip-teaser span {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .project-item-wrapper .project-item-tooltip-teaser span {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .project-item-wrapper .project-item-tooltip-teaser span {
          font-size: 2.4rem; } }
      @media (min-width: 992px) {
        .project-item-wrapper .project-item-tooltip-teaser span {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .project-item-wrapper .project-item-tooltip-teaser span {
          font-size: 2rem; } }
      @media (min-width: 992px) {
        .project-item-wrapper .project-item-tooltip-teaser span {
          opacity: 0; } }
    @media (min-width: 992px) {
      .project-item-wrapper .project-item-tooltip-teaser:focus span, .project-item-wrapper .project-item-tooltip-teaser:hover span {
        z-index: 5;
        color: #fff;
        text-transform: uppercase; } }
  @media (min-width: 992px) and (min-width: 320px) {
    .project-item-wrapper .project-item-tooltip-teaser:focus span, .project-item-wrapper .project-item-tooltip-teaser:hover span {
      font-size: 3.6rem; } }
  @media (min-width: 992px) and (min-width: 480px) {
    .project-item-wrapper .project-item-tooltip-teaser:focus span, .project-item-wrapper .project-item-tooltip-teaser:hover span {
      font-size: 3.27273rem; } }
  @media (min-width: 992px) and (min-width: 545px) {
    .project-item-wrapper .project-item-tooltip-teaser:focus span, .project-item-wrapper .project-item-tooltip-teaser:hover span {
      font-size: 3rem; } }
  @media (min-width: 992px) and (min-width: 768px) {
    .project-item-wrapper .project-item-tooltip-teaser:focus span, .project-item-wrapper .project-item-tooltip-teaser:hover span {
      font-size: 2.4rem; } }
  @media (min-width: 992px) and (min-width: 992px) {
    .project-item-wrapper .project-item-tooltip-teaser:focus span, .project-item-wrapper .project-item-tooltip-teaser:hover span {
      font-size: 2.25rem; } }
  @media (min-width: 992px) and (min-width: 1200px) {
    .project-item-wrapper .project-item-tooltip-teaser:focus span, .project-item-wrapper .project-item-tooltip-teaser:hover span {
      font-size: 2rem; } }
  @media (min-width: 992px) and (min-width: 992px) {
    .project-item-wrapper .project-item-tooltip-teaser:focus span, .project-item-wrapper .project-item-tooltip-teaser:hover span {
      opacity: 1; } }
    @media (min-width: 992px) {
      .project-item-wrapper .project-item-tooltip-teaser:focus img, .project-item-wrapper .project-item-tooltip-teaser:hover img {
        filter: none; } }

.parallax-max-width .parallax-slider-widget-right {
  margin-top: 8rem; }
  @media (min-width: 545px) {
    .parallax-max-width .parallax-slider-widget-right {
      margin-top: 7rem; } }
  @media (min-width: 768px) {
    .parallax-max-width .parallax-slider-widget-right {
      margin-top: 0;
      max-width: 100%; } }
  @media (min-width: 992px) {
    .parallax-max-width .parallax-slider-widget-right {
      max-width: 100% !important;
      margin-top: 0; } }

@media (min-width: 768px) {
  .parallax-max-width .parallax-slider-background-item {
    width: 100%; } }

@media (min-width: 992px) {
  .parallax-max-width .parallax-slider-background-item {
    width: 830px !important; } }

@media (min-width: 1600px) {
  .parallax-max-width .parallax-slider-background-item {
    width: 100% !important; } }

.parallax-max-width .parallax-slider-text h2 {
  color: #000;
  font-weight: 700; }
  .parallax-max-width .parallax-slider-text h2:after {
    display: none; }

.parallax-max-width .parallax-slider-text p {
  color: #000; }
  @media (min-width: 320px) {
    .parallax-max-width .parallax-slider-text p {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .parallax-max-width .parallax-slider-text p {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .parallax-max-width .parallax-slider-text p {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .parallax-max-width .parallax-slider-text p {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .parallax-max-width .parallax-slider-text p {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .parallax-max-width .parallax-slider-text p {
      font-size: 1.8rem; } }

.parallax-max-width .parallax-slider {
  background-color: rgba(0, 0, 0, 0.9); }

.parallax-max-width .parallax-slider-item .btn-link:focus, .parallax-max-width .parallax-slider-item .btn-link:hover {
  color: #ff7d33; }

.parallax-max-width .parallax-slider-item-name {
  color: #fff; }
  @media (min-width: 320px) {
    .parallax-max-width .parallax-slider-item-name {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .parallax-max-width .parallax-slider-item-name {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .parallax-max-width .parallax-slider-item-name {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .parallax-max-width .parallax-slider-item-name {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .parallax-max-width .parallax-slider-item-name {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .parallax-max-width .parallax-slider-item-name {
      font-size: 1.8rem; } }

.parallax-max-width .parallax-slider-item-text {
  color: #fff;
  font-size: 14px; }
  @media (min-width: 320px) {
    .parallax-max-width .parallax-slider-item-text {
      line-height: 3.6rem; } }
  @media (min-width: 480px) {
    .parallax-max-width .parallax-slider-item-text {
      line-height: 3.27273rem; } }
  @media (min-width: 545px) {
    .parallax-max-width .parallax-slider-item-text {
      line-height: 3rem; } }
  @media (min-width: 768px) {
    .parallax-max-width .parallax-slider-item-text {
      line-height: 2.4rem; } }
  @media (min-width: 992px) {
    .parallax-max-width .parallax-slider-item-text {
      line-height: 2.25rem; } }
  @media (min-width: 1200px) {
    .parallax-max-width .parallax-slider-item-text {
      line-height: 2rem; } }

.cta-btn-container br {
  display: none; }
  @media screen and (min-width: 730px) {
    .cta-btn-container br {
      display: inline-block; } }

.cta-btn-container-center {
  padding-top: 2rem;
  padding-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  @media (min-width: 992px) {
    .cta-btn-container-center {
      padding-bottom: 10rem; } }

.partners-slider-container h5 {
  padding-bottom: 4rem; }

.partner-slider {
  margin-left: -15px;
  margin-right: -15px; }

.partner-item {
  padding: 15px 20px; }
  @media (min-width: 992px) {
    .partner-item {
      padding: 15px 40px; } }
  .partner-item-inner {
    height: 46px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .partner-item img {
    margin: 0 auto;
    max-height: 46px;
    filter: grayscale(100%);
    opacity: 0.5;
    -moz-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    -webkit-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms; }
    .partner-item img:hover {
      filter: grayscale(0%);
      opacity: 1; }

.cta-container.homepage.reverse {
  background-repeat: no-repeat;
  width: 100% !important;
  height: 100% !important;
  background-size: cover;
  max-width: 100% !important; }

.infobox-items.features-smaller-font {
  margin-bottom: 0; }

@media (min-width: 320px) and (max-width: 1199px) {
  .img-left-center .cta-content {
    width: 100%;
    display: block; } }

.img-left-center .cta-content-image {
  flex: 1 0 450px;
  max-width: 450px;
  margin-top: 4rem;
  position: absolute;
  top: 0;
  left: 5.5rem; }
  @media (min-width: 320px) and (max-width: 1399px) {
    .img-left-center .cta-content-image {
      left: -4.5rem;
      margin: 0 auto;
      position: relative;
      top: 2rem;
      left: auto; } }
  @media (min-width: 1200px) {
    .img-left-center .cta-content-image {
      position: absolute;
      left: 5.5rem; } }

.img-left-center .cta-wrapper {
  margin-top: 0; }

@media (min-width: 545px) {
  .img-left-center .cta-content-image-inner {
    width: 450px;
    height: 450px; } }

.img-left-center .cta-content-text-inner {
  color: #fff;
  position: relative;
  z-index: 5;
  max-width: 100%;
  padding: 4rem 1rem 1rem 1rem; }
  @media (min-width: 545px) {
    .img-left-center .cta-content-text-inner {
      padding: 0;
      max-width: 100%;
      padding: 4rem 8rem 5rem 8rem; } }
  @media (min-width: 1200px) {
    .img-left-center .cta-content-text-inner {
      padding: 4rem 8rem 5rem 45rem; } }

.img-left-center .cta-content.with-devider h2 {
  font-weight: 700 !important;
  padding-bottom: 0.75rem; }
  @media (min-width: 320px) {
    .img-left-center .cta-content.with-devider h2 {
      font-size: 7rem; } }
  @media (min-width: 480px) {
    .img-left-center .cta-content.with-devider h2 {
      font-size: 6.36364rem; } }
  @media (min-width: 545px) {
    .img-left-center .cta-content.with-devider h2 {
      font-size: 5.83333rem; } }
  @media (min-width: 768px) {
    .img-left-center .cta-content.with-devider h2 {
      font-size: 4.66667rem; } }
  @media (min-width: 992px) {
    .img-left-center .cta-content.with-devider h2 {
      font-size: 4.375rem; } }
  @media (min-width: 1200px) {
    .img-left-center .cta-content.with-devider h2 {
      font-size: 4rem; } }

.section-table-wrapper {
  position: relative;
  overflow: hidden; }
  .section-table-wrapper:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, #fff 99%); }
    .wrapper-black .section-table-wrapper:before {
      background: radial-gradient(circle, rgba(0, 0, 0, 0.4) 0%, #000 99%); }
    body.EditMode .section-table-wrapper:before,
    body.DesignMode .section-table-wrapper:before {
      display: none; }
  .section-table-wrapper:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 2;
    background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 65%, white 100%); }
    body.EditMode .section-table-wrapper:after,
    body.DesignMode .section-table-wrapper:after {
      display: none; }
  .section-table-wrapper > img {
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; }
  .section-table-wrapper .section-table-overflow {
    position: relative;
    z-index: 3; }

.section-table-container {
  background: transparent;
  position: relative;
  width: 100%;
  padding: 0; }
  @media (min-width: 1200px) {
    .section-table-container {
      width: 37%; } }
  .section-table-container:after {
    content: '';
    height: 93%;
    width: 3px;
    position: absolute;
    right: 0;
    background-color: #ff5c00;
    top: 4%;
    left: 48%; }
  .section-table-container tr td {
    padding: 15px 25px; }
  .section-table-container tr td:last-child {
    border-right: none; }
  .section-table-container tr:nth-child(2n) {
    background: transparent !important; }
  .section-table-container tr {
    border-bottom: none !important; }

.single-slider-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center; }
  .single-slider-container .slick-arrow {
    display: inline-block;
    background-color: transparent;
    color: #ff5c00;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 0%;
    border: 2px solid #ff5c00;
    overflow: hidden;
    font-size: 0;
    padding: 0;
    -moz-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    -webkit-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms;
    cursor: pointer;
    outline: none; }
    .single-slider-container .slick-arrow:before {
      display: inline-block;
      font-size: 20px;
      line-height: 36px;
      content: "\e942"; }
    .single-slider-container .slick-arrow:hover {
      background-color: #ff5c00;
      color: #fff;
      text-decoration: none; }
    @media (min-width: 768px) {
      .single-slider-container .slick-arrow {
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin: 0 0 0 50px; }
        .single-slider-container .slick-arrow:before {
          line-height: 46px; } }
    .single-slider-container .slick-arrow.slick-prev:before {
      content: "\e943"; }
    @media (min-width: 768px) {
      .single-slider-container .slick-arrow.slick-prev {
        margin: 0 50px 0 0; } }
  .single-slider-container .slick-dots {
    display: flex;
    margin: 0 -5px;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 10px 0 0 0; }
    @media (min-width: 545px) {
      .single-slider-container .slick-dots {
        margin: 0 -10px;
        padding: 20px 0 0 0; } }
    .single-slider-container .slick-dots li {
      padding: 0 5px; }
      @media (min-width: 545px) {
        .single-slider-container .slick-dots li {
          padding: 0 10px; } }
      .single-slider-container .slick-dots li:before {
        display: none; }
      .single-slider-container .slick-dots li button {
        background: transparent;
        border: 2px solid #797979;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        font-size: 0;
        padding: 0;
        outline: none;
        cursor: pointer; }
      .single-slider-container .slick-dots li.slick-active button {
        background: #ff5c00;
        border: 2px solid #ff5c00; }

.single-slider {
  max-width: calc(100% - 80px); }
  @media (min-width: 768px) {
    .single-slider {
      max-width: calc(100% - 200px); } }

.annual-report-items {
  display: flex;
  flex-flow: row wrap; }

.annual-report-item {
  margin: 0 0 20px 0; }
  @media (min-width: 992px) {
    .annual-report-item {
      margin: 0 0 60px 0;
      flex: 1 0 50%;
      max-width: 50%; } }
  .annual-report-item-title {
    padding: 0 0 0 20px;
    font-size: 48px;
    font-weight: 700;
    margin: 0 0 20px 0; }
    @media (min-width: 992px) {
      .annual-report-item-title {
        padding: 0 0 0 45px; } }
  .annual-report-item-files {
    display: flex;
    flex-flow: row wrap; }
  .annual-report-item-file {
    padding: 0 0 0 20px;
    margin: 0 0 20px 0; }
    @media (min-width: 992px) {
      .annual-report-item-file {
        margin: 0 0 40px 0;
        padding: 0 0 0 45px;
        max-width: 40%; } }
    .annual-report-item-file img {
      display: block;
      width: 200px;
      margin: 0 0 20px 0;
      max-width: 100%; }
    .annual-report-item-file > div {
      margin: 0 0 20px 0;
      font-size: 18px;
      font-weight: 500; }
  .annual-report-item-link {
    font-size: 0;
    color: #fff; }
    .annual-report-item-link:hover {
      color: #ff5c00;
      text-decoration: none; }
    .annual-report-item-link:before {
      content: "\e941";
      font-size: 25px; }

.annual-report-collapse {
  flex: 1 0 100%; }
  .annual-report-collapse-inner {
    display: flex;
    flex-flow: row wrap; }
  .annual-report-collapse-control {
    flex: 1 0 100%; }
    @media (min-width: 320px) {
      .annual-report-collapse-control {
        padding-bottom: 10rem; } }

.cookies-btn {
  border: 2px solid #ff5c00 !important;
  color: #ff5c00 !important;
  background-color: #fff !important;
  font-size: 14px !important;
  line-height: 20px !important;
  padding: 11px 25px 11px 25px !important;
  font-weight: 500 !important;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .cookies-btn {
      font-size: 16px !important;
      padding: 13px 30px 13px 30px !important; } }
  .cookies-btn:hover {
    background-color: #ff5c00 !important;
    color: #fff !important; }

.cookies-modal-content {
  max-width: 800px !important; }

.cookies-modal-footer .cookies-modal-footer-left {
  flex-wrap: wrap !important; }
