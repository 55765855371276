@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

#brokers-map {
    width: 100%;
    padding: 80% 0 0 0;
    margin: 0 -15px 20px -15px;

    @include media(md) {
        padding: 60% 0 0 0;
    }

    @include media(xxxl) {
        padding: 55% 0 0 0;
    }

    @include loader(60px, $base-white, 1, $table, $main-color);

    .zfp-leaflet-tooltip {
        font-family: $font-family;
        font-size: 0;
        border: 0;
        box-shadow: none;
        background-color: transparent;
        opacity: 1 !important;

        @include media(md) {
            font-size: 12px;
        }

        @include media(xxl) {
            font-size: 16px;
        }

        &.active {
            color: $base-white;
        }

        &.ST {
            margin: 20px 0 0 0;

            @include media(lg) {
                margin: 40px 0 0 0;
            }
        }

        &.OL {
            margin: 20px 0 0 0;

            @include media(lg) {
                margin: 20px 0 0 6px;
            }

            @include media(xxxl) {
                margin: 40px 0 0 0;
            }
        }

        &.JM {
            margin: 10px 0 0 5px;

            @include media(xxxl) {
                margin: 0;
            }
        }

        &.KR {
            margin: 0 0 0 -17px;
        }

        &.MO {
            margin: 0px 0 0 6px;

            @include media(xxxl) {
                margin: 0;
            }
        }
    }

    .leaflet-control-attribution {
        display: none;
    }
}

.broker-county-container {
    .selectric-wrapper {
        width: 100%;
        max-width: 360px;
        margin: 0 auto;
    }

    .selectric {
        border-color: $main-color;
    }
}

.broker-items {
    margin: 0 -15px 30px -15px;

    @include media(lg) {
        margin: 0 -15px 60px -15px;
    }

    .items-container {
        display: flex;
        flex-flow: row wrap;
        min-height: 250px;

        @include loader(60px, $base-white, 0.9, $table, $main-color);

        [id*="pnlNoData"] {
            flex: 1 0 100%;
            width: 100%;
        }

        .pux-infinite-repeater-no-data-title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: 16px;
            padding: 0 15px;

            @include media(lg) {
                font-size: 24px;
            }

            &:before {
                content: "\e92c";
                font-size: 40px;
                margin: 0 10px 0 0;
                color: $main-color;
            }
        }
    }

    .show-more-container {
        text-align: center;
        @include pux-scale-with-min("padding-top", 30px, 20px);
        @include pux-scale-with-min("padding-bottom", 30px, 20px);

        .arrow-down-link {
            display: inline-block;
            background-color: transparent;
            color: $main-color;
            text-align: center;
            border-radius: 0;
            overflow: hidden;
            border: 2px solid $main-color;
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
            padding: 11px 25px 11px 25px;
            text-transform: uppercase;
            position: relative;
            @include transition(100ms);

            @include media(md) {
                font-size: 20px;
                line-height: 24px;
                padding: 13px 34px 13px 34px;
            }

            &:hover {
                text-decoration: none;
                background-color: $main-color;
                color: $base-white;
            }
        }
    }

    .broker-item {
        margin: 0 0 30px 0;
        padding: 0 15px;
        width: 100%;
        flex: 1 0 100%;

        @include media(md) {
            flex: 1 0 50%;
            max-width: 50%;
        }

        @include media(lg) {
            flex: 1 0 33.33333%;
            max-width: 33.33333%;
        }

        &-inner {
            padding: 25px 25px 25px 25px;
            background-color: $light-grey;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            height: 100%;

            @include media(md) {
                padding: 35px 35px 25px 25px;
            }
        }

        &-left {
            padding: 0 15px 0 0;
            @include pux-scale-with-min("font-size", 24px, 18px);
            @include pux-scale-with-min("line-height", 36px, 25px);
        }

        &-name {
            font-weight: 700;
            color: $second-color;

            &:hover {
                text-decoration: none;
                color: $main-color;
            }
        }

        &-func {
            font-style: italic;
        }

        &-city {
            margin: 0 0 10px 0;
        }

        &-link {
            display: inline-block;
            width: 32px;
            height: 32px;
            line-height: 28px;
            text-align: center;
            border: 2px solid $main-color;

            &:before {
                content: "\e908";
                font-size: 12px;
                display: inline-block;
                vertical-align: 4px;

                .Safari & {
                    vertical-align: 2px;
                }
            }

            &:hover,
            &:focus {
                text-decoration: none;
                background-color: $main-color;
                color: $base-white;
            }
        }

        &-right {
            padding: 10px 0 0 15px;
            width: 100%;
            max-width: 100px;

            img {
                width: 100px;
                border-radius: 50%;

                &.broker-item-default-image {
                    border-radius: 0;
                }
            }
        }
    }
}

.broker-page {
    .top-image .main-slider-inner-content {
        max-width: 700px;
    }

    .top-image {
        .main-slider-inner {
            @include media(begin, sm) {
                padding: 100px 0 40px 0;
            }
        }

        .main-slider-image {
            @include media(begin, sm) {
                position: static;
            }

            &:before {
                @include media(begin, sm) {
                    display: none;
                }
            }

            img {
                @include media(begin, sm) {
                    max-width: 100%;
                }
            }
        }
    }

    .wrapper-black .top-image .main-slider-image:before {
        background: radial-gradient(circle, rgba(0, 0, 0, 0.2) 0%, #000 99%);
    }

    .content-overflow {
        @include media(begin, sm) {
            margin-top: 40px;
        }
    }

    .infobox-item-col {
        @include media(lg) {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    .infobox-item-icon {
        @include media(lg) {
            margin: 0 0 30px 0;
        }
    }
}

.broker-detail-item {
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 0 20px 0;

    @include media(lg) {
        margin: 0 0 30px 0;
    }

    &>[class^="zfp-ico-"],
    &>[class*=" zfp-ico-"] {
        color: $main-color;
        font-size: 36px;
        display: block;
        margin: 0 25px 0 0;

        @include media(lg) {
            margin: 0 40px 0 0;
        }
    }

    .broker-detail-item-link {
        color: $base-white;
    }

    a:hover,
    a:focus {
        color: $main-color;
    }
}

.broker-rating-item {
    font-size: 16px;
    line-height: 25px;
    padding: 0 20px;

    @include media(lg) {
        padding: 0 50px;
    }

    &-name {
        margin: 0 0 20px 0;
        font-size: 24px;
    }
}