@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.blog {
    &-filter {
        &-main {
            ul {
                margin-left: -15px;
                margin-right: -15px;

                @include media(lg) {
                    padding-bottom: 30px;
                }

                @include clearfix;

                li {
                    display: block;
                    float: left;
                    width: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                    margin-bottom: 15px;

                    &:before {
                        display: none;
                    }

                    a {
                        display: block;
                        text-align: center;
                        color: $base-white;
                        text-transform: uppercase;
                        font-size: 16px;
                        line-height: 50px;
                        padding: 0 20px;
                        border: 2px solid $main-color;
                        font-weight: 500;

                        &:hover {
                            background-color: $main-color;
                            text-decoration: none;
                        }
                    }

                    &.Highlighted {
                        a {
                            background-color: $main-color;
                            color: $base-white;
                        }
                    }

                    @include media(md) {
                        width: 50%;
                        margin-bottom: 30px;
                    }

                    @include media(lg) {
                        width: 25%;

                        a {
                            font-size: 17px;
                            line-height: 70px;
                        }
                    }
                }
            }
        }

        &-secondary {
            text-align: center;
            @include pux-scale-with-min("margin-bottom", 60px, 30px);

            a {
                display: inline-block;
                background-color: $base-white;
                font-size: 14px;
                text-transform: uppercase;
                line-height: 40px;
                padding: 0 15px;
                margin: 0 0 15px 0;
                width: 100%;

                @include media(sm) {
                    width: auto;
                    font-size: 15px;
                    padding: 0 30px;
                    margin: 0 5px 10px 5px;
                }

                &.active {
                    background-color: $main-color;
                    color: $base-white;
                }
            }
        }
    }

    &-items {
        margin-left: -15px;
        margin-right: -15px;

        @include media(xxl) {
            margin-left: -35px;
            margin-right: -35px;
        }

        .items-container {
            @include flex-block();
            @include flex-flow(row, wrap);
        }

        .show-more-container {
            text-align: center;
            @include pux-scale-with-min("padding-top", 30px, 20px);
            @include pux-scale-with-min("padding-bottom", 30px, 20px);

            .arrow-down-link {
                display: inline-block;
                background-color: transparent;
                color: $base-white;
                text-align: center;
                border-radius: 0;
                overflow: hidden;
                border: 2px solid $main-color;
                font-size: 20px;
                line-height: 24px;
                padding: 11px 25px 11px 25px;
                text-transform: uppercase;
                position: relative;
                @include transition(100ms);
                font-weight: 500;

                @include media(md) {
                    font-size: 20px;
                    line-height: 24px;
                    padding: 13px 34px 13px 34px;
                }

                &:hover {
                    text-decoration: none;
                    background-color: $main-color;
                    color: $base-white;
                }
            }
        }
    }

    &-item {
        @include flex(100%);
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px;

        &-teaser {
            position: relative;
            display: block;
            overflow: hidden;
            z-index: 1;
            height: 211px;

            &-inner {
                display: block;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                @include transition(400ms);
            }
        }

        &-info {
            background-color: $text-color;
            position: relative;
            padding: 25px 20px 20px 20px;
            z-index: 2;
            @include transition(400ms);
            text-align: left;

            &-name {
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;
                display: block;
                min-height: 60px;
                margin-bottom: 3rem;
            }

            a.blog-item-info-name {
                color: $base-white;

                &:hover {
                    color: $main-color;
                    text-decoration: none;
                }
            }

            &-category {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: middle;
                line-height: 30px;
                padding: 0 20px;
                background-color: $color-subtext;
                color: $base-white;
                text-transform: uppercase;
                max-width: 180px;
                font-size: 14px;

                @include media(sm) {
                    max-width: 120px;
                }

                @include media(md) {
                    max-width: 140px;
                }

                @include media(lg) {
                    max-width: 160px;
                }

                @include media(xxl) {
                    max-width: none;
                    font-size: 16px;
                }

                &:hover {
                    text-decoration: none;
                    color: $base-white;
                    background-color: $main-color;
                }
            }

            &-date {
                float: right;
                line-height: 30px;
                vertical-align: middle;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: $main-color;
            }
        }

        @include media(sm) {
            @include flex(50%);
            max-width: 50%;
        }

        @include media(lg) {
            @include flex(33.333333%);
            max-width: 33.333333%;

            &-teaser {
                height: 211px;
            }

            &-info {
                background-color: $text-color;
            }
        }

        @include media(xxl) {
            padding-left: 35px;
            padding-right: 35px;
        }
    }

    &-page {
        .grey-wrapper {
            background-color: $base-black;
        }

        &-flex {
            @include pux-scale-with-min("padding-top", 140px, 60px);
            @include pux-scale-with-min("padding-bottom", 80px, 20px);
            @include flex-block();
            @include flex-flow(row, wrap);
        }

        &-header {
            border-top: 1px solid $base-white;
            padding-bottom: 5px;
            padding-top: 10px;
            @include pux-scale-with-min("margin-top", 30px, 20px);
            @include pux-scale-with-min("margin-bottom", 40px, 20px);
            color: $base-white;

            @include flex(100%);
            @include order(2);

            @include media(xl) {
                @include order(0);
                padding-top: 0;
                border-top: 0;
                border-bottom: 1px solid $base-white;
            }

            &-tags {
                font-size: 0;
                margin-bottom: 10px;

                a {
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 28px;
                    padding: 0 15px;
                    text-transform: uppercase;
                    font-size: 14px;
                    margin-bottom: 10px;
                    border: 2px solid $main-color;

                    &:not(.blog-page-header-category) {
                        background-color: $base-white;
                        border: 2px solid $base-white;
                        color: $main-color;

                        &:hover {
                            background-color: darken($light-grey, 5);
                            color: $second-color;
                            text-decoration: none;
                        }
                    }

                    &:not(:last-of-type) {
                        margin-right: 10px;
                    }

                    @include media(sm) {
                        font-size: 14px;
                        padding: 0 20px;
                    }
                }

                @include media(md) {
                    margin-bottom: 0px;
                }
            }

            &-category {
                background-color: transparent;
                color: $base-white;

                &:hover {
                    background-color: $main-color;
                    color: $base-white;
                    text-decoration: none;
                }
            }

            &-date {
                margin-bottom: 10px;

                @include media(sm) {
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 0px;

                    &:after {
                        content: "|";
                        margin-left: 10px;
                        color: $base-white;
                    }
                }
            }

            &-author {
                margin-bottom: 10px;
                display: none;

                @include media(sm) {
                    display: none;
                    margin-right: 10px;
                    margin-bottom: 0px;

                    &:after {
                        content: "|";
                        margin-left: 10px;
                        color: $base-white;
                    }
                }
            }

            &-social {
                display: inline-block;

                ul {
                    display: inline-block;
                    vertical-align: -8px;
                    margin-left: 10px;

                    li {
                        margin: 0;
                        padding: 0;

                        &:before {
                            display: none;
                        }

                        .social-ico {
                            color: $base-white;

                            &:hover {
                                text-decoration: none;
                            }

                            &.facebook {
                                &:before {
                                    content: "\e93b";
                                    font-size: 32px
                                }
                            }
                        }
                    }
                }
            }
        }

        &-title {
            @include flex(100%);
            @include order(0);
            color: $base-white;

            @include media(xl) {
                @include order(1);
            }

            h1 {
                text-align: center;
                @include pux-scale-with-min("margin-bottom", 60px, 30px);

                @include media(xl) {
                    padding: 0 100px;
                }
            }
        }

        &-content {
            @include flex(100%);
            @include order(1);
            color: $base-white;

            p,
            big,
            .perex {
                color: $base-white
            }

            @include media(xl) {
                @include order(2);
            }
        }

        &-latest {
            color: $base-white;

            .items-container {
                .blog-item {
                    @include media(xl) {
                        &:nth-of-type(4) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.author {
    &-item {
        background-color: $text-color;
        font-size: 0;
        display: flex;
        padding: 20px 15px;
        @include media(md) {
            padding: 30px 35px;
        }

        &-left {
            margin: 0 3rem 0 0;
        }

        &-right {
            width: 100%;
        }

        &-avatar {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &-name {
            font-size: 22px;
            line-height: 30px;
            color: $base-white;
            font-weight: 700;
            text-transform: uppercase;
        }

        &-description {
            font-size: 16px;
            line-height: 25px;
            color: $base-white;
        }

        &-social {
            text-align: right;
            @include create-social(21px, 0, 30px, 50%, none, none, 0, 0, $base-white, $base-white, true, false, false);

            ul {
                display: block;

                li {
                    display: inline-block;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.blog-stories {
    margin: 0;

    @include media(lg) {
        padding-left: 70px;
        padding-right: 70px;
    }

    .blog-item {
        @include media(lg) {
            @include flex(50%);
            max-width: 50%;
            margin-bottom: 50px;
        }

        @include media(xxl) {
            padding: 0 70px;
            margin-bottom: 80px;
        }
    }

    .blog-item-info-date {
        font-weight: 700;
        text-transform: uppercase;
    }
}