@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.top-image {
    .main-slider {
        padding-bottom: 0px;
        flex-wrap: wrap;

        @include media(lg) {
            min-height: 100vh;
            flex-wrap: nowrap;
            padding-top: 10rem;
        }

        @include media(2K) {
            min-height: 980px;
        }

        &-inner {
            padding-bottom: 70px;
            width: 100%;
            flex: 0 1 100%;
            order: 1;
            position: relative;
            z-index: 1;
            padding: 100px 0 140px 0;

            @include media(lg) {
                min-height: 100vh;
                padding: 0;
            }

            @include media(2K) {
                min-height: 980px;
            }

            &-content {
                margin: 0;

                @include media(lg) {
                    max-width: 540px;
                    margin: -200px 0 0 0;
                }

                &-title {
                    @include pux-scale-with-min("font-size", 60px, 25px);
                    @include pux-scale-with-min("line-height", 66px, 26px);
                    @include pux-scale-with-min("margin-bottom", 50px, 20px);
                    font-weight: 700;
                    color: $base-black;
                    text-transform: uppercase;

                    &.normal-font {
                        text-transform: none;
                    }

                    .wrapper-black & {
                        color: $base-white;
                    }
                }

                &-text {
                    color: $paragraph-color;
                    @include pux-scale-with-min("font-size", $paragraph-font-size, $paragraph-font-size-min);
                    @include pux-scale-with-min("line-height", $paragraph-line-height, $paragraph-line-height-min);
                    @include pux-scale-with-min("margin-bottom", 40px, 20px);
                    color: $base-black;

                    p {
                        color: $base-black;
                    }

                    ul li {
                        color: $base-black;
                    }

                    .wrapper-black & {
                        color: $base-white;

                        p {
                            color: $base-white;
                        }

                        ul li {
                            color: $base-white;
                        }
                    }

                    @include media(begin, md) {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }

        &-image {
            width: 100% !important;
            flex: 0 1 100%;
            order: 2;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            overflow: hidden;

            @include media(lg) {
                flex: none;
                width: calc(100% - 500px);
            }

            @include media(xxl) {
                width: auto;
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                overflow: hidden;
                z-index: 1;
                background: radial-gradient(circle, rgba($base-white, 0.8) 0%, $base-white 99%);

                @include media(lg) {
                    background: radial-gradient(circle, rgba($base-white, 0.4) 0%, $base-white 99%);
                }

                .wrapper-black & {
                    background: radial-gradient(circle, rgba($base-black, 0.7) 0%, $base-black 99%);

                    @include media(lg) {
                        background: radial-gradient(circle, rgba($base-black, 0.4) 0%, $base-black 99%);
                    }
                }
            }

            &-wrapper {
                height: 100%;
            }

            img {
                min-width: 100%;
                max-width: none;
                min-height: 100% !important;
                position: relative;
                z-index: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &-scroll {
            display: none;

            &:before {
                content: "\e942";
                transform: rotateZ(90deg);
                margin-left: 0;
                display: block;
                width: 42px;
                height: 42px;
                line-height: 38px;
                text-align: center;
                color: $base-white;
                border: 2px solid $main-color;
                font-size: 14px;
                @include transition();
            }

            &:hover {
                text-decoration: none;

                &:before {
                    background-color: $main-color;
                }
            }
        }

        &-video {
            .main-slider-inner-content-btn {
                padding: 20px 0 40px 0;

                @include media(lg) {
                    padding: 0;
                    position: absolute;
                    top: 50%;
                    left: 70%;
                    transform: translate(-50%, -120px);
                }

                @include media(xxl) {
                    top: 50%;
                    left: 50%;
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                    background-color: transparent;
                    border: 2px solid $main-color;
                    margin: 0 auto;
                    padding: 0;

                    &:before {
                        content: '';
                        display: block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 13px 0 13px 22px;
                        border-color: transparent transparent transparent $base-white;
                        margin: 0 0 0 7px;
                        transition: all ease-in-out 300ms;
                    }

                    &:hover {
                        background-color: $main-color;

                        &:before {
                            transform: scale(1.2);
                        }
                    }
                }
            }
        }
    }

    &.top-image-text-right {
        .main-slider-inner-content {
            margin: 0 0 0 auto;
            text-align: right;

            @include media(lg) {
                margin: -10rem 0 0 auto;
            }
        }
    }

    &.top-image-people {
        &:before {
            content: "";
            background: linear-gradient(180deg, rgba($base-white, 0) 0%, $base-white 100%);
            height: 208px;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;

            @include media (lg) {
                height: 300px;
            }

            @include admin {
                display: none;
            }
        }

        .main-slider-inner {
            min-height: 0;
        }

        .main-slider-image {
            &:before {
                display: none;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($base-white, 0.8);

                @include media(lg) {
                    background-color: rgba($base-white, 0.4);
                }
            }

            img {
                @include media(md) {
                    margin-top: 120px;
                    min-width: 0;
                    min-height: 0 !important;
                    max-width: 65% !important;
                    transform: none;
                    left: auto;
                }
            }
        }
    }

    &.top-image-strategy {
        .main-slider {
            min-height: 500px;

            @include media(xxl) {
                min-height: 670px;
            }

            &-inner {
                min-height: 0;
            }
        }

        .main-slider-inner-content {
            margin: 0;
        }
    }

    &.contact-top-image {
        .main-slider {
            min-height: 420px;

            @include media(lg) {
                min-height: 680px;
            }

            &-inner {
                min-height: 0;
            }
        }

        .main-slider-image {
            img {
                height: auto !important;
            }
        }

        .main-slider-inner .pux-container {
            width: 100%;
            height: 100%;
        }

        .main-slider-inner-content {
            @include media(lg) {
                margin: 120px 0 0 0;
            }
        }

        .main-slider-inner-content-text {
            margin: 0;
        }
    }

    &.top-image-downloads {
        .main-slider {
            align-items: flex-start;
        }

        .main-slider-inner {
            justify-content: flex-start;
            min-height: 0;

            @media only screen and(-webkit-min-device-pixel-ratio: 1.25),
            only screen and(-o-min-device-pixel-ratio: 5/4),
            only screen and(min-resolution: 125dpi),
            only screen and(min-resolution: 1.25dppx) {
                min-height: 720px;
            }
        }

        .main-slider-inner-content {
            max-width: 85%;

            @include media(md) {
                max-width: 50%;
            }

            @include media(lg) {
                @include pux-scale-with-min("margin-top", 120px, 20px);
            }
        }
    }

    &.top-image-career {
        .main-slider-image {
            display: none;

            @include media(md) {
                display: block;
            }
        }

        .main-slider-scroll {
            display: inline-block;
        }
    }
}

.content-overflow {
    margin-top: 0;
    z-index: 2;
    position: relative;
    background-color: transparent;
    padding-top: 20px;
    margin-top: -100px;

    @include media (lg) {
        margin-top: -200px;
        padding-top: 40px;
    }

    @include media (xxl) {
        margin-top: -270px;
        padding-top: 60px;
    }

    @include admin {
        margin-top: 0px;
    }

    &-container {
        position: relative;
        z-index: 2;
    }

    &:before {
        content: "";
        background: linear-gradient(180deg, rgba($base-white, 0) 0%, $base-white 100%);
        height: 208px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: 100px;

        @include media (lg) {
            height: 200px;
        }

        @include media (xxl) {
            height: 270px;
        }

        @include admin {
            display: none;
        }
    }

    .wrapper-black & {
        &:before {
            background: linear-gradient(180deg, rgba($base-black, 0) 0%, $base-black 100%);
        }
    }

    &.content-overflow-downloads {
        margin-top: -70rem;

        @include media (md) {
            margin-top: -55rem;
        }

        @media (max-height: 900px) {
            margin-top: -45rem;
        }

        @media (max-height: 800px) {
            margin-top: -40rem;
        }

        &:before {
            height: 55rem;
        }
    }
}

.content-overflow-bottom {
    position: relative;

    &:after {
        @include media (xxl) {
            content: "";
            background: linear-gradient(180deg, rgba($base-white, 0) 80%, $base-white 100%);
            height: 560px;
            width: 100%;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;

            .wrapper-black & {
                background: linear-gradient(180deg, rgba($base-black, 0) 80%, $base-black 100%);
            }
        }

        @include admin {
            display: none;
        }
    }
}