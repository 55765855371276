@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.cta {
    &-image-left {
        background-size: cover;
        background-position: center;

        &.founders {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                overflow: hidden;
                background-color: rgba(0, 0, 0, 0.8);
                background: rgba(0, 0, 0, 0.9);

                @include admin {
                    display: none;
                }
            }

            .cta-content-image {
                @include media(md) {
                    @include pux-scale-with-min("margin-top", 120px, 20px);
                }

            .cta-content-image-inner {
                    @include media(lg) {
                        width: 450px;
                        height: 450px;
                    }
                }

                .cta-content-image-caption {
                    text-align: center;
                    color: $base-white;
                    padding: 20px 0;

                    >strong {
                        display: block;
                        text-align: center;
                        @include pux-scale-with-min("font-size", 22px, 20px);
                        color: $main-color;
                        font-weight: 700;
                    }
                }
            }

            .cta-content {
                justify-content: space-between;
                align-items: flex-start;

                @include media(md) {
                    flex-flow: row nowrap;
                }
            }

            .cta-content-text {
                color: $base-white;
                flex: 1 1 100%;
                max-width: none;
                margin: 0;

                &-inner {
                    padding: 30px 0;

                    @include media(md) {
                        padding: 60px 0 50px 40px;
                    }

                    @include media(lg) {
                        padding: 60px 0 50px 115px;
                    }
                }
            }
        }
    }

    &-content {
        display: flex;
        align-items: flex-start;
        flex-flow: row wrap;
        width: 100%;

        @include media(xxl) {
            align-items: center;
            flex-flow: row nowrap;
        }

        &.with-devider {
            h2 {
                position: relative;
                @include pux-scale-with-min("padding-bottom", 30px, 20px);
                font-weight: 500;

                &:after {
                    content: '';
                    width: 86px;
                    height: 4px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: $main-color;
                }
            }
        }

        &-image {
            position: relative;
            width: 100%;
            flex: 0 1 450px;
            max-width: 450px;

            @include media(xxs, xl) {
                padding: calc(30px + 1rem) 0 30px;
            }

            &-inner {
                width: 100%;
                max-width: 450px;
                aspect-ratio: 1/1;
                background-size: cover;
                background-position: center;
                padding-top: 100%;

                @include media(md) {
                    padding-top: 0;
                    width: 240px;
                    height: 240px;
                    margin-left: auto;
                }

                @include media(lg) {
                    width: 480px;
                    height: 480px;
                }
            }
        }

        &-text {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            flex: 1 0 100%;
            position: relative;
            color: $base-black;
            @include pux-scale-with-min("font-size", $paragraph-font-size, $paragraph-font-size-min);
            @include pux-scale-with-min("line-height", $paragraph-line-height, $paragraph-line-height-min);

            p {
                color: $base-black;
            }

            ul li {
                color: $base-black;
            }

            .wrapper-black & {
                color: $base-white;

                p {
                    color: $base-white;
                }
    
                ul li {
                    color: $base-white;
                }
            }

            @include media(md) {
                flex: 1 0 80%;
                max-width: 80%;
            }

            @include media(xxl) {
                flex: 1 0 60%;
                max-width: 60%;
                margin: 0 -80px;
            }

            &-inner {
                height: 100%;
                padding: 30px 0;

                @include media(md) {
                    padding: 50px 0;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    align-items: flex-start;
                }

                @include media(xxl) {
                    padding: 80px;
                }
            }

            h2,
            .h2,
            h3,
            .h3 {
                color: $base-black;

                .wrapper-black & {
                    color: $base-white;
                }
            }
        }
    }

    &-container {
        &.reverse {
            margin-left: 0;
            margin-right: auto;

            .cta-content {
                justify-content: flex-end;
            }

            .cta-content-text-inner {
                @include media(md) {
                    text-align: right;
                    align-items: flex-end;
                }
            }
        }

        &.homepage {
            .cta-content {
                display: block;

                &.with-devider {
                    h2:after {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }

            .cta-content-text {
                @include media(lg) {
                    width: 50%;
                    margin: 0 -60px;
                }

                &-inner {
                    max-width: none;

                    @include media(lg) {
                        padding: 60px;
                    }
                }
            }

            h2 {
                margin-bottom: 2.5rem;
                text-align: center !important;
                display: block;
                width: 100%;
            }

            p {
                width: 100%;
                margin: 0;
            }
        }
    }

    &-wrapper {
        &.cta-wrapper-backgound-image {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                overflow: hidden;
                z-index: 1;
                background: radial-gradient(circle, rgba($base-white, 0.8) 0%, $base-white 99%);

                @include media(lg) {
                    background: radial-gradient(circle, rgba($base-white, 0.4) 0%, $base-white 99%);
                }

                .wrapper-black & {
                    background: radial-gradient(circle, rgba($base-black, 0.7) 0%, $base-black 99%);

                    @include media(lg) {
                        background: radial-gradient(circle, rgba($base-black, 0.4) 0%, $base-black 99%);
                    }
                }
            }

            .cta-container {
                position: relative;
                z-index: 2;
            }

            &.cta-wrapper-map {
                background-size: 60% auto;
                background-position: top right;
                padding-top: 100px;

                @include media(xxl) {
                    background-size: 1200px auto;
                    padding-bottom: 200px;
                }

                &:before {
                    display: none;
                }

                h2 {

                    &:after {
                        display: none;
                    }
                }

                .cta-container.homepage .cta-content-text {
                    @include media (xxl) {
                        min-height: 837px;
                    }
                }
            }
        }

        &.cta-wrapper-with-overflow {
            padding-bottom: 120px;

            @include media(lg) {
                padding-bottom: 220px;
            }

            @include media(xxl) {
                padding-bottom: 320px;
            }
        }

        &.cta-wrapper-persons {
            .cta-content-text {
                h3 {
                    position: relative;

                    &:before {
                        content: '';
                        width: 86px;
                        height: 4px;
                        position: absolute;
                        margin: 15px 0 0 0;
                        top: 100%;
                        left: 0;
                        background-color: $main-color;
                    }
                }
            }

            @include media(xxl) {
                padding-bottom: 240px;
            }
        }

        &.cta-wrapper-timeline {
            &:before {
                display: none;
            }
        }
    }
}