@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.business-contact-frame {
    position: relative;
    padding: 0 20px;
    padding-bottom: 140px;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 2;
        background: radial-gradient(circle, rgba($base-white, 0.4) 0%, $base-white 99%);

        .wrapper-black & {
            background: radial-gradient(circle, rgba($base-black, 0.4) 0%, $base-black 99%);
        }

        @include admin {
            display: none;
        }
    }

    @include media(lg) {
        padding-bottom: 300px;
    }

    @include media(xxl) {
        padding-bottom: 420px;
    }

    >img {
        min-height: 100%;
        min-width: 100%;
        max-width: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    &-inner {
        position: relative;
        z-index: 3;
    }


    &-title {
        text-align: center;
    }

    &-text {
        border: 2px solid $base-white;
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
        padding: 20px;
        text-align: center;

        @include media(md) {
            padding: 40px 40px 50px 40px;
        }

        &:before {
            display: inline-block;
            content: "\e940";
            font-size: 75px;
            color: $main-color;
            margin: 0 0 20px 0;

            @include media(md) {
                margin: 0 0 40px 0;
            }
        }

        h3 {
            font-weight: 500;
            margin: 0 0 20px 0;
        }

        p {
            margin: 0 0 20px 0;

            @include media(md) {
                margin: 0 0 40px 0;
            }
        }
    }
}

.business-videos {
    &-container {
        position: relative;
        padding: 0 0 20px 0;

        @include media(lg) {
            padding: 0 140px 60px 140px;
        }

        @include media(xxl) {
            padding: 0 140px 120px 140px;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: calc((100% - 548px) / 2);
        background-image: linear-gradient(90deg, rgba($base-black, 1) 0%, rgba($base-black, 0) 100%);
        z-index: 3;

        @include media(xl) {
            width: calc((100% - 800px) / 2);
        }
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: calc((100% - 548px) / 2);
        background-image: linear-gradient(90deg, rgba($base-black, 0) 0%, rgba($base-black, 1) 100%);
        z-index: 3;

        @include media(xl) {
            width: calc((100% - 800px) / 2);
        }
    }

    .business-video {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        height: 100%;

        @include media(md) {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0%, -50%);
            height: 420px;
            width: 548px;
            transition: all 300ms ease-in-out;
        }

        @include media(xl) {
            height: 380px;
            width: 680px;
        }

        &-text {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: linear-gradient(90deg, rgba($base-black, 1) 0%, rgba($base-black, 0.55) 100%);
            background-size: calc(100% + 200px) 100%;
            background-position: -200px 50%;
            background-repeat: no-repeat;
            transition: background 300ms ease-in-out, padding 300ms ease-in-out, opacity 0ms ease-in-out;
            padding: 10px 20px;

            @include media(md) {
                opacity: 0;
            }

            @include media(xl) {
                padding: 20px 35px 15px 40px;
            }
        }

        &-placeholder {
            height: 280px;
            z-index: 1;


            @include media(md) {
                height: 455px;
            }

            &.swiper-slide-prev {
                z-index: 2;

                .business-video {
                    @include media(xl) {
                        height: 420px;
                        left: 25%;
                        width: 755px;
                        transform: translate(-27%, -50%);
                    }
                }
            }

            &.swiper-slide-active {
                z-index: 3;

                .business-video {
                    @include media(md) {
                        left: 50%;
                        height: 445px;
                        width: 580px;
                        transform: translate(-50%, -50%);
                    }

                    @include media(xl) {
                        height: 445px;
                        width: 800px;
                    }
                }

                .business-video-text {
                    @include media(md) {
                        opacity: 1;
                        transition: background 300ms ease-in-out, padding 300ms ease-in-out, opacity 300ms ease-in-out 150ms;
                    }
                }
            }

            &.swiper-slide-next {
                z-index: 2;

                .business-video {
                    @include media(xl) {
                        left: 25%;
                        height: 420px;
                        width: 755px;
                        transform: translate(-60%, -50%);
                    }

                    @include media(xxxl) {
                        transform: translate(-58%, -50%);
                    }
                }
            }

            &.swiper-slide-next+.business-video-placeholder {
                .business-video {
                    @include media(xl) {
                        transform: translate(-66%, -50%);
                    }
                }
            }
        }

        &-title {
            font-size: 24px;
            line-height: 28px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin: 0 0 5px 0;

            @include media(md) {
                font-size: 32px;
                line-height: 36px;
            }

            &:before {
                display: block;
                content: "\e901";
                color: $main-color;
                font-size: 32px;
                margin: 0 10px 0 -7px;

                @include media(md) {
                    font-size: 40px;
                }
            }
        }

        &:hover {
            .business-video-text {
                background-position: 0px 50%;
                background-size: 200% 100%;

                @include media(lg) {
                    padding: 25px 35px 25px 45px;
                }
            }
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    border-radius: 50%;
    font-size: 0;
    background-color: $second-color;
    transition: all 200ms ease-in-out;

    @include media(md) {
        width: 50px;
        height: 50px;
        margin-top: -25px;
    }

    &:after {
        content: "\e91a";
        font-size: 16px;
        color: $base-white;
    }

    &:hover {
        background-color: lighten($second-color, 10);
    }
}

.swiper-button-next {
    &:after {
        content: "\e91b";
    }
}