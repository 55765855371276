@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.pux-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;

    &:after {
        display: block;
        content: "";
        clear: both;
    }

    @include media(md) {
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }

    @include media(xl) {
        padding-left: $fluid-container-padding-left-xl;
        padding-right: $fluid-container-padding-right-xl;
    }
}

.small-container {
    max-width: 865px !important;
}

.pux-container {
    .row {
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;

        @include media(md) {
            margin-left: $row-margin-left-md;
            margin-right: $row-margin-right-md;
        }
    }
}

.pux-container {

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12,
    .col,
    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12,
    .col-auto,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm,
    .col-sm-auto,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md,
    .col-md-auto,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg,
    .col-lg-auto,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12,
    .col-xl,
    .col-xl-auto {
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;

        @include media(md) {
            padding-left: $col-padding-left-md;
            padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container {
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;

    @include media(md) {
        padding-left: $fluid-container-padding-left-md;
        padding-right: $fluid-container-padding-right-md;
    }

    @include media(xl) {
        padding-left: $fluid-container-padding-left-xl;
        padding-right: $fluid-container-padding-right-xl;
    }
}

.text-container {
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    @include clearfix;

    iframe {
        display: block;
        margin: 0 auto;
        max-width: 100% !important;

        @include media(xxs, lg) {
            max-height: 480px;
        }
    }
}

header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    @include box-shadow((0px 3px 7px 0px rgba($base-black, 0)));
    background-color: $base-white;
    background: linear-gradient(180deg, rgba($base-white, 1) 0%, rgba(255, 255, 255, 0) 90%);
    z-index: 1001;
    @include transition(350ms);

    .main-navbar {
        .navbar {
            &-brand {
                margin-right: 0;
                margin-bottom: 10px;
                padding-top: 10px;
                padding-bottom: 0;
                @include transition(350ms);

                img {
                    width: 78px;
                    height: 40px !important;
                    position: relative;
                    z-index: 1;
                    @include transition(350ms);

                    object-fit: contain;

                }

                img.logo-dark {
                    display: block;
                }

                img.logo-light {
                    display: none;
                }


                @include media(xl) {
                    margin-bottom: -7px;
                    padding-top: 15px;

                    img {
                        width: 94px;
                        height: 63px !important;
                    }
                }
            }

            &-toggler {
                display: inline-block;
                vertical-align: middle;
                border: 0;
                padding: 0;
                margin: 0px 0px;
                height: 18px;
                width: 32px;
                position: relative;

                .icon-bar {
                    display: block;
                    background-color: $second-color;
                    width: 32px;
                    height: 2px;
                    border-radius: 0px;
                    @include transition(350ms);
                    position: absolute;
                    left: 0;
                    top: 0;

                    &:nth-of-type(2) {
                        top: 8px;
                    }

                    &:nth-of-type(3) {
                        top: 16px;
                    }
                }

                &:not(.collapsed) {
                    .icon-bar {
                        border-radius: 1px;

                        &:nth-of-type(1) {
                            @include transform(rotateZ(45deg));
                            top: 8px;
                        }

                        &:nth-of-type(2) {
                            width: 0px;
                            opacity: 0;
                        }

                        &:nth-of-type(3) {
                            @include transform(rotateZ(-45deg));
                            top: 8px;
                        }
                    }
                }

                &:focus {
                    outline: 0;
                    box-shadow: none;
                    border: 0;
                }

                @include media(xl) {
                    display: none;
                }
            }

            &-collapse {
                width: 100%;

                @include media(xl) {
                    display: block;
                    width: auto;
                }

                &.main-menu {
                    >ul {
                        padding: 5px 0px 15px 0px;
                        margin: 0;
                        font-size: 0;
                        @include transition(350ms);

                        @include media(xl) {
                            padding: 40px 0 27px 0;
                        }

                        >li {
                            display: block;
                            text-align: right;
                            margin-bottom: 8px;
                            position: relative;

                            @include media(xl) {
                                display: inline-block;
                                margin-bottom: 0px;
                            }

                            &:not(:last-child) {
                                @include media(xl) {
                                    padding-right: 35px;
                                }

                                @include media(xxl) {
                                    padding-right: 70px;
                                }
                            }

                            a {
                                font-size: 16px;
                                text-transform: uppercase;
                                display: inline-block;
                                position: relative;
                                padding: 0 4px;
                                color: $color-line;

                                @include media(xxl) {
                                    font-size: 17px;
                                    padding-bottom: 4px;
                                }

                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 100%;
                                    border-bottom: 2px solid transparent;
                                    @include transition(350ms);
                                }

                                &:hover {
                                    text-decoration: none;

                                    &:after {
                                        right: 0;
                                        border-color: $main-color;
                                    }
                                }
                            }

                            >ul {
                                display: none;
                                background-color: $base-white;
                                margin: 0 -20px;
                                padding: 20px 20px 10px 20px;

                                @include media(xl) {
                                    position: absolute;
                                    padding: 20px 20px 10px 20px;
                                    top: 100%;
                                    left: 0;
                                    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
                                    min-width: 200px;
                                    text-align: left;
                                }

                                >li {
                                    margin: 0 0 7px 0;

                                    @include media(xl) {
                                        margin: 0 0 10px 0;
                                    }

                                    >a {
                                        font-size: 14px;

                                        @include media(xl) {
                                            font-size: 16px;
                                        }
                                    }

                                    &.Highlighted {
                                        >a {
                                            &:after {
                                                right: 0;
                                                border-color: $main-color;
                                            }
                                        }
                                    }
                                }

                                &.open {
                                    display: block;
                                }
                            }

                            &.hide-submenu>ul.open {
                                display: none;
                            }

                            &.Highlighted {
                                >a {
                                    &:after {
                                        right: 0;
                                        border-color: $main-color;
                                    }
                                }
                            }
                        }
                    }

                    .open-btn {
                        display: inline-block;
                        margin: 0 0 0 5px;

                        &:before {
                            content: "\e903";
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .header {
            &-controls {
                padding: 13px 0 16px 0;
                @include transition(350ms);

                @include media(xl) {
                    padding: 33px 0 10px 0;
                }
            }

            &-btn {
                color: $color-line;
                text-align: center;
                width: 30px;
                line-height: 30px;
                display: inline-block;

                &:before {
                    font-size: 20px;
                    display: inline-block;
                    vertical-align: middle;
                }
                
                
                &.login {
                    margin: 0 5px 0 0;

                    @include media(xl) {
                        margin: 0 0 0 20px;
                    }

                    @include media(xxl) {
                        margin: 0 0 0 30px;
                    }

                    &:before {
                        content: "\e905";
                    }
                }
                
                &:hover {
                    text-decoration: none;
                    color: $main-color;
                }
            }
        }
    }


    &.header-dark {
        background: $base-black;
        background: linear-gradient(180deg, rgba($base-black, 0.9) 0%, rgba($base-black, 0) 90%);

        .navbar-brand img.logo-dark {
            display: none;
        }

        .navbar-brand img.logo-light {
            display: block;
        }

        .main-navbar .navbar-collapse.main-menu>ul>li a {
            color: $base-white;
        }

        .main-navbar .navbar-collapse.main-menu>ul>li>ul {
            background-color: $base-black;
            
            @include media(xl) {
                background-color: $base-white;
            }
        }

        .main-navbar .navbar-collapse.main-menu>ul>li>ul a {
            @include media(xl) {
                color: $color-line;
            }
        }

        .main-navbar .navbar-toggler .icon-bar {
            background-color: $base-white;
        }

        .open-btn {
            color: $base-white;
        }

        .header-btn {
            color: $base-white;
        }
    }

    &.scrolled {
        background-color: rgba($base-white, 1);
        @include box-shadow((0px 3px 7px 0px rgba($base-black, 0.1)));

        &.homepage-header {
            background: $base-white;
        }

        &.header-dark {
            background: $base-black;
        }

        .header-controls {
            padding: 16px 0 10px 0;
        }

        .main-navbar {
            .navbar {
                &-brand {
                    padding-top: 10px;
                    margin-bottom: 10px;

                    img {
                        height: 40px !important;
                    }
                }

                &-collapse {
                    &.main-menu {
                        >ul {
                            padding: 5px 0 7px 0;

                            @include media(xl) {
                                padding: 20px 0 7px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &.open {
        background: $base-white;

        &.header-dark {
            background: $base-black;
        }
    }
}

footer {
    background-color: $footer;
    color: $base-white;
    padding: 20px 0 7rem 0;

    h4,
    .h4,
    p {
        color: $base-white;
    }

    .footer {
        &-container {
            padding: 25px 0 7rem 0;
            border-top: 1px solid $color-subtext;
        }

        &-header {
            margin: 0 0 4rem 0;
            display: flex;
            justify-content: space-between;

            @include media(md) {
                align-items: center;
            }
        }

        &-logo {
            text-align: left;
            padding: 0 40px 0 0;

            svg {
                width: 80px;
            }
        }

        &-list {
            margin: 0 -2rem;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                flex-flow: row wrap;

                li {
                    padding: 0 2rem;
                    margin: 0 0 15px 0;
                    width: 100%;

                    @include media(md) {
                        width: auto;
                        margin: 0;
                    }

                    a {
                        color: $base-white;
                        display: inline-block;
                        position: relative;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 100%;
                            border-bottom: 2px solid transparent;
                            @include transition(350ms);
                        }

                        &:hover {
                            text-decoration: none;

                            &:after {
                                right: 0;
                                border-color: $base-white;
                            }
                        }
                    }
                }
            }
        }

        &-columns {
            display: flex;
            flex-flow: row wrap;
            width: 100%;

            @include media(md) {
                text-align: left;
                padding: 0 0 8rem 0;
            }

            &-container {
                margin: 0 -2rem;
            }
        }

        &-column {
            padding: 0 2rem;
            margin: 0 0 30px 0;
            flex: 1 1 100%;
            width: 100%;
            text-align: center;

            @include media(md) {
                flex: 1 1 33.3333%;
                max-width: 33.3333%;
                margin: 0;
                text-align: left;
            }

            h4,
            .h4 {
                font-size: 20px;
                line-height: 25px;
            }
            
            p {
                font-size: 14px;
                line-height: 18px;
            }
            
            address {
                margin-bottom: 18px;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    padding: 0;
                    margin: 0 0 18px 0;

                    a {
                        color: $base-white;
                        font-weight: 700;
                        display: inline-block;
                        position: relative;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 100%;
                            border-bottom: 2px solid transparent;
                            @include transition(350ms);
                        }

                        &:hover {
                            text-decoration: none;

                            &:after {
                                right: 0;
                                border-color: $base-white;
                            }
                        }
                    }
                }
            }

            .map-canvas,
            iframe {
                height: 180px;
                width: 300px;
                max-width: 100%;
                margin: 0 auto;

                @include media(lg) {
                    margin: 0;
                }
            }
        }

        &-bottom-list {            
            padding: 3rem 0 0 0;
            border-top: 1px solid $color-subtext;

            ul {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                margin: 0 -10px;
                padding: 0;
                list-style: none;

                @include media(md) {
                    justify-content: flex-start;
                    margin: 0;
                }

                li {
                    padding: 0 10px;
                    margin: 0 0 15px 0;

                    @include media(md) {
                        margin: 0;
                    }

                    a {
                        color: $base-white;
                        display: inline-block;
                        position: relative;
                        font-size: 14px;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 100%;
                            border-bottom: 2px solid transparent;
                            @include transition(350ms);
                        }

                        &:hover {
                            text-decoration: none;

                            &:after {
                                right: 0;
                                border-color: $base-white;
                            }
                        }
                    }
                }
            }
        }

        &-social {
            text-align: center;
            @include create-social();

            @include media(md) {
                text-align: left;
            }

            ul {
                width: 100%;
                display: block;

                li {
                    display: inline-block;
                    vertical-align: middle;

                    &:last-of-type {
                        padding-right: 0px;
                    }

                    .social-ico {
                        width: 32px;
                        height: 32px;
                        color: $base-black;

                        &:before {
                            line-height: 32px;
                        }

                        &:after {
                            display: none;
                        }

                        &.twitter {
                            &:before {
                                font-size: 14px;
                            }
                        }

                        &.linkedin {
                            &:before {
                                font-size: 17px;
                            }
                        }

                        &.youtube {
                            &:before {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }        

        &-copy {
            font-size: 13px;
            line-height: 20px;
            text-align: center;
        }
    }

    &.footer-light {
        background-color: $base-white;
        color: $base-black;

        .footer-fill {
            fill: $base-black !important;
        }

        .footer-column {
            h4,
            .h4,
            p {
                color: $base-black;
            }

            ul li a {
                color: $base-black;

                &:after {
                    border-color: $base-black;
                }
                
                &.social-ico {
                    color: $base-white;
                    background-color: $base-black;
                }
            }
        }

        .footer-list ul li a,
        .footer-bottom-list ul li a {
            color: $base-black;

            &:after {
                border-color: $base-black;
            }
        }
    }
}

main {
    transition: all ease-in-out 350ms;
}

.page-content {
    padding-top: 60px;

    @include media(xl) {
        padding-top: 100px;
    }
}

.EditMode,
.DesignMode {
    header {
        position: static;

        &:after {
            display: none;
        }
    }

    main {
        padding-top: 0px;
    }

    #content {
        margin-top: 0 !important;

        &:before,
        &:after {
            display: none !important;
        }
    }
}