@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.job {
    &-page {
        .bottom-line {
            border-bottom: 0;
        }
    }

    &-items {
        padding-bottom: 60px;
        position: relative;

        @include bs-grid(15px);

        [class*="col-"] {
            margin-bottom: 30px;
        }

        .btn-more {
            position: absolute;
            bottom: 0px;
            left: 50%;
            @include transform(translate(-50%, 50%) rotateZ(45deg));
            z-index: 2;
            @include transition(350ms);

            &.collapsed {
                @include transform(translate(-50%, 50%) rotateZ(0deg));
            }
        }
    }

    &-item {
        background-color: $text-color;
        padding: 25px 25px 50px 25px;
        position: relative;
        min-height: 280px;
        height: 100%;

        @include media(lg) {
            padding: 50px 50px 70px 50px;
        }

        &-name {
            font-weight: 700;
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 15px;
            display: inline-block;
            @include hover;
            padding-left: 0;
            color: $base-white;

            &:hover {
                color: $main-color;
            }
        }

        &-description {
            margin-bottom: 15px;
            color: $base-white;

            big {
                color: $base-white;
                font-size: 16px;
                line-height: 25px;
            }
        }

        &-link {
            position: absolute;
            bottom: 23px;
            left: 25px;

            @include media(lg) {
                bottom: 29px;
                left: 50px;
            }

            &:hover {
                color: $table;

                &:after {
                    color: $table;
                }
            }
        }
    }

    &-detail {
        &-header {
            padding: 30px 0 0 0;

            @include media(lg) {
                padding: 0;
            }
        }

        &-cta {
            padding: 30px 0;

            h3 {
                @include media(begin, sm) {
                    margin: 0 0 20px 0;
                }
            }

            .perex {
                @include media(begin, sm) {
                    margin: 0 0 40px 0;
                }
            }
        }

        &-socials {
            ul {
                display: flex;
                justify-content: center;

                li {
                    margin: 0;
                    padding: 0 20px;

                    @include media(sm) {
                        padding: 0 25px;
                    }

                    @include media(lg) {
                        padding: 0 50px;
                    }

                    &:before {
                        display: none;
                    }

                    [class^="zfp-ico-"],
                    [class*=" zfp-ico-"] {
                        color: $base-white;

                        &:before {
                            font-size: 48px;
                        }

                        &:hover {
                            color: $main-color;
                            text-decoration: none;
                        }
                    }

                    .zfp-ico-job-icon-fb:hover {
                        color: map-get($social-colors, 'facebook');
                    }

                    .zfp-ico-job-icon-in:hover {
                        color: map-get($social-colors, 'linkedin');
                    }

                    .zfp-ico-job-icon-ytb:hover {
                        color: map-get($social-colors, 'youtube');
                    }

                    .zfp-ico-job-icon-insta:hover {
                        color: map-get($social-colors, 'instagram');
                    }
                }
            }
        }
    }
}

/* contact */

.contact {
    &-column {
        &-text {
            @include pux-scale-with-min("font-size", 22px, 18px);
            @include pux-scale-with-min("line-height", 36px, 28px);
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            margin: 0 0 20px 0;

            &:before {
                color: $main-color;
                @include pux-scale-with-min("font-size", 46px, 32px);
                display: block;
                margin: 10px 25px 0 0
            }

            &.address {
                &:before {
                    content: "\e93e";
                }
            }

            &.phone {
                &:before {
                    content: "\e93c";
                }
            }

            &.email {
                &:before {
                    content: "\e93d";
                }
            }
        }
    }
}

.map {
    &-wrapper &-points {
        display: none;
    }

    &-wrapper .marker-detail-container {
        display: none;
    }

    &-canvas {
        height: 600px;
    }
}

.search-box {
    display: inline-block;
    width: 100%;
    max-width: 760px;
    font-size: 0;

    &.map-search {
        max-width: 430px;
    }

    &-inner {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        background-color: $base-white;
        padding-right: 90px;
        margin-right: -80px;

        .map-search & {
            padding-right: 0;
            margin-right: 0;
        }

        input[type="text"] {
            line-height: 70px;
            background: $base-white;
            font-size: 16px;
            height: 70px;
            border: 0;

            &:focus {
                outline: none;
            }
        }
    }

    &-btn {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 80px;
        height: 70px;
        line-height: 70px;

        .map-search & {
            display: none;
        }

        &:before {
            content: "\e904";
            display: inline-block;
            vertical-align: middle;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: $main-color;
            color: $base-white;
            border-radius: 50%;
            font-size: 20px;
            @include transition();
        }

        &:hover {
            &:before {
                width: 80px;
                height: 70px;
                border-radius: 0;
                line-height: 70px;
            }
        }
    }
}

.marker-detail {
    color: $base-white;
    text-align: center;
    padding: 25px;
    font-size: 16px;
    line-height: 25px;
    font-family: $font-family;

    h2 {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 0;
    }

    .number,
    .city,
    .email {
        padding-bottom: 10px;
    }

    a {
        &:hover {
            color: $main-color;
        }
    }

    &-position {
        .gm-style-iw-c {
            padding: 0 !important;
        }

        &:after {
            width: 0 !important;
            height: 0 !important;
            border-style: solid;
            border-width: 11px 10px 0 10px;
            border-color: $second-color transparent transparent transparent;
            transform: translate(-50%, -0%) !important;
            box-shadow: none !important;
            opacity: 0.6 !important;
        }
    }

    &-flyout {
        background-color: rgba($second-color, 0.8) !important;
        border: 0 !important;
        border-radius: 0 !important;

        >div {
            background-color: transparent !important;
            overflow: hidden !important;
        }
    }

    &-close {
        opacity: 1 !important;
        width: 50px !important;
        height: 50px !important;
        outline: none !important;

        >img,
        >span {
            display: none !important;
        }

        &:before {
            content: "\e900";
            color: $main-color;
        }
    }
}

.contact-slider.slider-init .main-slider-inner {
    @include pux-scale-with-min("min-height", 300px, 200px);
}

.downloads-container {
    @include bs-grid(18px);
}

.download-item {
    display: block;
    background-color: $base-white;
    padding: 30px 120px;
    position: relative;
    @include box-shadow(4px 4px 0px 0px rgba($color-line, 0));
    @include transition();
    margin-bottom: 30px;

    &:before {
        content: "\e937";
        display: block;
        position: absolute;
        top: 32px;
        left: 36px;
        font-size: 70px;
    }

    &:focus,
    &:hover {
        @include box-shadow(6px 6px 10px 2px rgba($color-line, 0.5));
        color: $main-color;
        text-decoration: none;
    }

    &-name {
        font-weight: 700;
        font-size: 17px;
        margin-bottom: 5px;
    }

    &-file {
        font-size: 14px;
        color: $color-line;
    }
}

.page-404 {
    &-container {
        text-align: center;
    }

    &-image {
        @include pux-scale-with-min("margin-bottom", 50px, 20px);

        svg {
            margin: 0 auto;
            @include pux-scale-with-min("width", 300px, 20px);
            @include pux-scale-with-min("height", 400px, 20px);
        }
    }
}

body {
    overflow-x: hidden;
}

.wrapper-black {
    background-color: $base-black;

    h2 {
        color: $base-white;
    }

    p {
        color: $base-white;
    }
}

#content.slider-no-features {
    margin-top: -183px;

    &:before {
        margin-top: -171px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }

    &:after {
        content: "";
        background: transparent;
        height: auto;
        width: auto;
        display: block;
        margin-top: 0;
    }
}

.project-item-wrapper {
    display: grid;
    grid-gap: 8rem;
    grid-template-columns: 1fr;
    margin-bottom: 4rem;

    @include media(md) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 11rem 4rem;
        margin-bottom: 0;
    }

    .project-item {
        position: relative;
        padding: 0 0 150px 0;
    }

    .project-item-tooltip-inner {
        color: $paragraph-color;
        @include pux-scale-with-min("font-size", $paragraph-font-size, $paragraph-font-size-min);
        @include pux-scale-with-min("line-height", $paragraph-line-height, $paragraph-line-height-min);

        li {
            color: $paragraph-color;
            font-weight: 700;
        }
    }

    .project-item-tooltip-inner-title {
        @include pux-scale-with-min("font-size", 32px, 25px);
        @include pux-scale-with-min("line-height", 30px, 25px);
        font-weight: 700;
        color: $base-black;
        margin-bottom: 2.5rem;
    }

    .project-item-tooltip-teaser {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 150px;
        overflow: hidden;

        img {
            min-height: 100%;
            min-width: 100%;
            max-width: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all .4s ease-in-out;

            @include media (lg) {
                filter: grayscale(100%);
            }
        }

        span {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            z-index: 5;
            @include pux-scale-with-min("font-size", 20px, 18px);
            color: $base-white;
            text-transform: uppercase;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            transition: all .4s ease-in-out;
            background: rgba(0, 0, 0, 0.4);

            @include media (lg) {
                opacity: 0;
            }
        }

        &:focus,
        &:hover {
            @include media (lg) {
                span {
                    z-index: 5;
                    @include pux-scale-with-min("font-size", 20px, 18px);
                    color: $base-white;
                    text-transform: uppercase;

                    @include media (lg) {
                        opacity: 1;
                    }
                }

                img {
                    filter: none;
                }
            }
        }
    }
}

.parallax-max-width {
    .parallax-slider-widget-right {
        margin-top: 8rem;

        @include media (sm) {
            margin-top: 7rem;
        }

        @include media (md) {
            margin-top: 0;
            max-width: 100%;
        }

        @include media (lg) {
            max-width: 100% !important;
            margin-top: 0;
        }
    }

    .parallax-slider-background-item {
        @include media (md) {
            width: 100%;
        }

        @include media (lg) {
            width: 830px !important;
        }

        @include media (inf) {
            width: 100% !important;
        }
    }

    .parallax-slider-text {
        h2 {
            color: $base-black;
            font-weight: 700;

            &:after {
                display: none;
            }
        }

        p {
            color: $base-black;
            @include pux-scale-with-min("font-size", 18px, 16px);
        }
    }

    .parallax-slider {
        background-color: rgba(0, 0, 0, 0.9);
    }

    .parallax-slider-item {
        .btn-link {

            &:focus,
            &:hover {
                color: lighten($main-color, 10%);
            }
        }
    }

    .parallax-slider-item-name {
        color: $base-white;
        @include pux-scale-with-min("font-size", 18px, 16px);
    }

    .parallax-slider-item-text {
        color: $base-white;
        font-size: 14px;
        @include pux-scale-with-min("line-height", 20px, 18px);
    }
}

.cta-btn-container {
    br {
        display: none;

        @media screen and (min-width: 730px) {
            display: inline-block;
        }
    }
}

.cta-btn-container-center {
    padding-top: 2rem;
    padding-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include media (lg) {
        padding-bottom: 10rem;
    }
}

.partners-slider-container {
    h5 {
        padding-bottom: 4rem;
    }
}

.partner {
    &-slider {
        margin-left: -15px;
        margin-right: -15px;
    }

    &-item {
        padding: 15px 20px;

        @include media (lg) {
            padding: 15px 40px;
        }

        &-inner {
            height: 46px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            margin: 0 auto;
            max-height: 46px;
            filter: grayscale(100%);
            opacity: 0.5;
            @include transition(150ms);

            &:hover {
                filter: grayscale(0%);
                opacity: 1;
            }
        }
    }
}

.cta-container.homepage.reverse {
    background-repeat: no-repeat;
    width: 100% !important;
    height: 100% !important;
    background-size: cover;
    max-width: 100% !important;
}

.infobox-items.features-smaller-font {
    margin-bottom: 0
}

.img-left-center {
    .cta-content {
        @include media (xxs, lg) {
            width: 100%;
            display: block;
        }
    }

    .cta-content-image {
        flex: 1 0 450px;
        max-width: 450px;
        margin-top: 4rem;
        position: absolute;
        top: 0;
        left: 5.5rem;

        @include media (xxs, xl) {
            left: -4.5rem;
            margin: 0 auto;
            position: relative;
            top: 2rem;
            left: auto;
        }

        @include media (xl) {
            position: absolute;
            left: 5.5rem;
        }
    }

    .cta-wrapper {
        margin-top: 0;
    }

    .cta-content-image-inner {
        @include media (sm) {
            width: 450px;
            height: 450px;
        }
    }

    .cta-content-text-inner {
        color: $base-white;
        position: relative;
        z-index: 5;
        max-width: 100%;
        padding: 4rem 1rem 1rem 1rem;

        @include media (sm) {
            padding: 0;
            max-width: 100%;
            padding: 4rem 8rem 5rem 8rem;
        }

        @include media (xl) {
            padding: 4rem 8rem 5rem 45rem;
        }
    }

    .cta-content.with-devider h2 {
        @include pux-scale-with-min("font-size", 40px, 35px);
        font-weight: 700 !important;
        padding-bottom: 0.75rem;
    }
}

.section-table-wrapper {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 2;
        background: radial-gradient(circle, rgba($base-white, 0.4) 0%, $base-white 99%);

        .wrapper-black & {
            background: radial-gradient(circle, rgba($base-black, 0.4) 0%, $base-black 99%);
        }

        @include admin {
            display: none;
        }
    }

    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 2;
        background: linear-gradient(0deg, rgba($base-white, 1) 0%, rgba($base-white, 0) 35%, rgba($base-white, 0) 65%, rgba($base-white, 1) 100%);

        @include admin {
            display: none;
        }
    }

    >img {
        min-height: 100%;
        min-width: 100%;
        max-width: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    .section-table-overflow {
        position: relative;
        z-index: 3;
    }
}

.section-table-container {
    background: transparent;
    position: relative;
    width: 100%;
    padding: 0;

    @include media (xl) {
        width: 37%;
    }

    &:after {
        content: '';
        height: 93%;
        width: 3px;
        position: absolute;
        right: 0;
        background-color: $main-color;
        top: 4%;
        left: 48%;
    }

    tr td {
        padding: 15px 25px;
    }

    tr td:last-child {
        border-right: none;
    }

    tr:nth-child(2n) {
        background: transparent !important;
    }

    tr {
        border-bottom: none !important;
    }
}

.single-slider-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .slick-arrow {
        display: inline-block;
        background-color: transparent;
        color: $main-color;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 0%;
        border: 2px solid $main-color;
        overflow: hidden;
        font-size: 0;
        padding: 0;
        @include transition(150ms);
        cursor: pointer;
        outline: none;

        &:before {
            display: inline-block;
            font-size: 20px;
            line-height: 36px;
            content: "\e942";
        }

        &:hover {
            background-color: $main-color;
            color: $base-white;
            text-decoration: none;
        }

        @include media(md) {
            width: 50px;
            height: 50px;
            line-height: 50px;
            margin: 0 0 0 50px;

            &:before {
                line-height: 46px;
            }
        }

        &.slick-prev {
            &:before {
                content: "\e943";
            }

            @include media(md) {
                margin: 0 50px 0 0;
            }
        }
    }

    .slick-dots {
        display: flex;
        margin: 0 -5px;
        flex-flow: row nowrap;
        justify-content: center;
        padding: 10px 0 0 0;

        @include media(sm) {
            margin: 0 -10px;
            padding: 20px 0 0 0;
        }

        li {
            padding: 0 5px;

            @include media(sm) {
                padding: 0 10px;
            }

            &:before {
                display: none;
            }

            button {
                background: transparent;
                border: 2px solid $dots;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                font-size: 0;
                padding: 0;
                outline: none;
                cursor: pointer;
            }

            &.slick-active button {
                background: $main-color;
                border: 2px solid $main-color;
            }
        }
    }
}

.single-slider {
    max-width: calc(100% - 80px);

    @include media(md) {
        max-width: calc(100% - 200px);
    }
}

.annual-report {
    &-items {
        display: flex;
        flex-flow: row wrap;
    }

    &-item {
        margin: 0 0 20px 0;

        @include media(lg) {
            margin: 0 0 60px 0;
            flex: 1 0 50%;
            max-width: 50%;
        }

        &-title {
            padding: 0 0 0 20px;
            font-size: 48px;
            font-weight: 700;
            margin: 0 0 20px 0;

            @include media(lg) {
                padding: 0 0 0 45px;
            }
        }

        &-files {
            display: flex;
            flex-flow: row wrap;
        }

        &-file {
            padding: 0 0 0 20px;
            margin: 0 0 20px 0;

            @include media(lg) {
                margin: 0 0 40px 0;
                padding: 0 0 0 45px;
                max-width: 40%;
            }

            img {
                display: block;
                width: 200px;
                margin: 0 0 20px 0;
                max-width: 100%;
            }

            >div {
                margin: 0 0 20px 0;
                font-size: 18px;
                font-weight: 500;
            }
        }

        &-link {
            font-size: 0;
            color: $base-white;

            &:hover {
                color: $main-color;
                text-decoration: none;
            }

            &:before {
                content: "\e941";
                font-size: 25px;
            }
        }
    }

    &-collapse {
        flex: 1 0 100%;

        &-inner {
            display: flex;
            flex-flow: row wrap;
        }

        &-control {
            flex: 1 0 100%;
            @include pux-scale-with-min("padding-bottom", 100px, 20px);
        }
    }
}

.cookies-btn {
    border: 2px solid $main-color !important;
    color: $main-color !important;
    background-color: $base-white !important;
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 11px 25px 11px 25px !important;
    font-weight: 500 !important;
    text-transform: uppercase;

    @include media(md) {
        font-size: 16px !important;
        padding: 13px 30px 13px 30px !important;
    }

    &:hover {
        background-color: $main-color !important;
        color: $base-white !important;
    }
}

.cookies-modal-content {
    max-width: 800px !important; 
}

.cookies-modal-footer .cookies-modal-footer-left {
    flex-wrap: wrap !important;
}