@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.person-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: transparent;

    .person-item {
        width: 100%;
        max-width: 100%;
        margin: 0 0 10px 0;

        @include media(sm) {
            width: 50%;
        }

        @include media(md) {
            margin: 0 0 30px 0;
        }

        @include media(xl) {
            width: 25%;
        }
    }

    .person-item-teaser {
        height: 250px;
        width: 250px;
        padding: 20px;
        margin: 0 auto;
        position: relative;

        span {
            height: 100%;
            width: 100%;
            display: block;
            overflow: hidden;
            border-radius: 50%;
            position: relative;

            img {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .person-item-info {
        background-color: transparent;
        text-align: center;
        padding: 0;
    }

    .person-item-info-name {
        @include pux-scale-with-min("font-size", 22px, 20px);
        color: $main-color;
        min-height: auto;
        margin-bottom: 0;
        font-weight: 700;
    }
}

.person-container.person-container-spread {
    .cta-content-text-inner {
        color: $base-black;

        h2 {
            @include media (lg) {
                margin-top: -17rem;
            }
        }
    }
}