@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

.parallax-slider {
    &-widget {
        &-wrapper {
            position: relative;
            padding: 30px 0;

            @include media(md) {
                padding: 70px 0;
            }

            .board-container & {
                @include media(md) {
                    z-index: 1;
                    margin-bottom: -110px;
                }

                @include media(xxl) {
                    margin-bottom: -160px;
                }
            }

            h2 {
                color: $base-white;
                @include pux-scale-with-min("padding-bottom", 30px, 20px);
                position: relative;

                &:after {
                    content: '';
                    width: 86px;
                    height: 4px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: $main-color;
                }
            }

            p {
                color: $base-white;
            }
        }

        &-background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @include media(md) {
                width: calc(66.666667% + 120px);
            }
        }

        &-row {
            @include media(md) {
                display: flex;
                flex-flow: row nowrap;
            }
        }

        &-left {
            @include media(md) {
                flex: 0 0 calc(100% - 240px);
                max-width: calc(100% - 240px);
                padding-right: 40px;
            }

            @include media(lg) {
                flex: 0 0 calc(100% - 480px);
                max-width: calc(100% - 480px);
                padding-right: 100px;
            }
        }

        &-right {
            @include media(md) {
                padding-top: 0;
                flex: 0 0 240px;
                max-width: 240px;
            }

            @include media(lg) {
                padding-top: 0;
                flex: 0 0 480px;
                max-width: 480px;
            }
        }
    }

    &-wrapper {
        color: $base-white;
        position: relative;
    }

    &-background {
        &-item {
            background-size: 100%;
            background-repeat: no-repeat;
            padding-top: calc(100vw - 30px);

            @include media(md) {
                padding-top: 0;
                height: 480px;
            }

            &:focus {
                outline: none;
            }
        }
    }

    &-item {
        &-name {
            font-size: 22px;
            font-weight: 700;
        }

        &-position {
            font-size: 22px;
            margin-bottom: 10px;

            @include media(md) {
                margin-bottom: 20px;
            }
        }

        &:focus {
            outline: none;
        }
    }

    &-controls {
        font-size: 0px;
        margin-top: 20px;
        margin-bottom: 20px;

        @include media(md) {
            margin-top: 60px;
        }

        @include media(lg) {
            margin-top: 0px;
        }
    }

    &-arrow {
        display: inline-block;
        background-color: transparent;
        color: $main-color;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 36px;
        border-radius: 00%;
        border: 2px solid $main-color;
        font-size: 24px;
        overflow: hidden;

        &:before {
            display: inline-block;
        }

        &:hover {
            background-color: $main-color;
            color: $base-white;
            text-decoration: none;
        }

        @include media(md) {
            width: 50px;
            height: 50px;
            line-height: 46px;
        }

        &.prev {
            &:before {
                content: "\e90a";
                transform: rotateZ(180deg);
            }
        }

        &.next {
            margin-left: -2px;

            &:before {
                content: "\e90a";
            }
        }
    }
}

.project-parallax-container {
    .parallax-slider {
        background-color: rgba($base-black, 0.9);

        &-text {
            padding-top: 20px;
            padding-right: 60px;
            min-height: 270px;
            padding-bottom: 260px;
            color: $base-black;

            h2 {
                color: $base-black;
            }

            p {
                color: $base-black;
            }
    
            ul li {
                color: $base-black;
            }

            @include media(md) {
                padding-top: 70px;
                padding-bottom: 0px;
                padding-right: 60px;
                min-height: 270px;
                margin-bottom: -200px;
            }
        }

        &-wrapper {
            position: absolute;
            top: 100%;
            width: 100%;
            margin-top: -260px;

            @include media(md) {
                position: relative;
                margin-top: 0;
                top: auto;
            }
        }

        &-spacer {
            padding-top: 60px;

            @include media(md) {
                position: relative;
                padding-top: 0;
                left: 90%;
                top: 0;
                margin-bottom: -50px;
            }

            @include media(xl) {
                left: 100%;
            }
        }

        &-widget {
            &-wrapper {
                padding: 0;

                &:before {
                    content: '';
                    background-color: rgba($base-white, 0.9);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            &-background {
                width: 100%;

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: $base-white;

                    @include media (md) {
                        background: transparent;
                    }
                }

                @include media(md) {
                    width: 60%;
                    height: 100%;

                    &:after {
                        display: none;
                    }
                }

                @include media(xl) {
                    width: 55%;
                }

                @include media(xxxl) {
                    width: 47%;
                }
            }

            &-left {
                position: relative;
                z-index: 2;

                @include media(md) {
                    padding-right: 0;
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                @include media(xl) {
                    flex: 0 0 490px;
                    max-width: 490px;
                }
            }

            &-right {
                margin: 0 -25px;

                @include media(md) {
                    position: relative;
                    z-index: 1;
                    flex: 0 0 calc(50% + 45px);
                    max-width: calc(50% + 45px);
                    padding-left: 60px;
                    margin: 0;
                }

                @include media(xl) {
                    flex: 0 0 calc(100vw - 490px);
                    max-width: calc(100vw - 490px);
                    padding-left: 120px;
                }

                @include media(inf) {
                    flex: 0 0 calc(50vw + 230px);
                    max-width: calc(50vw + 230px);
                }
            }
        }

        &-item {
            color: $second-color;
            padding: 20px 30px;

            @include media(lg) {
                padding: 50px 60px;
            }

            &-logo {
                margin-bottom: 20px;
                max-height: 60px;

                @include media(lg) {
                    margin-bottom: 40px;
                }

                img {
                    max-height: 60px !important;

                    @include media(lg) {
                        max-height: none;
                    }
                }
            }

            &-name {
                color: $base-white;
                margin-bottom: 20px;
            }

            &-text {
                color: $base-white;
                margin-bottom: 20px;

                @include media(lg) {
                    margin-bottom: 40px;
                }
            }
        }

        &-background {
            background-color: $base-white;

            @include media(md) {
                height: 100%;

                .slick-list,
                .slick-track {
                    height: 100%;
                }
            }

            &-wrapper {
                @include media(md) {
                    height: 100%;
                }
            }

            &-item {
                height: 100%;
                background-size: cover;
                background-position: center;
            }
        }

        &-controls {
            position: absolute;
            top: 0px;
            margin-top: 0;

            @include media(md) {
                position: relative;
                top: auto;
                margin-top: -50px;
                padding-bottom: 70px;
            }
        }
    }
}

.EditMode,
.DesignMode {
    .board-container .parallax-slider-widget-wrapper {
        margin-bottom: 0;
    }
}