@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_mixins/pux/pux-social.scss";
@import "_variables.scss";

$slider-height: 100vh;
$slider-height-lg: 92vh;

.company-slider {
    &-widget-wrapper {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        height: $slider-height;

        @include media(lg) {
            height: $slider-height-lg;
        }

        @media only screen and(-webkit-min-device-pixel-ratio: 1.5),
        only screen and(-o-min-device-pixel-ratio: 5/4),
        only screen and(min-resolution: 150dpi),
        only screen and(min-resolution: 1.5dppx) {
            min-height: 720px;
        }

        @include media(2K) {
            height: 980px;
        }

        @include bs-grid(0px);

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            overflow: hidden;
            height: auto;
            background-color: rgba(255, 255, 255, 0.8);
            background: radial-gradient(circle, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.9) 89%, #fff 99%);
            z-index: 1;

            @include media(lg) {
                background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.9) 89%, #fff 99%);
            }

            @include admin {
                display: none;
            }
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
            height: 208px !important;
            width: 100%;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;

            @include admin {
                display: none;
            }

            @include media (lg) {
                height: 299px;
            }

            @include media (xl) {
                height: auto;
            }

            @include media (inf) {
                height: 500px !important;
            }

            @include admin {
                display: none;
            }
        }
    }

    &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .slick-list,
        .slick-track {
            height: 100%;
        }

        .company-slider-background-item {
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
    }

    &-wrapper {
        padding: 80px 0 20px 0;
        position: relative;
        z-index: 2;
        height: $slider-height;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;

        @include media(lg) {
            height: $slider-height-lg;
            padding: 110px 0 20px 0;
        }        

        @media only screen and(-webkit-min-device-pixel-ratio: 1.25),
        only screen and(-o-min-device-pixel-ratio: 5/4),
        only screen and(min-resolution: 125dpi),
        only screen and(min-resolution: 1.25dppx) {
            min-height: 720px;
        }

        @include media(2K) {
            height: 980px;
        }

        p {
            color: $base-black;
        }

        ul li {
            color: $base-black;
        }

        h1 {
            margin-bottom: 20px;
            @include pux-scale-with-min("line-height", 70px, 45px);
        }

        .perex {
            @include pux-scale-with-min("margin-bottom", 40px, 20px);
        }
    }

    &-dots {
        padding: 30px 0px 20px 0px;
        overflow: hidden;
        display: none;

        @include media(lg) {
            padding: 35px 0px 40px 0px;
            display: block;
        }

        .slick-dots {
            display: flex;
            margin: 0 -5px;
            flex-flow: row nowrap;

            @include media(sm) {
                margin: 0 -10px;
            }

            li {
                padding: 0 5px;

                @include media(sm) {
                    padding: 0 10px;
                }

                &:before {
                    display: none;
                }

                button {
                    background: transparent;
                    border: 2px solid $dots;
                    border-radius: 50%;
                    width: 15px;
                    height: 15px;
                    font-size: 0;
                    padding: 0;
                    outline: none;
                    cursor: pointer;
                }

                &.slick-active button {
                    background: $main-color;
                    border: 2px solid $main-color;
                }
            }
        }
    }

    &-dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $light-grey;
        text-align: center;
        cursor: pointer;

        @include media(sm) {
            width: 30px;
            height: 30px;
        }

        @include media(lg) {
            width: 80px;
            height: 80px;
            line-height: 80px;
        }

        @include media(xxl) {
            width: 100px;
            height: 100px;
            line-height: 100px;
        }

        svg {
            vertical-align: middle;
            display: none;

            @include media(lg) {
                display: inline-block;
                width: 46px;
            }

            @include media(xxl) {
                width: 60px;
            }

            .cls-1,
            .cls-2,
            .cls-3 {
                fill: $star-bg !important;
            }
        }

        &:hover {
            background-color: rgba($main-color, 0.5);

            svg {

                .cls-1,
                .cls-2,
                .cls-3 {
                    fill: $base-white !important;
                }
            }
        }

        .slick-active & {
            background-color: $main-color;

            svg {

                .cls-1,
                .cls-2,
                .cls-3 {
                    fill: $base-white !important;
                }
            }
        }
    }

    &-controls {
        font-size: 0px;
        margin-top: 30px;

        @include media(lg) {
            margin-top: 40px;
        }
    }

    &-arrow {
        display: inline-block;
        background-color: transparent;
        color: $main-color;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 36px;
        border-radius: 00%;
        border: 2px solid $main-color;
        font-size: 24px;
        overflow: hidden;

        &:before {
            display: inline-block;
        }

        &:hover {
            background-color: $main-color;
            color: $base-white;
            text-decoration: none;
        }

        @include media(md) {
            width: 50px;
            height: 50px;
            line-height: 46px;
        }

        &.prev {
            &:before {
                content: "\e90a";
                transform: rotateZ(180deg);
            }
        }

        &.next {
            margin-left: -2px;

            &:before {
                content: "\e90a";
            }
        }
    }
}

.company-item {
    user-select: text;

    &-logo {
        display: none;
    }

    &-text {
        @include pux-scale-with-min("font-size", $paragraph-font-size, $paragraph-font-size-min);
        @include pux-scale-with-min("line-height", $paragraph-line-height, $paragraph-line-height-min);
        margin-bottom: 15px;
        color: $base-black;

        p {
            color: $base-black;
        }

        ul li {
            color: $base-black;
        }

        @include media(lg) {
            margin-bottom: 30px
        }
    }
}

.company-item-title {
    @include pux-scale-with-min("font-size", 24px, 22px);
    font-weight: 700;
    color: $main-color;
    text-transform: uppercase;
}

.dark-slider-text {
    .main-slider-inner-content-title {
        color: $base-white;
    }

    p {
        color: $base-white;
    }
}