@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.testimonial {
    &-wrapper {
        @include pux-scale-with-min("padding-top", 70px, 30px);
        @include pux-scale-with-min("padding-bottom", 55px, 30px);

        h2 {
            text-align: center;
            @include pux-scale-with-min("margin-bottom", 60px, 20px);
        }
    }

    &-item {
        position: relative;
        padding: 15px 20px;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;

        @include media(md) {
            flex-flow: row nowrap;
        }

        @include media(lg) {
            padding: 35px 50px;
        }

        &-photo {
            flex: 1 0 200px;
            max-width: 200px;
            height: 200px;
            border-radius: 50%;
            overflow: hidden;
            background-color: $light-grey;
            margin: 0 0 20px 0;

            @include media(md) {
                margin: 0;
            }
        }

        &-content {
            flex: 0 1 100%;
            font-size: 16px;
            line-height: 25px;
            
            @include media(md) {
                padding: 0 0 0 20px;
            }

            @include media(lg) {
                padding: 0 0 0 50px;
            }

            strong {
                display: block;
                margin: 0 0 20px 0;
                font-size: 24px;
            }
        }

        &-rate {
            padding-top: 20px;
            line-height: 20px;

            span {
                display: inline-block;
                margin-right: 4px;
                font-size: 20px;
                line-height: 20px;

                &:before {
                    content: "\e90b";
                    color: $star-bg;
                }

                &:nth-of-type(1) {
                    &:before {
                        @include transition-full(color 50ms linear 0ms);
                    }
                }

                &:nth-of-type(2) {
                    &:before {
                        @include transition-full(color 50ms linear 300ms);
                    }
                }

                &:nth-of-type(3) {
                    &:before {
                        @include transition-full(color 50ms linear 600ms);
                    }
                }

                &:nth-of-type(4) {
                    &:before {
                        @include transition-full(color 50ms linear 900ms);
                    }
                }

                &:nth-of-type(5) {
                    &:before {
                        @include transition-full(color 50ms linear 1200ms);
                    }
                }
            }

            &.activate {
                &.count-1 {
                    span {
                        &:nth-of-type(1) {
                            &:before {
                                color: $main-color
                            }
                        }
                    }
                }

                &.count-2 {
                    span {
                        &:nth-of-type(1),
                        &:nth-of-type(2) {
                            &:before {
                                color: $main-color
                            }
                        }
                    }
                }

                &.count-3 {
                    span {
                        &:nth-of-type(1),
                        &:nth-of-type(2),
                        &:nth-of-type(3) {
                            &:before {
                                color: $main-color
                            }
                        }
                    }
                }

                &.count-4 {
                    span {
                        &:nth-of-type(1),
                        &:nth-of-type(2),
                        &:nth-of-type(3),
                        &:nth-of-type(4) {
                            &:before {
                                color: $main-color
                            }
                        }
                    }
                }

                &.count-5 {
                    span {
                        &:before {
                            color: $main-color
                        }
                    }
                }
            }
        }

        &.no-rate {
            .testimonial-item-info {
                padding-bottom: 43px;
            }
        }
    }
}
