
$px-values: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 120, 150);

// bootstrap
$main-left: 25px;
$main-right: 25px;
$main-left-md: 45px;
$main-right-md: 45px;

// container
$container-max-width: 1600px;
$container-padding-left: $main-left;
$container-padding-right: $main-right;
$container-padding-left-md: $main-left-md;
$container-padding-right-md: $main-right-md;

// fluid container
$fluid-container-padding-left: $main-left;
$fluid-container-padding-right: $main-right;
$fluid-container-padding-left-md: $main-left-md;
$fluid-container-padding-right-md: $main-right-md;
$fluid-container-padding-left-xl: 80px;
$fluid-container-padding-right-xl: 80px;


// row
$row-margin-left: (-$main-left);
$row-margin-right: (-$main-right);
$row-margin-left-md: (-$main-left-md);
$row-margin-right-md: (-$main-right-md);

// col
$col-padding-left: $main-left;
$col-padding-right: $main-right;
$col-padding-left-md: $main-left-md;
$col-padding-right-md: $main-right-md;

// typography
$font-family: 'Fedra', sans-serif;
$font-size: 16px;

// perex
$font-size-perex: 22px;
$min-font-size-perex: 18px;
$line-height-perex: 35px;

// small text
$font-size-small: 16px;
$min-font-size-small: 14px;
$line-height-small: 25px;

// main colors
$color-link: #ff5c00;
$main-color: #ff5c00;
$second-color: #323337;
$text-color: #171717;
$color-line: #646569;
$color-subtext: #525151;
$table: #e4e4e4;
$base-black: #000;
$base-white: #fff;
$light-grey: #f2f2f2;
$button-shadow: #dedfdf;
$star-bg: #939396;
$footer: $base-black;
$dots: #797979;
$features: #e5e5e5;
$small-features: #5b5b5b;

// paragraph
$paragraph-color: $base-black;
$paragraph-font-size: 20px;
$paragraph-font-size-min: 16px;
$paragraph-line-height: 30px;
$paragraph-line-height-min: 24px;

// button primary
$btn-primary-background: transparent;
$btn-primary-color: $main-color;

// button default
$btn-default-background: transparent;
$btn-default-color: $base-white;
$btn-font-weight: 500;

// ul, li
$ul-bullet: $main-color;
$ol-number: $color-line;
$ol-font-family: $font-family;

// table
$table-even-background: #ebebeb;
$table-head-border: $second-color;
$table-tr-border: #e4e4e4;

// form
$color-placeholder: $color-line;

// inputs
$border-color: $main-color;
$input-background: $base-white;
$focus-border-color: $second-color;
$input-height: 60px;
$textarea-height: $input-height * 3;

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $light-grey;
$file-name-color: $base-black;

// form radio
$radio-background: #eeeff4;
$radio-border: #eeeff4;
$radio-active-border: $main-color;
$radio-after-background: $main-color;

// form checkbox
$checkbox-background: #eeeff4;
$checkbox-border: #eeeff4;
$checkbox-active-border: $main-color;
$checkbox-after-background: $main-color;

// form error
$error-input-border: #d40511;
$error-label-color: #d40511;
$error-blink-start: #fff;
$error-blink-final: #fec7ca;
$use-blink-error: 1;

// scale ratios
$scale-base-size: 10px;

$scale-ratio-4K: 1.5;
$scale-ratio-2K: 1.05;
$scale-ratio-xxl: 1;
$scale-ratio-xl: 0.9;
$scale-ratio-lg: 0.8;
$scale-ratio-md: 0.75;
$scale-ratio-sm: 0.6;
$scale-ratio-xs: 0.55;
$scale-ratio-xxs: 0.5;

$font-size: 18px;
$font-size-min: 14px;
$line-height: 25px;
$line-height-min: 23px;
$min-padding: 10px;
$min-margin: 10px;
