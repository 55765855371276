@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.infobox {
    &-wrapper {
        text-align: center;

        h1 {
            max-width: 1200px;
            margin: 0 auto;
            text-transform: uppercase;
            @include pux-scale-with-min("margin-bottom", 30px, 20px);
        }

        h2 {
            max-width: 1200px;
            margin: 0 auto;
            margin-bottom: 20px;
        }

        .perex {
            max-width: 1200px;
            margin: 0 auto;
            @include pux-scale-with-min("margin-bottom", 40px, 20px);
        }
    }

    &-item {
        margin-bottom: 4rem;
        text-align: center;

        @include media (lg) {
            margin-bottom: 8rem;
        }

        &.jobs {
            margin-bottom: 40px;
        }

        &-icon {
            margin-bottom: 10px;

            svg {
                height: 100px;

                @include media (sm) {
                    height: 120px;
                }
            }
        }

        &-name {
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 15px;
            color: $base-black;
            transition: all ease-in-out 200ms;

            .wrapper-black & {
                color: $base-white;
            }

            a {
                @include hover;
            }
        }

        &-text {            
            color: $paragraph-color;
            @include pux-scale-with-min("font-size", $paragraph-font-size, $paragraph-font-size-min);
            @include pux-scale-with-min("line-height", $paragraph-line-height, $paragraph-line-height-min);
            transition: all ease-in-out 200ms;

            .wrapper-black & {
                color: $base-white;
            }
        }

        &-link {
            padding-top: 10px;
            font-weight: 700;

            a {
                &:after {
                    position: relative;
                    content: "\e908";
                    font-size: 10px;
                    line-height: 25px;
                    margin-left: 0;
                    @include transition();
                }

                &:hover {
                    color: $second-color;
                    text-decoration: none;

                    &:after {
                        margin-left: 3px;
                        color: $main-color;
                    }
                }
            }
        }
    }

    &-items {
        .row {
            margin-left: -15px;
            margin-right: -15px;

            [class*="col-"] {
                padding-left: 15px;
                padding-right: 15px;
            }

            @include media(lg) {
                margin-left: -45px;
                margin-right: -45px;

                [class*="col-"] {
                    padding-left: 45px;
                    padding-right: 45px;
                }
            }
        }

        &.features-infobox-homepage {
            .infobox-item-name {
                @include pux-scale-with-min("font-size", 45px, 25px);
                @include pux-scale-with-min("line-height", 50px, 28px);
            }
        }

        &.infobox-items-strategy {
            .infobox-item-name {
                @include pux-scale-with-min("font-size", 48px, 25px);
                @include pux-scale-with-min("line-height", 52px, 28px);
            }

            .infobox-item-text {
                .wrapper-black & {
                    color: $base-white;
                }
            }
        }

        &.infobox-items-business {
            .infobox-item {
                max-width: 480px;
                margin-left: auto;
                margin-right: auto;
            }

            .infobox-item-icon {
                margin-bottom: 20px;
            }

            .infobox-item-name {
                @include pux-scale-with-min("font-size", 32px, 25px);
                @include pux-scale-with-min("line-height", 40px, 28px);
                margin-bottom: 20px;
            }
        }

        &.infobox-items-brokers {
            .infobox-item-icon svg {
                @include media(lg) {
                    height: 150px;
                }
            }

            .infobox-item-name {
                @include pux-scale-with-min("font-size", 40px, 25px);
                @include pux-scale-with-min("line-height", 48px, 28px);
            }
        }
    }
}

a.infobox-item {
    display: block;

    &:hover,
    &:focus  {
        color: $main-color;
        text-decoration: none;

        .infobox-item-name,
        .infobox-item-text {
            color: $main-color;
        }
    }
}

.infobox-wrapper-text-map-layout {
    position: relative;

    img {
        position: absolute;
        z-index: -1;
        margin-top: -2rem;
        top: 0;
        max-width: none;
        height: 700px !important;
        right: -11rem;
        display: none;

        @include media (lg) {
            right: 0;
            margin-top: -4rem;
            max-width: 100%;
            height: 910px !important;
        }

        @include media(xl) {
            display: block;
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            overflow: hidden;
            background: rgb(255, 255, 255);
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);
        }
    }

    .infobox-item:last-of-type {
        margin-bottom: 3rem;
    }

    .infobox-item-name {
        @include pux-scale-with-min("font-size", 36px, 26px);
        font-weight: 400;
        color: $base-black;
    }

    .infobox-item-name b {
        color: $main-color;
        @include pux-scale-with-min("font-size", 96px, 40px);
        font-weight: 700;
        margin-right: 1.5rem;
    }

    .infobox-item-name {
        text-align: left;
    }

    .infobox-item-text {
        margin-top: 2.4rem;
        text-align: left;

        br {
            display: none;
        }

        @include media (lg) {
            br {
                display: inline-block;
            }
        }
    }
}

.infobox-items.features-small-icons {
    .infobox-item-icon svg {
        height: 70px;
    }

    .infobox-item-name {
        @include pux-scale-with-min("font-size", 24px, 22px);
    }

    .row {
        [class*="col-"] {
            padding-left: 40px !important;
            padding-right: 40px !important;
        }
    }

    .infobox-item-icon {
        @include media (lg) {
            margin-bottom: 1.75rem;
        }
    }

    .infobox-item {
        margin-bottom: 3rem;

        @include media (lg) {
            margin-bottom: 5rem;
        }
    }
}

.infobox-items-strateg-btn {
    margin-top: 10px;

    @include media(lg) {
        margin-top: 0;
    }
}