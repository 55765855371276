@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

@font-face {
    font-family: 'Fedra';
    src: url('/ZFPAcademy/media/system/fonts/FedraSans/FedraSansPro-Book.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Fedra';
    src: url('/ZFPAcademy/media/system/fonts/FedraSans/FedraSansPro-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Fedra';
    src: url('/ZFPAcademy/media/system/fonts/FedraSans/FedraSansPro-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Fedra';
    src: url('/ZFPAcademy/media/system/fonts/FedraSans/FedraSansPro-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

.cms-bootstrap [class^="icon-"]:before,
.cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    font-family: $font-family;
    height: 100%;
    color: $base-black;
}

html {
    font-size: $scale-base-size*$scale-ratio-xxs;

    @include media(xs) {
        font-size: $scale-base-size*$scale-ratio-xs;
    }

    @include media(sm) {
        font-size: $scale-base-size*$scale-ratio-sm;
    }

    @include media(md) {
        font-size: $scale-base-size*$scale-ratio-md;
    }

    @include media(lg) {
        font-size: $scale-base-size*$scale-ratio-lg;
    }

    @include media(xl) {
        font-size: $scale-base-size*$scale-ratio-xl;
    }

    @include media(xxl) {
        font-size: $scale-base-size*$scale-ratio-xxl;
    }

    @include media(2K) {
        font-size: $scale-base-size*$scale-ratio-2K;
    }

    @include media(4K) {
        font-size: $scale-base-size*$scale-ratio-4K;
    }
}

@media only screen and(-webkit-min-device-pixel-ratio: 1.25),
only screen and(-o-min-device-pixel-ratio: 5/4),
only screen and(min-resolution: 120dpi),
only screen and(min-resolution: 1.25dppx) {
    html {
        @include media(xl) {
            font-size: $scale-base-size*$scale-ratio-xl*0.7;
        }

        @include media(xxl) {
            font-size: $scale-base-size*$scale-ratio-xxl*0.7;
        }

        @include media(2K) {
            font-size: $scale-base-size*$scale-ratio-2K;
        }

        @include media(4K) {
            font-size: $scale-base-size*$scale-ratio-4K;
        }
    }
}

@media only screen and(-webkit-min-device-pixel-ratio: 1.10),
only screen and(-o-min-device-pixel-ratio: 5/4),
only screen and(min-resolution: 110dpi),
only screen and(min-resolution: 1.10dppx) {
    html {
        @include media(xl) {
            font-size: $scale-base-size*$scale-ratio-xl*0.8;
        }

        @include media(xxl) {
            font-size: $scale-base-size*$scale-ratio-xxl*0.8;
        }

        @include media(2K) {
            font-size: $scale-base-size*$scale-ratio-2K;
        }

        @include media(4K) {
            font-size: $scale-base-size*$scale-ratio-4K;
        }
    }
}

@media only screen and(-webkit-min-device-pixel-ratio: 1.05),
only screen and(-o-min-device-pixel-ratio: 5/4),
only screen and(min-resolution: 105dpi),
only screen and(min-resolution: 1.05dppx) {
    html {
        @include media(xl) {
            font-size: $scale-base-size*$scale-ratio-xl*0.85;
        }

        @include media(xxl) {
            font-size: $scale-base-size*$scale-ratio-xxl*0.85;
        }

        @include media(2K) {
            font-size: $scale-base-size*$scale-ratio-2K;
        }

        @include media(4K) {
            font-size: $scale-base-size*$scale-ratio-4K;
        }
    }
}

body {
    @include pux-scale-with-min("font-size", $font-size, $font-size-min);
    @include pux-scale-with-min("line-height", $line-height, $line-height-min);
}

img {
    max-width: 100%;
    height: auto !important;
}

@each $value in $px-values {
    .pt-#{$value} {
        @if($value > 20) {
            @include pux-scale-with-min("padding-top", $value, 20);
        }

        @else {
            padding-top: #{$value}px;
        }
    }

    .pb-#{$value} {
        @if($value > 20) {
            @include pux-scale-with-min("padding-bottom", $value, 20);
        }

        @else {
            padding-bottom: #{$value}px;
        }
    }

    .mt-#{$value} {
        @if($value > 20) {
            @include pux-scale-with-min("margin-top", $value, 20);
        }

        @else {
            margin-top: #{$value}px;
        }
    }

    .mb-#{$value} {
        @if($value > 20) {
            @include pux-scale-with-min("margin-bottom", $value, 20);
        }

        @else {
            margin-bottom: #{$value}px;
        }
    }
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

h1,
.h1 {
    @include pux-scale-with-min("font-size", 60px, 30px);
    @include pux-scale-with-min("line-height", 80px, 50px);
    @include pux-scale-with-min("margin-bottom", 40px, 20px);
    font-weight: 700;
    text-transform: uppercase;
}

h2,
.h2 {
    @include pux-scale-with-min("font-size", 52px, 25px);
    @include pux-scale-with-min("line-height", 70px, 32px);
    @include pux-scale-with-min("margin-bottom", 30px, 20px);
    font-weight: 700;
    text-transform: uppercase;
}

h3,
.h3 {
    @include pux-scale-with-min("font-size", 40px, 24px);
    @include pux-scale-with-min("line-height", 60px, 32px);
    @include pux-scale-with-min("margin-bottom", 25px, 15px);
    font-weight: 700;
    text-transform: uppercase;
}

h4,
.h4 {
    @include pux-scale-with-min("font-size", 32px, 25px);
    @include pux-scale-with-min("line-height", 42px, 32px);
    @include pux-scale-with-min("margin-bottom", 20px, 10px);
    font-weight: 700;
    color: $base-black;
}

h5,
.h5 {
    @include pux-scale-with-min("font-size", 24px, 18px);
    @include pux-scale-with-min("line-height", 30px, 24px);
    @include pux-scale-with-min("margin-bottom", 20px, 10px);
    font-weight: 400;
    color: $main-color;
}

h6,
.h6 {
    @include pux-scale-with-min("font-size", 36px, 33px);
    @include pux-scale-with-min("line-height", 20px, 20px);
    @include pux-scale-with-min("margin-bottom", 30px, 10px);
    font-weight: 700;
    color: $base-black;
}

p {
    color: $paragraph-color;
    @include pux-scale-with-min("font-size", $paragraph-font-size, $paragraph-font-size-min);
    @include pux-scale-with-min("line-height", $paragraph-line-height, $paragraph-line-height-min);

    .wrapper-black & {
        color: $base-white;
    }
}

big,
.perex {
    @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
}

small,
.text-small {
    @include pux-scale-with-min($font-size-small,
        $min-font-size-small,
        $line-height-small);
}

a {
    color: $color-link;
    cursor: pointer;
    @include transition();

    &:focus,
    &:hover {
        outline: none;
        color: $second-color;
    }
}

q {
    &:before {
        font-family: $font-family !important;
        content: '»';
        color: $main-color;
        display: inline-block;
    }

    &:after {
        font-family: $font-family !important;
        content: '«';
        color: $main-color;
        display: inline-block;
    }
}

.btn {
    display: inline-block;
    @include border-radius(0);
    border: 2px solid $main-color;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 11px 25px 11px 25px;
    text-transform: uppercase;
    position: relative;
    @include transition(100ms);

    @include media(md) {
        font-size: 20px;
        line-height: 24px;
        padding: 13px 34px 13px 34px;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        outline: none;
        box-shadow: none;

        &:after {
            font-size: 21px;

            @include media(md) {
                right: 13px;
            }
        }
    }

    span & {
        @include box-shadow(none);
        margin-bottom: 0px;
        margin-right: 0px;
        vertical-align: top;

        &:focus,
        &:hover {
            @include box-shadow(none);
            margin-bottom: 0px;
            margin-right: 0px;
            margin-top: 0px;
            margin-left: 0px;
        }
    }

    @at-root span#{&} {
        padding: 0;
    }

    &-link {
        color: $main-color;
        font-weight: 700;

        &:after {
            position: relative;
            content: "\e908";
            font-size: 10px;
            line-height: 25px;
            margin-left: 5px;
            @include transition();
        }

        &:hover {
            color: $second-color;
            text-decoration: none;

            &:after {
                margin-left: 8px;
                color: $main-color;
            }
        }
    }
}

.btn-primary {
    background: $btn-primary-background;
    color: $btn-primary-color;
    @include transition();

    &:focus,
    &:hover {
        text-decoration: none;
        background: $main-color;
        color: $base-white;
        border-color: $main-color;
    }
}

.btn-default {
    background-color: $btn-default-background;
    color: $btn-default-color;
    font-weight: $btn-font-weight;
    @include transition();

    &:focus,
    &:hover {
        text-decoration: none;
        background: $main-color;
        color: $base-white;
        border-color: $main-color;
    }
}

.image-top-center {
    position: top center !important;
}

.image-top-left {
    position: top left !important;
}

.image-top-right {
    position: top left !important;
}

.image-bottom-center {
    position: bottom center !important;
}

.image-bottom-left {
    position: bottom left !important;
}

.image-bottom-right {
    position: bottom right !important;
}

main {
    min-height: 400px;

    ol {
        margin: 0;
        padding: 0;
        list-style: none;
        counter-reset: my-ol;

        li {
            position: relative;
            margin: 0 0 10px 0;
            padding: 0 0 0 3rem;

            &:before {
                font-family: $ol-font-family !important;
                content: counter(my-ol);
                counter-increment: my-ol;
                color: $ol-number;
                position: absolute;
                left: 0;
                top: 2px;
                font-weight: bold;
            }
        }

        &[type="a"] {
            li:before {
                content: counter(my-ol, lower-alpha);
            }

            &[data-counter-upper] {
                li:before {
                    content: counter(my-ol, upper-alpha);
                }
            }
        }

        &[type="i"] {
            li:before {
                content: counter(my-ol, lower-roman);
            }

            &[data-counter-upper] {
                li:before {
                    content: counter(my-ol, upper-roman);
                }
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            @include pux-scale-with-min("font-size", $paragraph-font-size, $paragraph-font-size-min);
            @include pux-scale-with-min("line-height", $paragraph-line-height, $paragraph-line-height-min);
            color: $paragraph-color;
            line-height: 26px;
            margin: 0 0 10px 0;
            padding: 0 0 0 40px;
            position: relative;

            &:before {
                content: "";
                width: 10px;
                height: 2px;
                background: $ul-bullet;
                position: absolute;
                top: 14px;
                left: 0;
            }
        }
    }

    table {
        width: 100%;

        tr {
            border-bottom: 2px solid $table-tr-border;

            td {
                padding: 13px 15px 12px 15px;
            }

            &:nth-child(even) {
                background: $table-even-background;
            }
        }
    }

    .respo-table {
        table {
            width: 100%;

            thead {
                display: none;

                @include media(md) {
                    display: table-header-group;
                }

                tr {
                    border-bottom: 2px solid $table-head-border;

                    th {
                        padding: 13px 15px 12px 15px;
                        font-weight: 400;
                    }
                }
            }

            tbody {
                tr {
                    display: block;
                    border: 1px solid $table-tr-border;
                    border-bottom: none;
                    margin: 0 0 20px 0;

                    &:nth-child(even) {
                        background: $table-even-background;
                    }

                    @include media(md) {
                        display: table-row;
                        margin: 0;
                        border: none;
                        border-bottom: 2px solid $table-tr-border;
                    }

                    td {
                        @include flex-block;
                        @include justify-content(space-between);
                        width: 100%;
                        border-bottom: 2px solid $table-tr-border;

                        @include media(md) {
                            display: table-cell;
                            border: none;
                            width: auto;
                        }

                        &:before {
                            content: attr(data-th);
                            display: block;
                            text-align: left;
                            font-family: $font-family !important;
                            margin: 0 15px 0 0;

                            @include media(md) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.two-column-list {
    ul {
        @include media(sm) {
            @include column-count(2);
        }
    }
}

.btn-list {
    ul {
        @include flex-block();
        @include flex-flow(row, wrap);
        margin-left: -15px;
        margin-right: -15px;

        li {
            @include flex(100%);
            max-width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 30px;

            @include media(md) {
                @include flex(33.3333%);
                max-width: 33.3333%;
            }

            &:before {
                display: none;
            }

            a {
                display: block;
                background-color: $main-color;
                color: $base-white;
                line-height: 22px;
                padding: 14px 30px;
                text-align: center;
                text-transform: uppercase;

                &:hover {
                    text-decoration: none;
                    background-color: darken($main-color, 5);
                }

                @include media(md) {
                    font-size: 17px;
                    padding: 24px 30px;
                }
            }
        }
    }
}

/* wrappers */

.orange-wrapper {
    background-color: $main-color;
    color: $base-white;

    .perex,
    big {
        color: $base-white;
    }

    a:not(.btn) {
        color: $base-white;
        text-decoration: underline;
    }
}

.text-block {
    color: $base-black;

    @include media(lg) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @include media(xl) {
        padding-left: 70px;
        padding-right: 70px;
    }

    @include media(xxl) {
        padding-left: 120px;
        padding-right: 120px;
    }

    h2,
    .h2 {
        @include pux-scale-with-min("margin-bottom", 30px, 20px);
    }

    p {
        @include pux-scale-with-min("margin-bottom", 40px, 20px);
    }

    &.narrow-text {
        max-width: 1120px;
        margin: 0 auto;
    }

    &.with-devider {
        p:nth-of-type(1) {
            position: relative;
            @include pux-scale-with-min("padding-top", 40px, 20px);

            &:before {
                content: '';
                width: 86px;
                height: 4px;
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -43px;
                background-color: $main-color;
            }
        }
    }
}

h2.text-underline,
h3.text-underline {
    position: relative;
    margin-bottom: 30px;

    &:before {
        content: '';
        width: 86px;
        height: 4px;
        position: absolute;
        bottom: -15px;
        background-color: $main-color;
    }
}

.white-wrapper {
    background-color: $base-white;
}

.grey-wrapper {
    background-color: $light-grey;
}

.wrapper-semi-black {
    background-color: rgba($base-black, 0.9);
    color: $base-white;
}

.wrapper-black {
    background-color: $base-black;
    color: $base-white;

    .text-block,
    big,
    .perex {
        color: $base-white;
    }
}

.bottom-line {
    border-bottom: 1px solid $light-grey;
}

.custom-layout {
    padding-left: 20px;
    padding-right: 20px;

    @include media(md) {
        @include bs-grid(15px);
    }

    @include media(inf) {
        @include bs-grid(20px);
    }
}

/* animated icons */

.animated-icon {
    opacity: 0;

    &.full-visible {
        opacity: 1;
    }
}

.hide {
    display: none;
}

.EditMode,
.DesignMode {
    .btn:after {
        display: none;
    }

    .business-contact-frame-inner {
        position: static;
    }
}